import React from 'react';
import { FormGroup, InputProps, Label, Input, UncontrolledTooltip } from 'reactstrap';
import { MdInfoOutline } from 'react-icons/md';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

const tooltipTarget = window?.bookingAppContainer?.querySelector('#bokamera-embedded') || document.querySelector('#bokamera-embedded');

interface Props extends InputProps {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    label?: string;
    help?: string;
}

function InputElement(props: Props) {
    const {
        input,
        label,
        type,
        help,
        meta: { error, warning, touched, submitFailed },
        ...otherProps
    } = props;

    const tooltipTargetId = input.name.replace(/\./g, '') || '';

    let message;

    if ((touched || submitFailed) && (error || warning)) {
        message = (
            <small className="text-danger">
                <strong>{error || warning}</strong>
            </small>
        );
    }

    return (
        <FormGroup>
            <Label>
                {help && (
                    <span id={`${tooltipTargetId}-help`} className="text-info">
                        <MdInfoOutline size="1.3em" />
                    </span>
                )}
                {label}
            </Label>
            {help && tooltipTarget && (
                <UncontrolledTooltip
                    placement="bottom"
                    target={`${tooltipTargetId}-help`}
                    container={tooltipTarget as HTMLElement}
                >
                    {help}
                </UncontrolledTooltip>
            )}
            <Input {...input} {...otherProps} type={type} />

            {message}
        </FormGroup>
    );
}

export default InputElement;
