import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';

// Elements
import CustomFieldTextBox from '@/components/elements/CustomFieldTextBox';
import CustomFieldDropDown from '@/components/elements/CustomFieldDropDown';
import { CustomFieldValueType, CustomFieldType } from '@/types';

// TODO: Make better ts type checking in this file

interface Props {
    customFields: Array<CustomFieldType>;
    prefix?: string;
}

export class CustomFields extends React.Component<Props> {
    static defaultProps = {
        customFields: [],
    };

    render() {
        return (
            <form>
                {this.props.customFields.map(customField => this.renderCustomField(customField))}
            </form>
        );
    }

    renderCustomField = (customField: any) => {
        const { prefix } = this.props;

        if (customField.DataType === 'TextBox') {
            return (
                <CustomFieldTextBox
                    key={customField.Id}
                    customField={customField}
                    name={`${prefix || ''}${customField.Id}`}
                />
            );
        }

        if (customField.DataType === 'DropDown') {
            return (
                <CustomFieldDropDown
                    key={customField.Id}
                    customField={customField}
                    name={`${prefix || ''}${customField.Id}`}
                />
            );
        }

        return null;
    };
}

function mapStateToProps(state: any, props: any) {
    let initialValues: any = {};

    props.customFields.forEach((customField: CustomFieldValueType) => {
        if (customField.Value) {
            initialValues[`${props.prefix || ''}${customField.Id}`] = customField.Value;
        }
    });

    return {
        form: 'customFields',
        initialValues: initialValues,
    };
}

function submit(values: any, event: any, form: any) {
    return Promise.resolve(values).then(values => {});
}

export default compose(
    connect(mapStateToProps),
    reduxForm({
        onSubmit: submit,
    })
)(CustomFields) as React.ComponentType<Props>;
