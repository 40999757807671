import { ActionType, TimesStateType, TimeForServiceStateType } from '@/types';

const initialState: TimeForServiceStateType = {
    isLoading: false,
    single: '',
    multiple: '',
    times: [],
    nextAvailableTime: null,
};

// users reducer
export default function times(state: TimesStateType = {}, action: ActionType) {
    switch (action.type) {
        case 'INIT_TIMES': {
            return { ...state, [action.service.Id]: initialState };
        }
        case 'LOAD_TIMES': {
            return {
                ...state,
                [action.service.Id]: {
                    ...initialState,
                    isLoading: true,
                },
            };
        }
        case 'SAVE_TIMES': {
            return {
                ...state,
                [action.service.Id]: {
                    isLoading: false,
                    single: action.single,
                    multiple: action.multiple,
                    times: action.times,
                },
            };
        }
        case 'SAVE_NEXT_AVAILABLE_TIME': {
            return {
                ...state,
                [action.service.Id]: {
                    ...state[action.service.Id],
                    nextAvailableTime: action.time,
                },
            };
        }
        default:
            return state;
    }
}
