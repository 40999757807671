import { Configuration } from "@/types";


export default function readConfigurationProperty(
  property: keyof Configuration,
  defaultValue?: any
): any {
  if (!(window as any).BOKAMERA || !(window as any).BOKAMERA.configuration) {
    // console.log('No external configuration found for BOKAMERA');
    return defaultValue;
  }

  const setting = (window as any).BOKAMERA.configuration[property];

  if (setting === "true")
    return true;

  if (setting === "false")
    return false;

  if (setting === undefined) {
    // console.log(`BOKAMERA: Unable to find setting for property: ${property}`);
    return defaultValue;
  }

  // console.log(`BOKAMERA: Loaded setting ${property}: ${setting}`);
  return setting;
}
