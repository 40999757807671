import React from 'react';

import './LinkButton.css';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {}

const LinkButton: React.FC<Props> = ({ className, ...props }) => (
    <button className={`LinkButton ${className ? className : ''}`} {...props} />
);

export default LinkButton;
