import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap';
import BEMHelper from 'react-bem-helper';
import { ReactComponent as Participants } from '@/components/svg/participants.svg';
import { ReactComponent as Clock } from '@/components/svg/clock.svg';
import { ReactComponent as Info } from '@/components/svg/info.svg';
import { format, getDayOfWeek, toDate } from '@/misc/datetime';


import Times from '@/components/partials/Times';

// Elements
import { Button } from 'bokamera-embedded-ui';
import ShowMoreText from '@/components/elements/ShowMoreText';
import LinkButton from '@/components/elements/LinkButton';

import { formatMinutesToWords } from '@/misc/common';
import { ConfigKeys } from '@/misc/constants';
import { ServiceType, CompanyType, PriceType } from '@/types';

import './Service.css';
import { RootState } from '@/reducers';
import { Trans } from '@lingui/macro';
import { ApplicationState } from '@/store';
import LocalizedPricePerTime from './LocalizedPricePerTime';
import { push } from 'connected-react-router';

interface Props extends RouteComponentProps {
    company: CompanyType;
    configuration: any;
    service: ServiceType;
    append?: React.FC<ServiceType>;
    hideSelect: boolean;
    hideLength: boolean;
    hidePrice: boolean;
    // router: RouteProps;
    dispatch: Dispatch<any>;
}

interface OwnProps {
    service: ServiceType;
    hideSelect?: boolean;
    append?: React.FC<ServiceType>;
    compact?: boolean;
}

const c = new BEMHelper({
    name: 'Service',
});

interface ServicePriceProps {
    prices: PriceType[];
    showDetails?: boolean;
}

// TODO: remove all @ts-ignore from the file

// Used only for redesigned row view
const DefaultPrice: React.FC<ServicePriceProps> = ({ prices = [] }) => {
    // TODO: handle price options
    const [showAll, setShowAll] = useState(false);

    function toggleShowAll() {
        setShowAll(!showAll);
    }

    let priceContent = null;
    let _prices = prices.slice();

    if (!showAll) {
        _prices = _prices.slice(0, 5);
    }

    if (_prices.length === 1) {
        const price = _prices[0];
        priceContent = (
            <div {...c('price')}>
                <span {...c('priceValue')}>
                    <LocalizedPricePerTime
                      value={price.Price}
                      sign={price.PriceSign}
                      calculationTypeId={price.CalculationTypeId}
                      style='narrow'
                    />
                </span>
            </div>
        );
    } else if (_prices.length > 1) {
        if (_prices.find((price) => !!price.Category)) {
            priceContent = _prices.map((price, i) => {
                return (
                  <div {...c("price")} key={i}>
                    <span {...c("priceLabel")}>{price.Category}</span>
                    <span {...c("priceValue")}>
                      <LocalizedPricePerTime
                        value={price.Price}
                        sign={price.PriceSign}
                        calculationTypeId={price.CalculationTypeId}
                        style="narrow"
                      />
                    </span>
                  </div>
                );
        });
        } else {
            let lowestPrice = _prices.sort((a, b) => a.Price - b.Price)[0];

            priceContent = (
                <div {...c('price')}>
                    <span {...c('priceLabel')}>
                        <Trans id="from"></Trans>
                    </span>
                    <span {...c('priceValue')}>
                        <LocalizedPricePerTime
                          value={lowestPrice.Price}
                          sign={lowestPrice.PriceSign}
                          calculationTypeId={lowestPrice.CalculationTypeId}
                          style='narrow'
                        />
                    </span>
                </div>
            );
        }
    }

    return (
        <div {...c('priceContainer')} data-testid="service-prices">
            {priceContent}
            {prices.length > 5 && (
                <LinkButton onClick={toggleShowAll}>
                    {showAll ? <Trans id="showLess"></Trans> : <Trans id="showMore"></Trans>}
                </LinkButton>
            )}
        </div>
    );
};

const FromToPrice: React.FC<ServicePriceProps> = ({ prices }) => {
  const lowestPrice = prices.sort((a, b) => a.Price - b.Price)[0];
  const highestPrice = prices.sort((a, b) => b.Price - a.Price)[0];

  return (
    <div {...c("priceContainer")} data-testid="service-prices">
      <div {...c("price")}>
        <div {...c("priceValue")}>
          {lowestPrice.Price !== highestPrice.Price ? (
            <>
              <span {...c("item")}>{lowestPrice.Price}</span>-
              <span {...c("item")}>
                <LocalizedPricePerTime
                  value={highestPrice.Price}
                  sign={highestPrice.PriceSign}
                  calculationTypeId={highestPrice.CalculationTypeId}
                  style='narrow'
                />
              </span>
            </>
          ) : (
            <span {...c("item")}>
              <LocalizedPricePerTime
                value={highestPrice.Price}
                sign={highestPrice.PriceSign}
                calculationTypeId={highestPrice.CalculationTypeId}
                style='narrow'
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const FromPrice: React.FC<ServicePriceProps> = ({ prices }) => {
  const lowestPrice = prices.sort((a, b) => a.Price - b.Price)[0];

  return (
    <div {...c('priceContainer')} data-testid="service-prices">
        <div {...c("price")}>
        <span {...c("priceValuePrefix")}>
            <Trans id="from"></Trans>
        </span>{" "}
        <div {...c("priceValue")}>
            <span {...c("item")}>
              <LocalizedPricePerTime
                value={lowestPrice.Price}
                sign={lowestPrice.PriceSign}
                calculationTypeId={lowestPrice.CalculationTypeId}
                style='narrow'
              />
            </span>
        </div>
        </div>
    </div>
  );
};

const TimesWeekdaysPrice: React.FC<ServicePriceProps> = ({ prices }) => {
  
  return (
    <div {...c('priceContainer column')} data-testid="service-prices">
    {prices.map((price, i) => {
      const { Category, Price, PriceSign, From, FromTime, To, ToTime, DaysOfWeek } = price;
      const fromTime = `${From.split('T')[0]}T${FromTime}`;
      const toTime = `${To.split('T')[0]}T${ToTime}`;

      const iStoTimeInfinite = To.includes('9999');
      const isWholeDay = (FromTime === '00:00:00' && ToTime === '23:59:00') || (FromTime === '00:00:00' && ToTime === '23:59:59')

      return (
          <div key={i}>
              <div {...c('category text-left')}>{Category}</div>
              <div {...c("price with-details")} key={i}>
                  
                  <div {...c("price-info")} >
                      <div data-testid="weekdays-short" className='text-left grey-400'>{DaysOfWeek.map(d => d.DayOfWeek.substring(0,2)).join(', ')}</div>
                      {!isWholeDay && !iStoTimeInfinite
                        ? <div className='text-left grey-400'>{format(fromTime, 'p')}-{format(toTime, 'p')}</div>
                        : null
                    }
                  </div>
                  <div {...c('priceValue')}>
                    <LocalizedPricePerTime
                      value={price.Price}
                      sign={price.PriceSign}
                      calculationTypeId={price.CalculationTypeId}
                      style='narrow'
                    />
                  </div>
              </div>
          </div>
        )
    })}
  </div>
  )
};


const AllPrice: React.FC<ServicePriceProps> = ({ prices, showDetails = false }) => {
  return (
    <div {...c('priceContainer column')} data-testid="service-prices">
      {prices.map((price, i) => {
        const { Category, Price, PriceSign, From, FromTime, To, ToTime, IsDaysOfWeekSpecific, DaysOfWeek } = price;
        const fromTime = `${From.split('T')[0]}T${FromTime}`;
        const toTime = `${To.split('T')[0]}T${ToTime}`;

        const iStoTimeInfinite = To.includes('9999');
        const isWholeDay = (FromTime === '00:00:00' && ToTime === '23:59:00') || (FromTime === '00:00:00' && ToTime === '23:59:59')

        const useWithTimesNoDays = !IsDaysOfWeekSpecific && !isWholeDay;
        const useWithTimesWithDays = IsDaysOfWeekSpecific && !isWholeDay;
        const useNoTimesWithDays = isWholeDay && IsDaysOfWeekSpecific;

        return (
          <div {...c("price column end")} key={i}>
            <div {...c("price with-details column end")} key={i}>
              {!iStoTimeInfinite ? (
                <div {...c("price-info")}>
                  <div {...c("category")}>
                    <span>
                      {Category}
                    </span>
                  </div>
                  <div {...c(` flex gap-6 justify-content-end wrap ${showDetails ? '' : 'hide'}`)}>
                    <div>{format(fromTime, "PPp")}</div>
                    <div>{format(toTime, "PPp")}</div>
                  </div>
                </div>
              ) : useWithTimesNoDays ? (
                <div {...c("price-info")}>
                  <div {...c("category")}>
                    {Category}
                  </div>
                  <div {...c(` flex gap-6 justify-content-end wrap ${showDetails ? '' : 'hide'}`)}>
                    <span>
                      {format(fromTime, "p")} - {format(toTime, "p")}
                    </span>
                  </div>
                </div>
              ) : useWithTimesWithDays ? (
                <div {...c("price-info column")}>
                  <div {...c("category")}>
                    {Category}
                  </div>
                  <div {...c(` flex gap-6 justify-content-end wrap ${showDetails ? '' : 'hide'}`)}>
                    <span {...c(" grow")}>{getShortenedWeekdays(DaysOfWeek)}</span>
                    <span>
                      {format(fromTime, "p")} - {format(toTime, "p")}
                    </span>
                  </div>
                </div>
              ) : useNoTimesWithDays ? (
                <div {...c("price-info column")}>
                  <div {...c("category")}>
                    {Category}
                  </div>
                  <div {...c(` flex gap-6 justify-content-end wrap ${showDetails ? '' : 'hide'}`)}>
                  <span>{getShortenedWeekdays(DaysOfWeek)}</span>
                  </div>
                </div>
              ) : null}
              <div {...c("priceValue end")}>
                <LocalizedPricePerTime
                  value={price.Price}
                  sign={price.PriceSign}
                  calculationTypeId={price.CalculationTypeId}
                  style='narrow'
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};


export class Service extends React.Component<Props, { showDetails: boolean}> {
    state = {
      showDetails: false
    }

    render() {
        const { configuration, service } = this.props;
        const layoutType = configuration[ConfigKeys.LISTING_LAYOUT];
        const bookLayout = configuration[ConfigKeys.BOOK_LAYOUT];

        const hasSelectableResources =
          service.ResourceTypes.length > 0 &&
          service.ResourceTypes.some(
            (resourceType) => resourceType.SelectableByUser
          );
        
        if (layoutType === ConfigKeys.LISTING_LAYOUT_ROWS_COMPACT) {
            return this.renderCompactServiceRow();
        }
        if (layoutType === ConfigKeys.LISTING_LAYOUT_ROWS) {
            return this.renderServiceRow();
        }
        if (layoutType === ConfigKeys.LISTING_LAYOUT_COLUMNS) {
            return this.renderServiceColumn();
        }
        if (layoutType === ConfigKeys.LISTING_LAYOUT_ROW_BASED_GROUPED) {
            return this.renderServiceRow();
        }

        if(
            layoutType !== ConfigKeys.LISTING_LAYOUT_COLUMNS &&
            bookLayout === ConfigKeys.BOOK_LAYOUT_RESOURCE_BASED &&
            hasSelectableResources
        ) {
            return this.renderServiceRow();
        }
    }

    renderServiceRow = () => {
        const {
            company,
            configuration,
            append,
            service,
            hideSelect,
            hideLength,
            hidePrice,
        } = this.props;

        const toggleDetails = () => this.setState((state) => ({ showDetails: !state.showDetails}))

        const showNumberOfSpots =
          (company?.BookingSettings?.ShowFreeTimesLeft &&
            service.GroupBooking.Active);

        const isFullPrice = !hidePrice && service.Prices.length > 0 && service.PriceViewTypeId === 5

        return (
          <div {...c()} data-testid={`service-id-${service.Id}`}>
            {!configuration.hideServiceImage && (
              <div {...c("imageContainer")}>
                {service.ImageUrl || company.LogoType ? (
                  <img
                    // @ts-ignore
                    src={service.ImageUrl || company.LogoType}
                    {...c("image")}
                    alt={service.Name}
                  />
                ) : (
                  <div
                    {...c("imagePlaceholder")}
                    style={{ backgroundColor: "rgb(240, 240, 235)" }}
                  />
                )}
              </div>
            )}
            <div {...c("main")}>
              <div {...c("content")}>
                <h4 {...c("title")}>{service.Name}</h4>
                <div {...c("meta")}>
                  {service.Group}
                  {service.Group && !hideLength && (
                    <div {...c("metaDivider")} />
                  )}
                  {!hideLength && (
                    <ServiceLength service={service} />
                  )}
                  {service.Group && !hideLength && showNumberOfSpots && (
                    <div {...c("metaDivider")} />
                  )}
                  {showNumberOfSpots ? (
                    <span>
                      <Participants {...c("metaIcon")} />
                      {service.TotalSpots} <Trans id="spots"></Trans>
                    </span>
                  ) : null}
                </div>
                {service.CustomFieldValues && (
                  <table {...c("customFields")}>
                    <tbody>
                      {service.CustomFieldValues.map((customFieldValue) => (
                        <tr {...c("customFieldRow")} key={customFieldValue.Id}>
                          <td {...c("customFieldLabel")}>
                            {customFieldValue.Name}
                          </td>
                          {customFieldValue.Value ? (
                            <td {...c("customFieldValue")}>
                              <ShowMoreText>
                                {customFieldValue.Value}
                              </ShowMoreText>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <p>
                  <ShowMoreText>{service.Description}</ShowMoreText>
                </p>
                {append && append(service)}
              </div>
              <div {...c("divider")} />
              <div {...c("actionBox")}>
                {isFullPrice ? (
                  <span
                    title="more info"
                    {...c("info-icon")}
                    onClick={() => toggleDetails()}
                    onKeyDown={() => toggleDetails()}
                  >
                    <Info />
                  </span>
                ) : null}
                {this.renderPriceViewType()}
                {!hideSelect && (
                  <Button
                    {...c("select")}
                    primary
                    outline
                    onClick={() => this.selectService(service)}
                  >
                    <Trans id="book"></Trans>
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
    };

    renderPriceViewType = () => {
        const service = this.props.service;
        const hidePrice = this.props.hidePrice;
        const { showDetails } = this.state;

        const priceContent =
          !hidePrice && service.PriceViewTypeId === 1 ? (
            <DefaultPrice prices={service.Prices} />
          ) : !hidePrice && service.Prices.length > 0 && service.PriceViewTypeId === 2 ? (
            <FromToPrice prices={service.Prices} />
          ) : !hidePrice && service.Prices.length > 0 && service.PriceViewTypeId === 3 ? (
            <FromPrice prices={service.Prices} />
          ) : !hidePrice && service.Prices.length > 0 && service.PriceViewTypeId === 4 ? (
            <TimesWeekdaysPrice prices={service.Prices} />
          ) : !hidePrice && service.Prices.length > 0 && service.PriceViewTypeId === 5 ? (
            <AllPrice prices={service.Prices} showDetails={showDetails} />
          ) : null;

        return !hidePrice && !priceContent
            ? <DefaultPrice prices={service.Prices} />
            : priceContent;
    }

    renderServiceColumn = () => {
        const {
            company,
            configuration,
            append,
            service,
            hideSelect,
            hideLength,
            hidePrice,
        } = this.props;

        return (
            <Card {...c(undefined, { column: true })}>
                {!configuration.hideServiceImage && service.ImageUrl && (
                    <CardImg top width="100%" src={service.ImageUrl} alt="Bild" />
                )}
                <CardBody>
                    <CardTitle>{service.Name}</CardTitle>
                    <CardText>
                        <ShowMoreText>{service.Description}</ShowMoreText>
                    </CardText>
                    <table {...c('customFields')}>
                        <tbody>
                            {!hideLength && this.renderServiceLength(service)}
                            {company?.BookingSettings?.ShowFreeTimesLeft &&
                                this.renderNumberOfSpots(service)}
                            {!hidePrice && this.renderPrice(service)}
                            {this.renderCustomFieldValues()}
                        </tbody>
                    </table>

                    {append && append(service)}

                    {!hideSelect && (
                        <Button block onClick={() => this.selectService(service)} primary outline>
                            {<Trans id="select"></Trans>}
                        </Button>
                    )}
                </CardBody>
            </Card>
        );
    };

    renderCompactServiceRow = () => {
        const { company, service, hideSelect, hideLength, hidePrice, append } = this.props;

        return (
            <React.Fragment>
                <tr key={service.Id}>
                    <td>
                        <strong>{service.Name}</strong>
                        {service.Description && (
                            <div className="text-muted">
                                <ShowMoreText>{service.Description}</ShowMoreText>
                            </div>
                        )}
                    </td>
                    {!hideLength && <td>{this.renderServiceLength(service, true)}</td>}
                    {company?.BookingSettings?.ShowFreeTimesLeft && (
                        <td>{this.renderNumberOfSpots(service, true)}</td>
                    )}
                    {append ? <tr>
                        <td>
                            {append(service)}
                        </td>
                    </tr> : null}
                    {!hidePrice && <td>{this.renderPrice(service, true)}</td>}
                    <td>
                        {!hideSelect && (
                            <Button color="success" onClick={() => this.selectService(service)}>
                                {<Trans id="select"></Trans>}
                            </Button>
                        )}
                    </td>
                </tr>
            </React.Fragment>
        );
    };

    renderNumberOfSpots = (service: ServiceType, compact?: boolean) => {
        if (service.TotalSpots && service.TotalSpots > 1) {
            if (compact) {
                return (
                    <div>
                        <strong>{<Trans id="numberOfSpots"></Trans>}: </strong>
                        {service.TotalSpots} {<Trans id="spots"></Trans>}
                    </div>
                );
            }

            return (
                <tr>
                    <td {...c('td-description')}>
                        <strong>{<Trans id="numberOfSpots"></Trans>}</strong>
                    </td>
                    <td>
                        {service.TotalSpots} {<Trans id="spots"></Trans>}
                    </td>
                </tr>
            );
        } else {
            return null;
        }
    };

    renderServiceLength = (service: ServiceType, compact?: boolean) => {
        if (service.Duration) {
            if (compact) {
                return (
                    <div style={{ marginRight: '2em' }}>
                        <strong>{<Trans id="duration"></Trans>}: </strong>{' '}
                        {formatMinutesToWords(service.Duration)}
                    </div>
                );
            }

            return (
                <tr>
                    <td {...c('td-description')}>
                        <strong>{<Trans id="duration"></Trans>}</strong>
                    </td>
                    <td>
                        <em>{formatMinutesToWords(service.Duration)}</em>
                    </td>
                </tr>
            );
        } else {
            return false;
        }
    };

    // 
    renderPrice = (service: ServiceType, compact?: boolean, columns?: boolean) => {
        let price;
        let prices = service.Prices.slice();

        if (prices) {
            if (prices.length === 1) {
                price = prices[0].PriceText;
            } else if (prices.length > 1) {
                if (prices.find((price) => !!price.Category)) {
                    price = prices.map(({ PriceText }) => PriceText).join('\n');
                } else {
                    let lowestPrice = prices.sort((a, b) => a.Price - b.Price)[0];

                    price = `${(<Trans id="from"></Trans>)} ${lowestPrice.PriceText}`;
                }
            }
        }

        if (price) {
            if (compact) {
                return (
                    <div>
                        <strong>{<Trans id="price"></Trans>}: </strong>
                        {price}
                    </div>
                );
            }

            return (
                <tr>
                    <td {...c('td-description')}>
                        <strong>{<Trans id="price"></Trans>}</strong>
                    </td>
                    <td>
                        <em>{price}</em>
                    </td>
                </tr>
            );
        } else {
            return false;
        }
    };

    renderCustomFieldValues() {
        const { CustomFieldValues } = this.props.service;

        if (!CustomFieldValues) return false;

        return CustomFieldValues.map((customFieldValue) => (
            <tr>
                <td>
                    <strong>{customFieldValue.Name}</strong>
                </td>
                <td>
                    <em>{customFieldValue.Value}</em>
                </td>
            </tr>
        ));
    }

    selectService = (service: ServiceType) => {
        this.props.dispatch(push(`/services/${service.Id}/times`))
        this.props.dispatch({ type: 'SELECT_SERVICE', payload: service.Id });
    };
}

export default compose<React.ComponentType<OwnProps>>(
    connect(({ company, configuration }: ApplicationState, ownProps) => {
        const props: any = {
            company: company.data,
            configuration: configuration.data
        }

        if(
            !ownProps?.append &&
            !ownProps.hideSelect &&
            configuration.data.bookLayout === ConfigKeys.BOOK_LAYOUT_TIME_BASED
        ) {
            props.append = (service: ServiceType) => (
                <div>
                    <Times service={service} />
                </div>
            );

            props.hideSelect = true;
        } 
        
        return props;
    }),
    withRouter
)(Service);

const getShortenedWeekdays = (
  daysOfWeek: {
    DayOfWeekId: number;
    DotNetDayOfWeekId: number;
    DayOfWeek: string;
  }[]
) => {
  return daysOfWeek.map((day) => getDayOfWeek(day.DayOfWeekId)).join(", ")
};

const ServiceLength: React.FC<{ service: ServiceType }> = ({ service }) => {
  return (
    <span>
      <Clock {...c("metaIcon")} />
      {service.DurationTypeId !== 1 ? (
        <>
          {formatMinutesToWords(service.MinDuration)} - {formatMinutesToWords(service.MaxDuration)}
        </>
      ) : service.Duration ? (
        formatMinutesToWords(service.Duration)
      ) : null}
    </span>
  );
};
