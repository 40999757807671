import { ActionType, MessageType } from '@/types';

// users reducer
export default function times(state: MessageType[] = [], action: ActionType) {
    switch (action.type) {
        case 'SET_ERROR_MESSAGE': {
            if (state.some((message) => message.value === action.payload.value)) return state;
            return [...state, { type: 'error', ...action.payload }];
        }
        case 'CLEAR_MESSAGE': {
            return [...state.filter((message) => message.id !== action.payload.id)];
        }
        default:
            return state;
    }
}
