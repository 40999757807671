import React, { useCallback } from 'react';
import { ResourceInterface } from '@/types';
import BEMHelper from 'react-bem-helper';
import { t } from '@lingui/macro';

import './ResourceMini.css';
import { withI18n } from '@lingui/react';
import { I18n } from '@lingui/core';

const c = new BEMHelper('ResourceMini');

interface Props {
    resource: ResourceInterface | null;
    onClick?: (resourceId: number | null) => void;
    className?: string;
    active?: boolean;
    i18n: I18n;
}

const ResourceMini: React.FC<Props> = ({ resource, onClick, className, active = false, i18n }) => {
    const handleClick = useCallback(() => {
        if (onClick) {
            onClick(resource?.Id || null);
        }
    }, [onClick, resource]);

    const name = resource?.Name || i18n._(t('all')``);
    const imgSrc = resource?.ImageUrl || process.env.REACT_APP_BASE_URL + '/resource-placeholder.png';

    return (
        <div data-cy={`resource-mini-${resource?.Id}`} {...c(undefined, { active }, className)} onClick={handleClick} aria-label={name}>
            <div {...c('img-container')}>
                <img {...c('img')} src={imgSrc} alt={name} />
            </div>
            <div {...c('name')}>{name}</div>
        </div>
    );
};

export default withI18n()(ResourceMini);
