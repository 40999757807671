import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { onSubmitActions } from "redux-form-submit-saga";
import { reduxForm, Field, InjectedFormProps } from "redux-form";
import { Alert, Row, Col, FormGroup, Spinner,  Input as BootstrapInput, Label } from "reactstrap";


import Input from "@/components/elements/Input";
import { Button } from "bokamera-embedded-ui";
import { Trans, t } from "@lingui/macro";
import { ApplicationState } from "@/store";
import { withI18n, withI18nProps } from "@lingui/react";

interface Props extends InjectedFormProps<any>, withI18nProps {
  [key: string]: any;
}

interface State {
  acceptAgreement: boolean;
}
export class CreateAccountForm extends React.Component<Props, State> {
  state = { acceptAgreement: false };
  render() {
    const { isLoading, error, handleSubmit, createAccountError, createAccountSuccess, i18n } = this.props;
    
    return (
      <form onSubmit={handleSubmit}>
        <h2 style={{ textAlign: "center" }}>
          <small>
            <Trans id="createAccount" />
          </small>
        </h2>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Field
                className="form-component"
                name="Firstname"
                component={Input}
                label={<Trans id="firstname"></Trans>}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Field
                className="form-component"
                name="Lastname"
                component={Input}
                label={<Trans id="lastname"></Trans>}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <div>
          <Field
            className="form-component"
            name="Email"
            type="email"
            component={Input}
            label={<Trans id="email"></Trans>}
            required
          />
        </div>
        <div>
          <Field
            className="form-component"
            name="Phone"
            component={Input}
            label={<Trans id="phone"></Trans>}
            required
          />
        </div>
        <FormGroup className="px-4">
          <BootstrapInput
            id="accept-agreement"
            type="checkbox"
            onChange={() =>
              this.setState((prevState) => ({
                acceptAgreement: !prevState.acceptAgreement,
              }))
            }
          />
          <Label for="accept-agreement">
            <span dangerouslySetInnerHTML={{
              __html: i18n._(t`acceptAgreement`)
            }}/>
          </Label>
        </FormGroup>
        {!createAccountSuccess ? <div className="form-group">
          <Button block type="submit" disabled={isLoading || !this.state.acceptAgreement}>
            {!isLoading ? <Trans id="createAccount" /> : <Spinner />} 
          </Button>
        </div> : null}
        {error
          ? <Alert color="danger">{error}</Alert> 
          : typeof createAccountError === "string"
          ? <Alert color="danger">{createAccountError}</Alert> : null}
        {createAccountSuccess && <Alert color="success">{createAccountSuccess}</Alert>}
      </form>
    );
  }
}

function mapStateToProps(state: ApplicationState, props: any) {
  return {
    isLoading: state.createAccount.isLoading,
    createAccountError: state.createAccount.error ? state.createAccount.data : undefined,
    createAccountSuccess: state.createAccount?.data?._success || state.createAccount?.data?.ResponseStatus?.Message || state.createAccount?.data?.ResponseStatus,
    onSubmitSuccess: () => {
      if (props.onSuccess) props.onSuccess();
    },
  };
}

export default compose(
  connect(mapStateToProps),
  withI18n(),
  reduxForm({
    form: "createAccountForm",
    onSubmit: onSubmitActions(
      "CREATE_ACCOUNT_REQUEST",
      "CREATE_ACCOUNT_SUCCESS",
      "CREATE_ACCOUNT_FAILURE"
    ),
  })
)(CreateAccountForm) as React.ComponentType;
