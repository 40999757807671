import { AnyAction } from "redux";
import bmApi from "@/services/bmApi";
import { ListenerEffect } from "@reduxjs/toolkit";
import { ApplicationDispatch, ApplicationState } from "@/store";
import { clearCustomer, setCustomer } from "../customer";
import { authoriseFail, authoriseSuccess } from "../authorise";
import { authenticateSuccess } from ".";

export const authenticateAuthoriseEffect: ListenerEffect<
  AnyAction,
  ApplicationState,
  ApplicationDispatch
> = (action, api) => {
  if (authenticateSuccess.match(action)) {
    // @ts-ignore
    api.dispatch(bmApi.endpoints.getUsers.initiate({ IncludeCompanyCustomers: true }));
  }

  if (bmApi.endpoints.getUsers.matchFulfilled(action)) {
    const state = api.getState();
    const companyId = state.company.data?.Id;
    const customerId = action.payload?.CompanyCustomers.find(
      (companyUser: any) => companyUser.CompanyId === companyId
    )?.CustomerId;
    api.dispatch(setCustomer({ user: action.payload, customerId }));
    api.dispatch(authoriseSuccess(action.payload));
  }

  if (bmApi.endpoints.getUsers.matchRejected(action)) {
    api.dispatch(authoriseFail(action.error));
    api.dispatch(clearCustomer())
  }
};
