import React from 'react';

// Partials
import ServicesPartial from '@/components/partials/Services';
import ArticlesPartial from '../partials/Articles';

export function Services() {
    return <>
        <ServicesPartial />
        <ArticlesPartial />
    </>
}

export default Services;
