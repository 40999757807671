import {
  createListenerMiddleware,
  createSlice,
  isAnyOf,
  PayloadAction,
  TypedStartListening,
} from "@reduxjs/toolkit";

interface Entity {
    MinDuration: number;
    MaxDuration: number;
    IntervalDuration: number;
    value?: number;
}

interface InitialState {
    entity: Entity | null;
    serviceId: number;
    error?: string;
};

const initialState: InitialState = {
    entity: null,
    serviceId: 0,
    error: ''
}

const flexibleHoursSlice = createSlice({
  name: "flexibleHours",
  initialState,
  reducers: {
    initialize: (state, { payload }: PayloadAction<{
        data: Entity,
        serviceId: number;
    }>) => {
        state.entity = payload.data;
        state.serviceId = payload.serviceId;
        
        // Set default value to interval duration
        if(!payload.data.value) {
            state.entity.value = payload.data.value;
        }
    },
    destroy: (state) => {
        state.entity = null;
        state.serviceId = 0
    },
    
    setValue: (state, action: PayloadAction<{ value: number, serviceId: number}>) => {
        if(state.entity) {
            state.entity.value = action.payload.value;
            state.serviceId = action.payload.serviceId;
        }
    },
    setError: (state, action: PayloadAction<string>) => {
        state.error = action.payload;
    }
  },
});

export const { initialize, destroy, setValue, setError } =  flexibleHoursSlice.actions;

export default flexibleHoursSlice;