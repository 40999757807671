import _ from "lodash";
import {
  ensureParentIFrameIsSet,
  getConfiguration,
  isEmbed,
} from "./common";

export function createIframeStorage() {
  return {
    setItem: async (key: string, value: string) => {
      try {
        if (isEmbed()) {
          const configuration = getConfiguration();
          const targetOrigin = configuration?.targetOrigin
            ? configuration?.targetOrigin
            : configuration?._targetOrigin;

          window.parent.postMessage(
            {
              setPersistedItem: {
                clientId: key,
                payload: value,
              },
            },
            targetOrigin || "*"
          );
        } else {
          return sessionStorage.setItem(key, value);
        }
      } catch (error) {
        return error;
      }
    },
    getItem: async (key: string) => {
      try {
        if (isEmbed()) {
          const configuration = getConfiguration();
          const targetOrigin = configuration?.targetOrigin
            ? configuration?.targetOrigin
            : configuration?._targetOrigin;
          const iframeId = await ensureParentIFrameIsSet();

          window.parent.postMessage(
            {
              getPersistedItem: {
                clientId: key,
                iframeId,
                targetOrigin,
              },
            },
            targetOrigin || "*"
          );

          const item = await new Promise(function (resolve, reject) {
            const listener = function (
              msg?:
                | {
                    [key in string]: any;
                  }
                | string
            ) {
              if (
                _.isObject(msg) &&
                typeof msg.data === "string" &&
                msg.data.includes("iFrameSizer]message:")
              ) {
                const item = JSON.parse(
                  msg.data.split("iFrameSizer]message:")[1]
                ).getPersistedItemResult;
                const itemKey = JSON.parse(
                  msg.data.split("iFrameSizer]message:")[1]
                ).key;
                if(key === itemKey) {
                  resolve(item);
                  window.removeEventListener("message", listener);
                }
              }
            };

            window.addEventListener("message", listener);
          });

          return item;
        } else {
          return sessionStorage.getItem(key);
        }
      } catch (error) {
        return error;
      }
    },
    removeItem: async (key: string) => {
      try {
        if (isEmbed()) {
          const configuration = getConfiguration();
          const targetOrigin = configuration?.targetOrigin
            ? configuration?.targetOrigin
            : configuration?._targetOrigin;

          window.parent.postMessage(
            {
              removePersistedItem: {
                clientId: key,
              },
            },
            targetOrigin || "*"
          );
        } else {
          return sessionStorage.removeItem(key);
        }
      } catch (error) {
        return error;
      }
    },
  };
}


// @ts-ignore
window.tmp = createIframeStorage();