import axiosInstance from './apiClient';
import { AxiosRequestConfig } from "axios";

export function get<T>(service: string, body?: any) {
    return request<T>('GET', service, body);
}

export function post<T>(service: string, body?: any) {
    return request<T>('POST', service, body);
}

export function put<T>(service: string, body?: any) {
    return request<T>('PUT', service, body);
}

export function remove<T>(service: string, body?: any) {
    return request('DELETE', service, body);
}

interface APIError {
    FieldName: string;
    Message: string;
  }
export function request<T>(method: AxiosRequestConfig["method"], service: string, body?: any, authentication?: boolean): Promise<T> {
    let url = `${process.env.REACT_APP_API_BASE_URL}${service}`;

    axiosInstance.interceptors.response.use(conf => conf, (resError: any) => {
        if(resError._error) {
          return Promise.reject({ _error: resError._error });
        }

        const resData = resError?.response?.data;

        let error = typeof resError ==='string' ? resError : 'Ett okänt fel uppstod';
          
        if (resData?.ResponseStatus) {
            error = resData.ResponseStatus.Message;
        }
      
        let errorObject: any = { _error: error };

        if(
            body &&
            Array.isArray(body?.ResponseStatus?.Errors) &&
            body?.ResponseStatus?.Errors.length === 1 &&
            body?.ResponseStatus?.Message !== body?.ResponseStatus?.Errors[0].Message
        ) {
            errorObject._error = body.ResponseStatus.Errors[0].Message;
        } else if (
            body &&
            Array.isArray(body.ResponseStatus?.Errors) &&
            body.ResponseStatus?.Errors.length > 1
        ) {
            body.ResponseStatus?.Errors.forEach((err: APIError) => {
                errorObject[err.FieldName] = err.Message;
            });
        }

        // @ts-ignore
        if (!error._error && error?.message) {
            // If not an error produced from the above code.
            // @ts-ignore
            let errorObject = { _error: error.message };
            
            return Promise.reject(errorObject);
        }
        
        
        return Promise.reject(errorObject)
      });

    return axiosInstance.request({
        url,
        method,
        data: body ? body : undefined,
    }).then(res => res.data);
};
