import React from 'react';

import './Spinner.css';

interface Props {
    noGraceTime?: boolean;
    className?: string;
     // Before primary color settings are loaded, show grey color
    noColor?: boolean;
}
interface State {
    graceTimeExpired: boolean;
}

export class CustomSpinner extends React.Component<Props, State> {
    private timer?: NodeJS.Timeout;

    constructor(props: Props) {
        super(props);

        this.state = { graceTimeExpired: props.noGraceTime ? true : false };
        if (!props.noGraceTime) {
            this.initGraceTimeTimeout();
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    render() {
        const { className, noColor } = this.props;
        if (!this.state.graceTimeExpired) return false;

        return (
            <div className={className} data-testid="loader">
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 12 }}>
                    <div className={`lds-roller ${noColor ? ' no-color': ''}`}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>{this.props.children}</div>
                </div>
            </div>
        );
    }

    initGraceTimeTimeout = () => {
        this.timer = setTimeout(() => {
            this.setState({ graceTimeExpired: true });
        }, 500);
    };
}

export default CustomSpinner;
