import React from 'react';
import { ReactComponent as Minus } from '@/components/svg/minus.svg';
import { ReactComponent as Plus } from '@/components/svg/plus.svg';
import './Counter.css';

interface Props {
    value: number;
    min?: number | null;
    max?: number | null;
    onDecrement(e: React.MouseEvent<any>): void;
    onIncrement(e: React.MouseEvent<any>): void;
}

const defaultProps = {
    min: 0,
};

const Counter = (props: Props) => {
    const { value, min, max } = props;
    return (
        <div className="Counter">
            <button
                disabled={!value || value === min}
                color="default"
                onClick={props.onDecrement}
                data-testid="Counter__decrement"
                className="Counter__button"
            >
                <Minus />
            </button>
            <div className="Counter__count" data-testid="Counter__count">
                {value}
            </div>
            <button
                onClick={props.onIncrement}
                disabled={value === max}
                data-testid="Counter__increment"
                className="Counter__button"
            >
                <Plus />
            </button>
        </div>
    );
};

Counter.defaultProps = defaultProps;

export default Counter;
