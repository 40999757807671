import React from "react";
import { Table } from "reactstrap";

// Partials
import Quantities from "@/components/partials/Quantities";

import { Article } from "@/types";
import { Trans } from "@lingui/macro";
import ArticleQuantities from "./ArticleQuantities";

interface Props {
  article: Article;
}

export function ArticleSummary({ article }: Props) {
  
  return article ? (
    <div>
      <Table size="sm" responsive borderless>
        <tbody>
          <tr>
            <td>
              <strong>
                <Trans id="ArticleSummary.name"></Trans>
              </strong>
            </td>
            <td>{article.Name}</td>
          </tr>
          <tr>
            <td>
              <strong>
                <Trans id="ArticleSummary.description"></Trans>
              </strong>
            </td>
            <td>{article.Description}</td>
          </tr>
        </tbody>
      </Table>
      <ArticleQuantities 
        article={article}
        quantity={1}
        // @ts-ignore
        currency={article.PriceSign}
      />
    </div>
  ) : null;
}

export default ArticleSummary;
