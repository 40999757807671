import React, { useState, SFC } from 'react';
import Truncate from 'react-truncate';
import BEMHelper from 'react-bem-helper';

import LinkButton from '@/components/elements/LinkButton';
import { Trans } from '@lingui/macro';

import './ShowMoreText.css';

interface Props {
    children: string;
    lines?: number;
}

const c = new BEMHelper({
    name: 'ShowMoreText',
});

const ShowMoreText: SFC<Props> = (props) => {
    const { children, lines = 3 } = props;
    const [expanded, setExpanded] = useState(false);
    const [truncated, setTruncated] = useState(false);

    function handleTruncate(nextTruncated: boolean) {
        if (truncated !== nextTruncated) {
            setTruncated(nextTruncated);
        }
    }

    function toggleLines(event: React.MouseEvent) {
        event.preventDefault();
        setExpanded(!expanded);
    }

    return (
        <div {...c()}>
            <Truncate
                lines={!expanded && lines}
                ellipsis={
                    <span>
                        ...{' '}
                        <LinkButton {...c('link')} onClick={toggleLines}>
                            <Trans id="showMore"></Trans>
                        </LinkButton>
                    </span>
                }
                onTruncate={handleTruncate}
            >
                {children}
            </Truncate>
            {!truncated && expanded && (
                <span>
                    {' '}
                    <LinkButton {...c('link')} onClick={toggleLines}>
                        <Trans id="showLess"></Trans>
                    </LinkButton>
                </span>
            )}
        </div>
    );
};

export default ShowMoreText;
