import React from 'react';
import { connect } from 'react-redux';

// Pages
import TimesService from '@/components/pages/TimesService';
import TimesServices from '@/components/pages/TimesServices';
import TimesResources from '@/components/pages/TimesResources';

import { ConfigKeys } from '@/misc/constants';
import { Configuration, ServiceType } from '@/types';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '@/store';

interface Props extends RouteComponentProps<{ serviceId: string }> {
    configuration: Configuration;
    services: any;
}

export function Times(props: Props) {
    const { configuration, services } = props;
    const bookLayout = configuration[ConfigKeys.BOOK_LAYOUT];

    const serviceId = props.match.params?.serviceId;

    const hasSelectedResource = services.data.some((service: ServiceType) => (
        service.Id.toString() === serviceId &&
        service.ResourceTypes.length > 0 &&
        service.ResourceTypes.some(
          (resourceType) => resourceType.SelectableByUser
        )
    ));

    if (bookLayout === ConfigKeys.BOOK_LAYOUT_SERVICE_BASED) {
        return <TimesService serviceId={serviceId} />;
    }
    if (bookLayout === ConfigKeys.BOOK_LAYOUT_TIME_BASED) {
        return <TimesServices />;
    }
    
    if (bookLayout === ConfigKeys.BOOK_LAYOUT_RESOURCE_BASED && hasSelectedResource) {
        return <TimesResources serviceId={serviceId} />
    }

    if (bookLayout === ConfigKeys.BOOK_LAYOUT_RESOURCE_BASED && !hasSelectedResource) {
        return <TimesService serviceId={serviceId} />
    }

    return null;
}

// export the connected class
const mapStateToProps = ({ configuration, services }: ApplicationState) => ({
    configuration: configuration.data,
    services
});

export default connect(mapStateToProps)(Times);
