import React, { createRef } from "react";
import { compose } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { onSubmitActions } from "redux-form-submit-saga";
import { reduxForm, InjectedFormProps, getFormValues } from "redux-form";
import { Alert, Spinner, Card, Row, Col, Label } from "reactstrap";
import { getFormSubmitErrors, Field } from "redux-form";
import BEMHelper from "react-bem-helper";

// Partials
import Agreement from "@/components/partials/Agreement";
import ArticleSummary from "@/components/partials/ArticleSummary";

// Forms
import CustomerForm from "@/components/forms/CustomerForm";
import LoginForm from "@/components/forms/LoginForm";
import CreateAccountForm from "../forms/CreateAccountForm";

// Components
import { Button } from "bokamera-embedded-ui";
import Loading from "@/components/elements/Spinner";
import Panel from "@/components/elements/Panel";

import { ConfigKeys, STEP_CONTAINER_HEIGHT } from "@/misc/constants";

import { Trans } from "@lingui/macro";
import * as actions from "@/actions";
import { RouteComponentProps, withRouter } from "react-router";
import { reset } from "redux-form";
import { withI18n, withI18nProps } from "@lingui/react";

import "./Confirm.css";
import { ApplicationDispatch, ApplicationState } from "@/store";
import Input from "../elements/Input";
import { confirmArticle, selectArticle } from "@/reducers/article";
import { Article } from "@/types";
import ErrorAlert from "../partials/ErrorAlert";
import RecepientForm from "../forms/RecepientForm";
import { scrollToElement } from "@/misc/common";
import readConfigurationProperty from "@/misc/readConfigurationProperty";


const c = new BEMHelper("Confirm");

enum TabIds {
  CONTACT_INFORMATION_BOOKING_TAB_ID = "0",
  LOGIN_BOOKING_TAB_ID = "1",
  CREATE_ACCOUNT_TAB_ID = "2",
}

interface State {
  activeId: string | number;
  hideBookingButtons: boolean;
}
interface Props extends ReduxProps, RouteComponentProps, withI18nProps {
  dispatch: ApplicationDispatch
}

export function withBookingRequirements(WrappedComponent: React.ComponentType) {
  const ComponentWithUser: React.FC<ReduxProps> = (props) => {

    if(props.authentication.isLoggedIn && !props.user?.CustomerProfile) {
      return <Loading />;
    }

    return <WrappedComponent {...props} />;
  };

  return ComponentWithUser;
}

export class ArticleConfirm extends React.Component<Props & InjectedFormProps, State> {
  state = { activeId: TabIds.CONTACT_INFORMATION_BOOKING_TAB_ID, hideBookingButtons: false  };

  bookingButtonsContainerRef = createRef<any>();
  containerRef = createRef<any>();

  componentDidMount() {
    scrollToElement({
      element: this.containerRef.current,
      scrollOffsetY: Number(readConfigurationProperty("topOffset", 0)) + STEP_CONTAINER_HEIGHT,
    });
  }

  render() {
    const { user, authentication, booking, company, configuration, dispatch, article } =
      this.props;

    const useContactInformationBooking =
      configuration.bookMethods.indexOf(
        ConfigKeys.BOOK_METODS_CONTACT_INFORMATION
      ) > -1;
    const useLoginBooking =
      configuration.bookMethods.indexOf(ConfigKeys.BOOK_METODS_LOGIN) > -1;
    const useCreateAccount =
      configuration.bookMethods.indexOf(ConfigKeys.BOOK_METODS_CREATE_ACCOUNT) >
      -1;
    const useShowRebateCodeField = configuration.showRebateCodeField;

    let tabs = [];
    
    if (useContactInformationBooking) {
      tabs.push({
        id: TabIds.CONTACT_INFORMATION_BOOKING_TAB_ID,
        title: <Trans id="book" />,
        content: this.renderBookActions(),
      });
    }
    if (useLoginBooking) {
      tabs.push({
        id: TabIds.LOGIN_BOOKING_TAB_ID,
        title: <Trans id="logIn"></Trans>,
        content: this.renderLogin(),
      });
    }

    if (useCreateAccount) {
      tabs.push({
        id: TabIds.CREATE_ACCOUNT_TAB_ID,
        title: <Trans id="createAccount"></Trans>,
        content: this.renderCreateAccount(),
      });
    }

    return (
      <div {...c()} ref={this.containerRef}>
        <div {...c("row")}>
          <div {...c("mainCol")}>
            {authentication.isLoading && (
              <Loading>
                <Trans id="authenticationLoading"></Trans>
              </Loading>
            )}
            {!authentication.isLoading &&
            authentication.isLoggedIn &&
            user?.CustomerProfile?.Email ? (
              <div>
                <Panel title={<Trans id="confirm.customerFormTitle" />}>
                  <CustomerForm />
                  <Alert color="info">
                    <p>
                      <Trans id="youAreLoggedInAs"></Trans>{" "}
                      {user.CustomerProfile.Email}.
                    </p>
                    <Button
                      block
                      onClick={() => {
                        dispatch(actions.logout());
                      }}
                    >
                      <Trans id="logOut"></Trans>
                    </Button>
                  </Alert>
                </Panel>
              </div>
            ) : null}
            {!authentication.isLoading && !authentication.isLoggedIn ? (
              <>
                {this.state.activeId ===
                TabIds.CONTACT_INFORMATION_BOOKING_TAB_ID
                  ? this.renderBookActions()
                  : null}

                {this.state.activeId === TabIds.CREATE_ACCOUNT_TAB_ID
                  ? this.renderCreateAccount()
                  : null}
              </>
            ) : null}
          </div>
          <div {...c("summaryCol")}>
            <Card>
              <div {...c("summaryBody")}>
                {article.selected  ? (
                  <ArticleSummary
                    article={article.selected}
                  />
                ) : null}
              </div>
              <div {...c("summaryFooter")}>{this.renderBook()}</div>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  renderCreateAccount = () => {
    // @ts-ignore
    const { createAccount, dispatch } = this.props;
    const isSuccess =
      !createAccount.error && createAccount.data?.ResponseStatus ? true : false;

    return (
      <Panel style={{ marginTop: -2 }}>
        <CreateAccountForm />
        {!isSuccess ? (
          <Button
            {...c("cancel")}
            outline
            block
            onClick={() => {
              dispatch(reset("createAccountForm"));
              this.setState({
                activeId: TabIds.CONTACT_INFORMATION_BOOKING_TAB_ID,
              });
            }}
          >
            <Trans id="cancel" />
          </Button>
        ) : (
          <Button
            block
            onClick={() => {
              dispatch(reset("createAccountForm"));
              this.setState({
                activeId: TabIds.CONTACT_INFORMATION_BOOKING_TAB_ID,
              });
            }}
          >
            <Trans id="confirm.goBackToBooking" />
          </Button>
        )}
      </Panel>
    );
  };

  renderBookActions = () => {
    const { configuration, dispatch, prices, i18n, differentRecipient, article } = this.props;

    const useContactInformationBooking =
      configuration.bookMethods.indexOf(
        ConfigKeys.BOOK_METODS_CONTACT_INFORMATION
      ) > -1;

    const useLoginBooking =
      configuration.bookMethods.indexOf(ConfigKeys.BOOK_METODS_LOGIN) > -1;
    const useCreateAccount =
      configuration.bookMethods.indexOf(ConfigKeys.BOOK_METODS_CREATE_ACCOUNT) >
      -1;

    return (
      <React.Fragment>
        {useContactInformationBooking ? (
          <Panel style={{ marginTop: -2 }}>
            <CustomerForm anonymous />
            {article.selected?.ArticleTypeId === 4 ? <Row className="mt-2">
                <Col style={{ display: "flex", gap: "8px" }}>
                  <Field
                    style={{
                      position: "relative",
                      marginLeft: 0,
                      marginTop: 0,
                    }}
                    id="_differentRecipient"
                    className="form-component"
                    name="_differentRecipient"
                    type="checkbox"
                    component={Input}
                  />
                  <Label for="_differentRecipient">
                    <Trans id="ArticleConfirm.differentRecipient" />
                  </Label>
                </Col>
              </Row> : null}
            {differentRecipient ? <RecepientForm /> : null}
          </Panel>
        ) : null}

        {useLoginBooking || useCreateAccount ? (
          <Panel>
            <div {...c("quickActions")}>
              {useLoginBooking ? (
                <React.Fragment>
                  <Button
                    block
                    disabled={prices.isLoading || !prices.data}
                    onClick={(evt) => {
                      evt.preventDefault();
                      dispatch(actions.login());
                    }}
                  >
                    <Trans id="logIn"></Trans>
                  </Button>
                </React.Fragment>
              ) : null}
              {useLoginBooking && useCreateAccount ? (
                <div {...c("orText m-2")}>
                  <Trans id="common.or"></Trans>
                </div>
              ) : null}
              {useCreateAccount ? (
                <Button
                  block
                  onClick={() => {
                    this.setState({ activeId: TabIds.CREATE_ACCOUNT_TAB_ID });
                  }}
                >
                  <Trans id="createAccount"></Trans>
                </Button>
              ) : null}
            </div>
            {useLoginBooking && (
              <Alert color="info">
                <span
                  dangerouslySetInnerHTML={{
                    __html: i18n._("doYouHaveAccount"),
                  }}
                />
              </Alert>
            )}
          </Panel>
        ) : null}
      </React.Fragment>
    );
  };

  sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  renderLogin = () => {
    return (
      <Panel style={{ marginTop: -2 }}>
        <LoginForm
          handleSubmit={() => {
            // After login is done, switch active tab to 0
            this.setState({
              activeId: TabIds.CONTACT_INFORMATION_BOOKING_TAB_ID,
            });
          }}
        />
      </Panel>
    );
  };

  renderBook = () => {
    const { error, submitErrors, change, bookButtonsBottom, configuration, article, dispatch } = this.props;
    
    const bookingsContainerHeight = this.bookingButtonsContainerRef.current
      ? this.bookingButtonsContainerRef.current.getBoundingClientRect().height
      : 0;

    const isTouchingBottom = bookButtonsBottom - 40 > 0;

    return (
      <form
        {...c("footer")}
        onSubmit={(evt) => {
          evt.preventDefault();

          if (article.selected) {
            dispatch(confirmArticle(article.selected));
          }
        }}
      >
        <div>
          {error && (
            <Alert color="danger">
              {error}
              {submitErrors && (
                <ul>
                  {Object.keys(submitErrors).map((key) => (
                    // @ts-ignore
                    <li key={key}>{submitErrors[key]}</li>
                  ))}
                </ul>
              )}
            </Alert>
          )}

          {article.error ? (
            <ErrorAlert  error={article.error} />
          ) : null}

          <div {...c("agreement")}>
            <Agreement />
          </div>
          <div
            {...c("placeholder")}
            style={{
              height:
                isTouchingBottom && this.bookingButtonsContainerRef?.current
                  ? `${
                      this.bookingButtonsContainerRef.current.getBoundingClientRect()
                        .height
                    }px`
                  : "0",
            }}
          ></div>
          <div
            ref={this.bookingButtonsContainerRef}
            {...c("bookButtons", isTouchingBottom ? "fixed" : "relative")}
            style={{
              bottom: isTouchingBottom
                ? bookButtonsBottom + 34
                : 0 + bookingsContainerHeight,
            }}
          >
            <Button
              {...c("submitButton")}
              primary
              type="submit"
              disabled={article.isLoading}
              onClick={() => {
                setTimeout(() => {
                  const scrollTo = bookButtonsBottom;
                  if (isTouchingBottom) {
                    window.parentIFrame.scrollToOffset(
                      0,
                      scrollTo + configuration.topOffset!
                    );
                  }
                }, 300);
              }}
            >
              <Trans id="Article.buy"></Trans>
            </Button>

            {article.isLoading && !article.error ? (
              <div {...c("loader", "absolute", `mt-1 text-center`)}>
                <Spinner />
              </div>
            ) : null}
          </div>
        </div>
      </form>
    );
  };
}

function mapStateToProps(state: ApplicationState, props: any) {
  // @ts-ignore
  const differentRecipient: any = getFormValues('createArticleForm')(state)?._differentRecipient
  
  return {
    initialValues: {},
    differentRecipient,
    authentication: state.authenticate,
    services: state.services || [],
    booking: state.booking,
    configuration: state.configuration.data,
    company: state.company.data,
    prices: state.prices,
    submitErrors: getFormSubmitErrors("bookForm")(state),
    createAccount: state.createAccount,
    user: state.user?.data,
    bookButtonsBottom: state.ui.parentScrollTop,
    article: state.article
  };
}

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export default compose(
  withRouter,
  connector,
  withBookingRequirements,
  withI18n(),
  reduxForm({
    form: "createArticleForm"
  })
)(ArticleConfirm) as React.ComponentType;
