import React from 'react';
import { Field } from 'redux-form';

import Input from './Input';
import { CustomFieldType } from '@/types';

interface Props {
    name: string;
    customField: CustomFieldType;
}

export class CustomFieldTextBox extends React.Component<Props> {
    validateRegEx = (value: string) => {
        let re = new RegExp(this.props.customField.RegEx, 'g');

        return !re.test(value) ? this.props.customField.RegExErrorMessage : undefined;
    };

    validateMandatory = (value: string) => {
        return !value ? this.props.customField.MandatoryErrorMessage : undefined;
    };

    render() {
        const { customField, name } = this.props;

        let validations = [];

        if (customField.RegEx && customField.RegEx.length > 0) {
            validations.push(this.validateRegEx);
        }

        if(customField.IsMandatory) {
            validations.push(this.validateMandatory);
        }

        return (
            <Field
                className="form-component"
                name={name}
                component={Input}
                type={customField.MultipleLineText ? 'textarea' : 'text'}
                key={customField.Id}
                help={customField.Description}
                label={`${customField.Name}`}
                maxLength={customField.MaxLength}
                validate={validations}
            />
        );
    }
}

export default CustomFieldTextBox;
