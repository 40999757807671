import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Row, Col } from "reactstrap";

import { MdPrint } from "react-icons/md";
import BEMHelper from "react-bem-helper";

// Partials
import Company from "@/components/partials/Company";

// Elements
import { Button } from "bokamera-embedded-ui";
import Panel from "@/components/elements/Panel";

import "./Completed.css";
import { Trans } from "@lingui/macro";
import { useHistory } from "react-router";
import { ApplicationState } from "@/store";
import QueueSummary from "../partials/QueueSummary";


type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & { children: React.ReactNode }

const c = new BEMHelper({ name: "Completed" });

export const AddedToQueue: React.FC<Props> = (props) => {
  const { company, booking } = props;
  const history = useHistory();

  if (!booking || !company) return null;

  return (
    <Panel>
      <div id="section-to-print">
          <h4
            className="text-left text-success"
            style={{ marginBottom: 20, padding: "0.3rem" }}
          >
            <Trans id="addedToQueueConfirmation"></Trans>
          </h4>
        {company?.BookingSettings?.BookingReceiptMessage && (
          <p style={{ marginBottom: 20, padding: "0.3rem" }}>
            {company.BookingSettings.BookingReceiptMessage}
          </p>
        )}

        <div>
          <Row {...c("body")}>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Company />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <QueueSummary booking={booking} />
            </Col>
          </Row>
          <Row className="hidden-print">
            <Col xs={12}>
              <Button
                className="button float-left"
                onClick={() => {
                  history.push("/");
                  props.dispatch({
                    type: "RESET_FORM",
                  });
                }}
              >
                <Trans id="bookANewTime"></Trans>
              </Button>
              <Button
                className="button float-right"
                onClick={() => {
                  window.print();
                }}
              >
                <MdPrint /> <Trans id="print"></Trans>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Panel>
  );
};

const mapStateToProps = ({
  booking,
  company,
  configuration
}: ApplicationState) => ({
  booking: booking,
  company: company.data,
  configuration: configuration.data,
});

const connector = connect(mapStateToProps);

export default connector(AddedToQueue);
