import React, { useCallback, useEffect } from 'react';
import { debounce, range } from 'lodash';
import Panel from '@/components/elements/Panel';
import Slider from 'rc-slider';
import { Trans } from '@lingui/macro';
import { initialize, destroy, setValue } from '@/reducers/flexibleHours';
import { withI18n, withI18nProps } from '@lingui/react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { ServiceType } from '@/types';

import 'rc-slider/assets/index.css';
import './Slider.css';
import { formatTimeToWords } from '@/misc/datetime';
import { getUnit } from '@/misc/common';

export function getSliderValues(service: ServiceType) {
  const sliderMinDuration = service.MinDuration;
  const sliderDurationInterval = service.DurationInterval;
  const serviceDuration = service.Duration;

  const sliderMaxDuration = (service.MaxDuration - sliderMinDuration) % sliderDurationInterval === 0
    ? service.MaxDuration
    : service.MaxDuration - (
      (service.MaxDuration - sliderMinDuration) % sliderDurationInterval
    );


  let marks = range(sliderMinDuration, sliderMaxDuration + 1, sliderDurationInterval);
  
  return { marks, sliderMinDuration, sliderMaxDuration, sliderDurationInterval, serviceDuration };
}

interface Props extends withI18nProps {
    service: ServiceType;
    onChange: (val: number) => void
}

const DEBOUNCE_CHANGE_TIME = 300;

const FlexibleHoursSlider: React.FC<Props> = ({
    service, onChange = () => { },
    ...restProps
}) => {
    const flexibleHours = useAppSelector((s) => s.flexibleHours);
    const dispatch = useAppDispatch();

    const _onChange = useCallback(
        debounce((val) => onChange(val), DEBOUNCE_CHANGE_TIME),
        []
    );

    useEffect(() => {
        if (
          service.DurationTypeId === 2 &&
          flexibleHours.serviceId !== service.Id
        ) {
          dispatch(
            initialize({
              data: {
                IntervalDuration: service.DurationInterval,
                MinDuration: service.MinDuration,
                MaxDuration: service.MaxDuration,
                value: service.Duration,
              },
              serviceId: service.Id,
            })
          );
        }
    }, [service]);

    useEffect(() => {
        if (flexibleHours?.entity?.value) {
            _onChange(flexibleHours.entity.value);
        }
    }, [flexibleHours?.entity?.value]);

    const {
      marks: _marks,
      sliderMinDuration,
      sliderMaxDuration,
      sliderDurationInterval,
      serviceDuration,
    } = getSliderValues(service);

    const unit = getUnit(_marks)

    let marks = _marks
        .reduce((acc, curr) => {
            acc[curr] = formatTimeToWords(
              curr,
              unit || "minute",
              restProps.i18n,
              _marks.length < 7 ? "normal" : 'hide'
            );

            return acc;
        }, {} as {
            [key in string]: any;
        });

    return flexibleHours ? (
      <Panel>
        <div className="slider-wrapper" data-cy="flexible-hours-slider">
          <p>
            <Trans id="FlexibleHoursSlider.selectDuration" />
            {unit && Object.keys(marks).length >= 7 ? (
              <span>
                {" "}
                (
                {unit === "day" ? (
                  <Trans id="common.days" />
                ) : unit === "hour" ? (
                  <Trans id="common.hours" />
                ) : unit === "minute" ? (
                  <Trans id="common.minutes" />
                ) : null}
                )
              </span>
            ) : null}
          </p>
          <Slider
            onChange={(val) =>
              dispatch(
                setValue({
                  value: parseInt(val.toString()),
                  serviceId: service.Id,
                })
              )
            }
            min={sliderMinDuration}
            max={sliderMaxDuration}
            step={sliderDurationInterval}
            defaultValue={flexibleHours?.entity?.value ?? serviceDuration}
            marks={marks}
          />
        </div>
        {flexibleHours.error ? (
          <div className="error">{flexibleHours.error}</div>
        ) : null}
      </Panel>
    ) : null;
};

export default withI18n()(FlexibleHoursSlider);