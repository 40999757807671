import React, { useCallback, useEffect } from 'react';
import { debounce, range } from 'lodash';
import Panel from '@/components/elements/Panel';
import { Trans } from '@lingui/macro';
import { withI18n, withI18nProps } from '@lingui/react';
import { initialize, destroy, setValue } from '@/reducers/flexibleHours';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { ServiceType } from '@/types';

import 'rc-slider/assets/index.css';
import './Slider.css';
import { formatTimeToWords } from '@/misc/datetime';
import { getSliderValues } from './FlexibleHoursSlider';
import { getUnit } from '@/misc/common';


interface Props extends withI18nProps {
    service: ServiceType;
    onChange: (val: number) => void
}

const DEBOUNCE_CHANGE_TIME = 300;

const FlexibleHoursDropdown: React.FC<Props> = ({
    service, onChange = () => { },
    ...restProps
}) => {
    const flexibleHours = useAppSelector((s) => s.flexibleHours);
    const dispatch = useAppDispatch();

    const _onChange = useCallback(
        debounce((val) => onChange(val), DEBOUNCE_CHANGE_TIME),
        []
    );

    useEffect(() => {
        if (
          service.DurationTypeId === 3 &&
          flexibleHours.serviceId !== service.Id
        ) {
          dispatch(
            initialize({
              data: {
                IntervalDuration: service.DurationInterval,
                MinDuration: service.MinDuration,
                MaxDuration: service.MaxDuration,
                value: service.Duration,
              },
              serviceId: service.Id,
            })
          );
        }
    }, [service]);

    useEffect(() => {
        if (flexibleHours?.entity?.value) {
            _onChange(flexibleHours.entity.value);
        }
    }, [flexibleHours?.entity?.value]);
    
    const {
      marks: _marks,
    } = getSliderValues(service);

    const unit = getUnit(_marks);

    let marks = _marks
        .map((mark) => ({
            value: mark,
            text: formatTimeToWords(mark, unit || "minute", restProps.i18n)
        }));

    return flexibleHours ? (
      <Panel>
        <div className="slider-wrapper" data-cy="flexible-hours-slider">
          <p>
            {unit ? (
              <span>
                <Trans id="FlexibleHoursSlider.selectDuration" />
              </span>
            ) : null}
          </p>
          <select
            data-cy="flexible-hours-dropdown"
            className="custom-select"
            defaultValue={flexibleHours?.entity?.value ?? service.Duration}
            onChange={(evt) => {
              dispatch(
                setValue({
                  value: Number(evt.target.value),
                  serviceId: service.Id,
                })
              );
              _onChange(evt.target.value);
            }}
          >
            {marks.map((mark, i) => {
              return (
                <option key={i} value={mark.value}>
                  {mark.text}
                </option>
              );
            })}
          </select>
        </div>
        {flexibleHours.error ? (
          <div className="error">{flexibleHours.error}</div>
        ) : null}
      </Panel>
    ) : null;
};

export default withI18n()(FlexibleHoursDropdown);
