import React from 'react';
import { t } from '@lingui/macro';
import { withI18n, withI18nProps } from '@lingui/react';
import { getUnitFromCalculationTypeId } from '@/misc/common';
import { definitions } from '@/apitypes';
import { PriceType } from '@/types';


type Service = Required<definitions["ServiceQueryResponse"]>;
// type Price = Required<Service['Prices'][0]>;

interface Props extends withI18nProps {
    sign: string;
    value: number | string;
    calculationTypeId: number; 
    style?: 'normal' | 'narrow' | 'short' | 'hide';
}
const LocalizedPricePerTime: React.FC<Props> = ({ sign, value, calculationTypeId, i18n, style = 'normal' }) => {
    const unit = getUnitFromCalculationTypeId(calculationTypeId);

    if (unit === 'minute') {
        const timeUnit = style === 'narrow'
            ? i18n._(t`common.minute.narrow`)
            : style === 'short'
                ? i18n._(t`common.minute`).toString().slice(0, 1)
                : i18n._(t`common.minute`);
        return <>{`${value}${sign}${style !== 'hide' ? `/${timeUnit}` : ''}`}</>;
    } else if (unit === 'hour') {
        const timeUnit = style === 'narrow'
            ? i18n._(t`common.hour.narrow`)
            : style === 'short'
                ? i18n._(t`common.hour`).toString().slice(0, 1)
                : i18n._(t`common.hour`);
        return <>{`${value}${sign}${style !== 'hide' ? `/${timeUnit}` : ''}`}</>;
    } else if (unit === 'day') {
        const timeUnit = style === 'narrow'
            ? i18n._(t`common.day.narrow`)
            : style === 'short'
                ? i18n._(t`common.day`).toString().slice(0, 1)
                : i18n._(t`common.day`);
        return <>{`${value}${sign}${style !== 'hide' ? `/${timeUnit}` : ''}`}</>;
    }

    return <>{`${value}${sign}`}</>;
};

export default withI18n()(LocalizedPricePerTime);