import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Table } from 'reactstrap';
import { definitions } from '@/apitypes'

// Partials
import Quantities from '@/components/partials/Quantities';

import { format, isSameDay, getFormattedDateTime } from '@/misc/datetime';
import { Trans } from '@lingui/macro';
import { ApplicationState } from '@/store';
import { BookingStateType, BookingType } from '@/types';

interface Props {
  booking: BookingStateType
}

export function QueueSummary(props: Props) {
    const service = props.booking?.bookingUserQueue
        ? props.booking.bookingUserQueue?.Service
        : props.booking.service
    const booking = props.booking;

    const time = props.booking.time!;

    const dateTimeFormat = 'd MMM, p';
    const timeFormat = 'p';
    const formattedTime = isSameDay(time.From, time.To)
        ? `${getFormattedDateTime(time.From)}-${format(time.To, timeFormat)}`
        : `${getFormattedDateTime(time.From)} - ${format(time.To, dateTimeFormat)}`;


    return (
      <div>
        <Table size="sm" responsive borderless>
          <tbody>
            {booking.bookingUserQueue?.BookingUserQueueId ? (
              <tr>
                <td>
                  <strong>
                    <Trans id="bookingUserQueueId"></Trans>
                  </strong>
                </td>
                <td>{booking.bookingUserQueue?.BookingUserQueueId}</td>
              </tr>
            ) : null}
            {service ? (
              <tr>
                <td>
                  <strong>
                    <Trans id="service"></Trans>
                  </strong>
                </td>
                <td>{service.Name}</td>
              </tr>
            ) : null}
            <tr>
              <td>
                <strong>
                  <Trans id="time"></Trans>
                </strong>
              </td>
              <td>{formattedTime}</td>
            </tr>
          </tbody>
        </Table>
        <Quantities />
      </div>
    );
}

export default QueueSummary;
