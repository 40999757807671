import React from 'react';
import { Field } from 'redux-form';

// Elements
import Input from './Input';
import { CustomFieldValueType, CustomFieldType } from '@/types';

interface Props {
    customField: CustomFieldType;
    name: string;
}

export class CustomFieldDropDown extends React.Component<Props> {
    validateMandatory = (value: string) => {
        return !value ? this.props.customField.MandatoryErrorMessage : undefined;
    };

    render() {
        const { customField, name } = this.props;

        let validations = [];

        if(customField.IsMandatory) {
            validations.push(this.validateMandatory);
        }

        return (
            <Field
                className="form-component"
                name={name}
                component={Input}
                key={customField.Id}
                required={customField.IsMandatory}
                label={`${customField.Name}`}
                type="select"
                help={customField.Description}
                validate={validations}
            >
                <option />
                {customField.Values &&
                    customField.Values.map((valueObject: CustomFieldValueType) => {
                        return (
                            <option key={valueObject.Value} value={valueObject.Value}>
                                {valueObject.Value}
                            </option>
                        );
                    })}
            </Field>
        );
    }
}

export default CustomFieldDropDown;
