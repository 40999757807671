import React from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { Row, Col } from 'reactstrap';
import BEMHelper from 'react-bem-helper';

import Spinner from '@/components/elements/Spinner';
import Calendar from '@/components/elements/Calendar';

import { format, addMonths } from '@/misc/datetime';

import { ServicesStateType, TimesStateType, Configuration, ServiceType } from '@/types';
import { Trans } from '@lingui/macro';
import withAutoScroll from '@/hoc/withAutoScroll';
import { ApplicationState } from '@/store';

interface Props {
    configuration: Configuration;
    services: ServicesStateType;
    service?: ServiceType;
    times: TimesStateType;
    dispatch: Dispatch<any>;
}

interface OwnProps {
    service?: ServiceType;
}

const c = new BEMHelper({
    name: 'CalendarQuarter',
});

export class CalendarQuarter extends React.Component<Props> {
    render() {
        const { configuration, services, times, service } = this.props;
        const { navigationDate } = configuration;

        // Make sure that atleast some times are loaded before showing the quarter view
        const finishedLoading = services.data
            .map((service) => times[service.Id].isLoading)
            .filter((isLoading) => isLoading === false);
        if (finishedLoading.length === 0)
            return (
                <Spinner>
                    <Trans id="loadingTimes"></Trans>
                </Spinner>
            );

        return (
            <Row {...c()}>
                <Col xs={12} md={6} lg={4} xl={4}>
                    <strong>{format(addMonths(navigationDate, 0), 'MMMM yyyy')}</strong>
                    <Calendar date={navigationDate} service={service} />
                </Col>
                <Col xs={12} md={6} lg={4} xl={4}>
                    <strong>{format(addMonths(navigationDate, 1), 'MMMM yyyy')}</strong>
                    <Calendar date={addMonths(navigationDate, 1)} service={service} />
                </Col>
                <Col xs={12} md={6} lg={4} xl={4}>
                    <strong>{format(addMonths(navigationDate, 2), 'MMMM yyyy')}</strong>
                    <Calendar date={addMonths(navigationDate, 2)} service={service} />
                </Col>
            </Row>
        );
    }
}

// export the connected class
const mapStateToProps = ({ configuration, booking, services, times }: ApplicationState) => ({
    configuration: configuration.data,
    booking,
    services,
    times,
});

export default compose<React.ComponentType<OwnProps>>(connect(mapStateToProps), withAutoScroll)(CalendarQuarter);
