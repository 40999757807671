import { login, logout, redirectAfterLogin, redirectAfterLogout, rehydrateState } from "@/actions";
import { isDirect, isEmbed } from "@/misc/common";
import { PayloadAction } from "@reduxjs/toolkit";
import { authenticateSuccess } from ".";


// Embed
export const isSelectServiceTypeEmbed = (
  action: PayloadAction
): action is PayloadAction => {
  return (
    action.type === "SELECT_SERVICE" &&
    isEmbed()
  );
};

export const isRedirectAfterLoginEmbed = (
  action: PayloadAction
): action is PayloadAction => {
  return (
    redirectAfterLogin.match(action) &&
    isEmbed()
  );
};

export const isReLoginEmbed = (
  action: PayloadAction
): action is PayloadAction => {
  return (
    rehydrateState.match(action) &&
    isEmbed()
  );
};

export const isReLoginDirect = (
  action: PayloadAction
): action is PayloadAction => {
  return (
    rehydrateState.match(action) &&
    isDirect()
  );
};

export const isAuthenticateSuccessEmbed = (
  action: PayloadAction
): action is PayloadAction => {
  return (
    authenticateSuccess.match(action) &&
    isEmbed()
  );
};

export const isRedirectAfterLogoutEmbed = (
    action: PayloadAction
  ): action is PayloadAction => {
    return (
      redirectAfterLogout.match(action) &&
      isEmbed()
    );
  };

export const isLogoutEmbed = (
  action: PayloadAction
): action is PayloadAction => {
  return logout.match(action) && isEmbed();
};

export const isLoginEmbed = (
  action: PayloadAction
): action is PayloadAction => {
  return login.match(action) && isEmbed();
};

export const isRehydrateStateEmbed = (
  action: PayloadAction
): action is PayloadAction => {
  return rehydrateState.match(action) && isEmbed();
};

// Direct
export const isSelectServiceTypeDirect = (
    action: PayloadAction
  ): action is PayloadAction => {
    return (
      action.type === "SELECT_SERVICE" &&
      isDirect()
    );
  };
  
  export const isRedirectAfterLoginDirect = (
    action: PayloadAction
  ): action is PayloadAction => {
    return (
      redirectAfterLogin.match(action) &&
      isDirect()
    );
  };
  
  export const isAuthenticateSuccessDirect = (
    action: PayloadAction
  ): action is PayloadAction => {
    return (
      authenticateSuccess.match(action) &&
      isDirect()
    );
  };

  export const isRedirectAfterLogoutDirect= (
    action: PayloadAction
  ): action is PayloadAction => {
    return (
      redirectAfterLogout.match(action) &&
      isDirect()
    );
  };
  
  export const isLogoutDirect = (
    action: PayloadAction
  ): action is PayloadAction => {
    return logout.match(action) && isDirect();
  };

  export const isLoginDirect = (
    action: PayloadAction
  ): action is PayloadAction => {
    return login.match(action) && isDirect();
  };

  export const isRehydrateStateDirect = (
    action: PayloadAction
  ): action is PayloadAction => {
    return rehydrateState.match(action) && isDirect();
  };