import React from 'react';
import BEMHelper from 'react-bem-helper';

import './Stepper.css';

const c = new BEMHelper({
    name: 'Stepper',
});

interface Step {
    label: React.ReactNode;
    onClick?: (e: React.MouseEvent) => void;
    disabled?: boolean;
}

interface Props {
    steps?: Step[];
    activeStep?: number;
    // Before primary color settings are loaded, show grey color
    noColor?: boolean;
}

const Stepper: React.FC<Props> = props => {
    const { steps = [], activeStep = 0, noColor } = props;

    return (
        <div {...c()}>
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    <div
                        {...c('step', {
                            active: activeStep === index,
                            done: activeStep > index,
                             disabled: !!step.disabled
                        })}
                        onClick={activeStep > index ? step.onClick : undefined}
                    >
                        <div
                            {...c('stepCircle')}
                            style={{
                                backgroundColor: index === 0 && noColor ? '#b8c2cc' : undefined,
                            }}
                        >
                            {index + 1}
                        </div>
                        <div {...c('stepLabel')}>{step.label}</div>
                    </div>
                    {index !== steps.length - 1 && (
                        <div {...c('connector')}>
                            <div {...c('connectorFill', { done: activeStep > index })}></div>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default Stepper;
