import React from "react";
import {
  parseISO,
  isSameDay,
  format,
} from "date-fns";
import { BookingType, CompanyType } from "@/types";
import {
  Card,
  Button,
} from "reactstrap";
import { Trans } from "@lingui/macro";
import BEMHelper from "react-bem-helper";
import "./Booking.css";
import { isBefore } from "@/misc/datetime";
import { connect } from "react-redux";


interface BookingProps {
  booking: BookingType
  company: CompanyType
  onPay?: () => void
  onCancel?: () => void
}

const BookingStatusIds = {
  BOOKED: 1,
  UNBOOKED: 2,
  RESERVED: 3,
  CANCELED: 4,
  AWAITING_PAYMENT: 5,
  AWAITING_PAYMENT_NO_TIME_LIMIT: 6,
  BOOKED_AND_PAYED: 7,
  AWAITING_PAYMENT_REQUEST: 8,
  AWAITING_MONEY_TRANSFER: 9,
};

const c = new BEMHelper("BookingCard");

const BookingCard = ({ booking, company, onPay, onCancel }: BookingProps) => {
  const dateFormat = "PP";
  const timeFormat = "p";
  const fromDate = parseISO(booking.From);
  const toDate = parseISO(booking.To);
  const multiDay = !isSameDay(fromDate, toDate);
  const paymentExpirationDate = parseISO(booking.PaymentExpiration);

  const date = multiDay ? (
    <div {...c("datetime")}>
      <div {...c("time")}>{format(fromDate, timeFormat)}</div>{" "}
      <div {...c("date")}>{format(fromDate, dateFormat)}</div>
      {" - "}
      <div {...c("time")}>{format(toDate, timeFormat)}</div>{" "}
      <div {...c("date")}>{format(toDate, dateFormat)}</div>
    </div>
  ) : (
    <div {...c("datetime")}>
      <div {...c("time")}>{format(fromDate, timeFormat)}</div>
      {" - "}
      <div {...c("time")}>{format(toDate, timeFormat)}</div>{" "}
      <div {...c("date")}>{format(toDate, dateFormat)}</div>
    </div>
  );

  const canBePaid =
    booking?.Service?.IsPaymentEnabled &&
    [
      BookingStatusIds.AWAITING_PAYMENT,
      BookingStatusIds.AWAITING_PAYMENT_NO_TIME_LIMIT,
    ].indexOf(booking.StatusId) > -1 && isBefore(new Date(), new Date(paymentExpirationDate));

  return (
    <React.Fragment>
      <Card key={booking.Id} {...c()}>
        <div {...c("flex")}>
          <div {...c("left")}>
            <div
              style={{ backgroundImage: `url(${booking.Service.ImageUrl || company.LogoType})` }}
              {...c("img")}
            />
          </div>
          <div {...c("right")}>
            <div {...c("header")}>
              <div {...c("serviceName")}>{booking.Service.Name}</div>
            </div>
            <div {...c("subtitle")}>
              <span {...c("id")}>{booking.Id}</span>{" "}
            </div>
            {date}
            <div {...c("descriptionList")}>
              {onPay ? (
                <React.Fragment>
                  <div {...c("descriptionTitle")}>
                    <Trans id="paymentExpirationDate"></Trans>
                  </div>
                  <div {...c("descriptionDetails")}>
                    {format(paymentExpirationDate, "PPp")}
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </Card>
      <div {...c("container")}>
        {canBePaid && onPay ? (
          <Button className="mr-3" color="primary" onClick={onPay}>
            <Trans id="pay"></Trans>
          </Button>
        ) : null}
        {onCancel ? (
          <Button
            color="light"
            onClick={() => {
              onCancel();
            }}
          >
            <Trans id="booking.goToCompany" />
          </Button>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default connect()(BookingCard);
