import ServicesAPI from "@/api/services";
import { definitions } from "@/apitypes";
import apiService from "@/services/bmApi";
import { TypedListener } from "@/store";
import { Article, Currency } from "@/types";
import { createAsyncThunk, createListenerMiddleware, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";

export const fetchArticles = createAsyncThunk('articles/fetch', async ( { CompanyId } : Article) => {
        const articles = await ServicesAPI.getArticles({ CompanyId });
        const currencies = await ServicesAPI.getCurrencies();
        const currency = currencies.find(currency => currency.Id === articles[0].CurrencyId);

        return {
            articles: articles,
            currency: currency
        }
});

export const articlesListener = createListenerMiddleware();

const startArticlesListening = articlesListener.startListening as TypedListener;

interface InitialState {
    entity: Article[];
    currency?: Currency;
}

export const initialState: InitialState = {
    entity: [],
    currency: undefined
};

const articlesSlice = createSlice({
    name: 'articles',
    initialState,
    reducers: {
        fetchArticlesSuccess: (state, { payload }: PayloadAction<Article>) => {
            // state.entity = payload.article;
            // state.currency = payload.currency;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchArticles.fulfilled, (state, action) => {
            state.entity = action.payload.articles;
            state.currency = action.payload.currency;
        })
    },
});

startArticlesListening({
    matcher: isAnyOf(
        apiService.endpoints.getArticles.matchFulfilled
    ),
    effect: async (action, api) => {
        
    }
});

export default articlesSlice;