import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import BEMHelper from 'react-bem-helper';
import Panel from '@/components/elements/Panel';
import { ServiceType, ResourceTypeType, BookingStateType } from '@/types';
import { selectResource } from '@/actions';

import ResourceMini from './ResourceMini';

import './Resources.css';

const c = new BEMHelper('Resources');

interface Props {
    service: ServiceType | null;
    booking: BookingStateType;
    hidePreview?: boolean;
    dispatch: Dispatch<any>;
}

export class Resources extends React.Component<Props> {
    render() {
        const { service } = this.props;

        if (!service) return false;
        if (!service.ResourceTypes) return false;

        const selectableResourceTypes = service.ResourceTypes.filter(
            (resourceType) => resourceType.SelectableByUser
        );

        if (!selectableResourceTypes.length) return false;

        return (
            <div data-cy="resources" {...c()}>
                {selectableResourceTypes.map((resourceType, index) => (
                    <div key={index}>{this.renderResourceType(resourceType)}</div>
                ))}
            </div>
        );
    }

    renderResourceType = (resourceType: ResourceTypeType) => {
        const { service, booking } = this.props;
        const selectedResourceId = booking.resources[resourceType.Id];

        return (
          <Panel title={resourceType.Name}>
            <div {...c("list")}>
              <ResourceMini
                {...c("resource")}
                resource={null}
                active={!selectedResourceId}
                onClick={() => {
                  this.props.dispatch(
                    selectResource({
                      resourceType: resourceType,
                      resource: null,
                      service: service,
                    })
                  );
                }}
              />
              {resourceType.Resources.map((resource) => (
                <ResourceMini
                  {...c("resource")}
                  key={resource.Id}
                  resource={resource}
                  active={resource.Id === selectedResourceId}
                  onClick={() => {
                    this.props.dispatch(
                      selectResource({
                        resourceType: resourceType,
                        resource: resource,
                        service: service,
                      })
                    );
                  }}
                />
              ))}
            </div>
            <p data-cy="resource-description">
              {selectedResourceId
                ? resourceType.Resources.find(
                    (r) => r.Id === selectedResourceId
                  )?.Description
                : resourceType.Description}
            </p>
          </Panel>
        );
    };
}

export default connect()(Resources);
