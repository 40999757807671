import { definitions } from "@/apitypes";
import { User } from "@/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Customer = {
  CompanyId?: string;
  Id?: string;
  Firstname?: string;
  Lastname?: string;
  Email?: string;
  WorkerId?: string;
  Phone?: string;
};




type InitialState = {
    data?: User
    customerId: string;
};

const initialState: InitialState = {
    data: undefined,
    customerId: ''
};
const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setCustomer: (state, action: PayloadAction<{user: User, customerId: string}>) => {
            state.data = action.payload.user;
            state.customerId = action.payload.customerId;
        },
        clearCustomer: (state) => {
            state.data = undefined;
        }
    }
});

export const  { setCustomer, clearCustomer } = customerSlice.actions;
export default customerSlice;