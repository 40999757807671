import Keycloak from "keycloak-js";
import {
  authenticateSuccess,
  authenticateFail
} from "@/reducers/authenticate";
import { store } from "@/store";
import _ from "lodash";

import qs from "qs";
import { logout, rehydrateState } from "./actions";
import { BACK_URI } from "./providers/IdentityProvider";

export const REFRESH_TOKEN_INTERVAL = 55; // in seconds

const keycloakUrl =process.env.REACT_APP_KEYCLOAK_AUTH_URL as string;
const keycloakRealm = process.env.REACT_APP_KEYCLOAK_REALM as string;
const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string;

export const keycloakConfig = {
  userInfoEndpoint: `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/userinfo`,
  tokenEndpoint: `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/token`,
  loginEndpoint: `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/auth`,
  logoutEndpoint: `${keycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/logout`,
  clientId: keycloakClientId,
  realm: keycloakRealm,
  url: keycloakUrl,
};

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
});


keycloak.onAuthRefreshSuccess = () => {
  store.dispatch(authenticateSuccess());
};

keycloak.onAuthError = (err) => {
  store.dispatch(logout());
};

keycloak.onAuthRefreshError = () => {
  const err = new Error("Token refresh error;");
  store.dispatch(authenticateFail(err));
};

keycloak.onTokenExpired = () => {
  keycloak.updateToken(REFRESH_TOKEN_INTERVAL);
};

export default keycloak;
