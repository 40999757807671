import React from 'react';

import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Media } from 'reactstrap';
import BEMHelper from 'react-bem-helper';


// Partials
import CalendarWeek from '@/components/partials/CalendarWeek';
import CalendarMonth from '@/components/partials/CalendarMonth';
import CalendarQuarter from '@/components/partials/CalendarQuarter';
import CalendarToolbar from '@/components/partials/CalendarToolbar';
import TimesList from '@/components/partials/TimesList';
import Resources from '@/components/partials/Resources';
import Times from '@/components/partials/Times';
import Service from '@/components/partials/Service';
import {
    startOfISOWeek,
    startOfMonth,
    endOfISOWeek,
    endOfMonth,
    addMonths,
} from '@/misc/datetime';
// Elements
import { Button } from 'bokamera-embedded-ui';
import Panel from '@/components/elements/Panel';

import { formatFromTo } from '@/misc/common';
import { ConfigKeys } from '@/misc/constants';
import { ServiceType, BookingStateType, Configuration, TimesStateType } from '@/types';

import './TimesService.css';
import { Trans } from '@lingui/macro';
import { isWithinInterval } from 'date-fns';
import { ApplicationState } from '@/store';

import FlexibleHoursSlider from '../partials/FlexibleHoursSlider';
import FlexibleHoursDropdown from '../partials/FlexibleHoursDropdown';

interface Props {
    dispatch: Dispatch<any>;
    service: ServiceType;
    booking: BookingStateType;
    configuration: Configuration;
    times: TimesStateType;
}

interface OwnProps {
    serviceId: string;
}

const c = new BEMHelper({
    name: 'TimesService',
});

interface State {
    service: ServiceType | null;
}
export class TimesService extends React.Component<Props, State> {
    cachedService: ServiceType | null = null;

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { service } = this.props;
        const { navigationDate, selectedDate } = this.props.configuration;

        const firstVisibleDate =
            this.props.configuration.timesLayout === ConfigKeys.TIMES_LAYOUT_QUARTER
                ? startOfISOWeek(startOfMonth(navigationDate))
                : this.props.configuration.timesLayout === ConfigKeys.TIMES_LAYOUT_MONTH
                ? startOfISOWeek(startOfMonth(navigationDate))
                : new Date();

        const lastVisibleDate =
            this.props.configuration.timesLayout === ConfigKeys.TIMES_LAYOUT_QUARTER
                ? endOfISOWeek(endOfMonth(addMonths(navigationDate, 2)))
                : this.props.configuration.timesLayout === ConfigKeys.TIMES_LAYOUT_MONTH
                ? endOfISOWeek(endOfMonth(navigationDate))
                : new Date();

        // Render times for selected date only when visible (in range)
        const isSelectedDateVisible =
            selectedDate &&
            isWithinInterval(selectedDate, {
                start: firstVisibleDate,
                end: lastVisibleDate,
            });

        if (!this.cachedService && isSelectedDateVisible) {
            const serviceClone = { ...service };
            this.cachedService = serviceClone;
            this.forceUpdate();
        } else if (!isSelectedDateVisible && this.cachedService) {
            this.cachedService = null;
            this.forceUpdate();
        }
    }

    render() {
        const { booking, configuration, service, dispatch } = this.props;
        if (!service || !booking.service) return null;

        const topOffset = configuration.topOffset || 0;
        const times = this.props.times[this.props.service.Id];

        return (
          <div>
            {configuration.selectedService && (
              <Service service={service} hideSelect />
            )}
            <Resources booking={booking} service={booking.service} />
            {service.DurationTypeId === 2 ? (
              <FlexibleHoursSlider
                service={service}
                onChange={() => {
                  dispatch({ type: "FETCH_FLEXIBLE_TIMES" });
                }}
              />
            ) : null}
            {service.DurationTypeId === 3 ? (
              <FlexibleHoursDropdown
                service={service}
                onChange={() => {
                  dispatch({ type: "FETCH_FLEXIBLE_TIMES" });
                }}
              />
            ) : null}
            <Panel {...c("calendarContainer")}>
              {configuration.timesLayout !== ConfigKeys.TIMES_LAYOUT_LIST && (
                <CalendarToolbar service={booking.service} />
              )}

              <div {...c("calendarBody", { loading: times.isLoading })}>
                {configuration.timesLayout === ConfigKeys.TIMES_LAYOUT_WEEK ||
                configuration.timesLayout ===
                  ConfigKeys.TIMES_LAYOUT_WEEKLY_OVERVIEW ? (
                  <CalendarWeek booking={booking} service={service} />
                ) : null}
                {configuration.timesLayout ===
                  ConfigKeys.TIMES_LAYOUT_MONTH && (
                  <CalendarMonth service={service} />
                )}
                {configuration.timesLayout ===
                  ConfigKeys.TIMES_LAYOUT_QUARTER && (
                  <CalendarQuarter service={service} />
                )}
                {configuration.timesLayout === ConfigKeys.TIMES_LAYOUT_LIST && (
                  <TimesList service={service} />
                )}
              </div>
            </Panel>

            <div id="times" style={{ scrollMarginTop: `${topOffset}px` }}>
              {!times.isLoading ? this.renderNextAvailableTime() : null}
              {!times.isLoading &&
              this.cachedService &&
              (configuration.timesLayout === ConfigKeys.TIMES_LAYOUT_MONTH ||
                configuration.timesLayout ===
                  ConfigKeys.TIMES_LAYOUT_QUARTER) ? (
                <Panel title={<Trans id="selectTime"></Trans>}>
                  <Times service={this.cachedService} />
                </Panel>
              ) : null}
            </div>
          </div>
        );
    }

    renderNextAvailableTime = () => {
        const times = this.props.times[this.props.service.Id];
        const nextAvailableTime: any = times.nextAvailableTime;

        if (!nextAvailableTime) return false;

        return (
            <Panel title={<Trans id="nextAvailableTime"></Trans>}>
                <Media list style={{ paddingLeft: 0 }}>
                    <Media>
                        <Media body>
                            <div>
                                {formatFromTo(nextAvailableTime.From, nextAvailableTime.To, {
                                    renderDateIfSameDay: true,
                                })}
                            </div>
                        </Media>
                        <Media right align="center">
                            <Button
                                primary
                                onClick={() => {
                                    this.props.dispatch({
                                        type: 'SELECT_NEXT_AVAILABLE_TIME',
                                        service: this.props.service,
                                        date: nextAvailableTime.From,
                                    });
                                }}
                            >
                                {<Trans id="showDay"></Trans>}
                            </Button>
                        </Media>
                    </Media>
                </Media>
            </Panel>
        );
    };
}

// export the connected class
function mapStateToProps(state: ApplicationState, props: any) {
    if (!props.serviceId) {
        console.error('No serviceId available as props');
    }

    return {
        configuration: state.configuration.data,
        services: state.services || [],
        service: state.services.data.find((service: ServiceType) => service.Id == props.serviceId),
        booking: state.booking,
        times: state.times || {},
    };
}

export default compose<React.ComponentType<OwnProps>>(connect(mapStateToProps))(TimesService);
