import React, { useState } from "react";
import { ConnectedProps } from "react-redux";
import BEMHelper from "react-bem-helper";

// Elements
import { Button } from "bokamera-embedded-ui";
import ShowMoreText from "@/components/elements/ShowMoreText";

import { Article as ArticleType, Currency, ServiceType } from "@/types";

import "./Service.css";
import { Trans } from "@lingui/macro";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { selectArticle } from "@/reducers/article";

interface OwnProps {
  article: ArticleType;
  hideSelect?: boolean;
  append?: React.FC<ServiceType>;
  compact?: boolean;
}

const c = new BEMHelper({
  name: "Service",
});

const Article: React.FC<OwnProps> = ({ article, hideSelect = false }) => {
  const [showDetails, setShowDetails] = useState(false);
  const configuration = useAppSelector((s) => s.configuration.data);
  const company = useAppSelector((s) => s.company.data);
  const dispatch = useAppDispatch();

  return (
    <>
      <div {...c()} data-testid={`article-id-${article.Id}`}>
        {!configuration.hideServiceImage && (
          <div {...c("imageContainer")}>
            {article.ImageUrl || company?.LogoType ? (
              <img
                // @ts-ignore
                src={article.ImageUrl || company?.LogoType}
                {...c("image")}
                alt={article.Name}
              />
            ) : (
              <div
                {...c("imagePlaceholder")}
                style={{ backgroundColor: "rgb(240, 240, 235)" }}
              />
            )}
          </div>
        )}
        <div {...c("main")}>
          <div {...c("content")}>
            <h4 {...c("title")}>{article.Name}</h4>
            {article.Description ? (
              <p>
                <ShowMoreText>{article.Description}</ShowMoreText>
              </p>
            ) : null}
          </div>
          <div {...c("divider")} />
          <div {...c("actionBox")}>
            <div {...c('priceContainer')} data-testid="service-prices">
              <div {...c('price')}>
                {/* @ts-ignore */}
                <span {...c('priceValue')}>{article.Price}{article.PriceSign}</span>
              </div>
            </div>
            {!hideSelect && (
              <Button
                {...c("select")}
                primary
                outline
                onClick={() => dispatch(selectArticle({ article }))}
              >
                <Trans id="Article.buy"></Trans>
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Article;
