import React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import BEMHelper from 'react-bem-helper';
import groupBy from 'lodash/groupBy';

import Spinner from '@/components/elements/Spinner';
import { Button } from 'bokamera-embedded-ui';

import { format } from '@/misc/datetime';
import { BookingStateType, CompanyType, TimeForServiceStateType, ServiceType } from '@/types';

import './TimesList.css';
import { Trans } from '@lingui/macro';

const classes = new BEMHelper({
    name: 'TimesList',
});

interface Props {
    times: TimeForServiceStateType;
    booking: BookingStateType;
    company: CompanyType;
    service: ServiceType;
    dispatch: Dispatch<any>;
}
interface OwnProps {
    service: ServiceType;
}

export function TimesList(props: Props) {
    const { times, booking, company } = props;
    const { service } = booking;

    if (times.isLoading)
        return (
            <Spinner>
                <Trans id="loadingTimes"></Trans>
            </Spinner>
        );

    let filteredTimes = times.times;
    if (!company?.BookingSettings?.EnableShowBookedTimes) {
        filteredTimes = filteredTimes.filter((time) => time.Free > 0);
    }

    const groupedTimes = groupBy(filteredTimes, (time) => format(time.From, 'yyyy-MM'));

    return (
        <div {...classes()}>
            {Object.keys(groupedTimes).map((key) => {
                const timesForMonth = groupedTimes[key];

                return (
                    <>
                        <div {...classes('month')}>
                            {timesForMonth.length > 0 &&
                                format(timesForMonth[0].From, 'MMMM, yyyy')}
                        </div>

                        {timesForMonth.map((time: any, timeIndex) => {
                            const addToQueue = !!(time.Free === 0 && props.service.EnableBookingQueue);
                            const fullyBooked = time.Free === 0 && !addToQueue;
                            
                            return (
                                <div key={timeIndex} {...classes('row')}>
                                    <div {...classes('param')}>
                                        <span {...classes('label')}>
                                            <Trans id="from"></Trans>
                                            {': '}
                                        </span>
                                        <span {...classes('date')}>
                                            {format(time.From, 'dd MMM, E')}{' '}
                                        </span>
                                        <span {...classes('time')}>{format(time.From, 'p')}</span>
                                    </div>
                                    <div {...classes('param')}>
                                        <span {...classes('label')}>
                                            <Trans id="endsAt"></Trans>
                                            {': '}
                                        </span>
                                        <span {...classes('date')}>
                                            {format(time.To, 'dd MMM, E')}{' '}
                                        </span>
                                        <span {...classes('time')}>{format(time.To, 'p')}</span>
                                    </div>
                                    {service &&
                                        (service.GroupBooking.Active ||
                                            service.MultipleResource.Active) &&
                                        time.Free > 0 && (
                                            <div {...classes('param')}>
                                                <span {...classes('label')} />
                                                {company?.BookingSettings?.ShowFreeTimesLeft && (
                                                    <>
                                                        {time.Free}{' '}
                                                        {time.Free === 1
                                                            ? times.single
                                                            : times.multiple}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    <div {...classes('param')}>
                                        <span {...classes('label')} />
                                        <Button
                                                {...classes('flex')}
                                                disabled={fullyBooked}
                                                onClick={() => {
                                                    if (!fullyBooked) {
                                                        props.dispatch({
                                                            type: 'SELECT_TIME',
                                                            service,
                                                            time,
                                                        });
                                                    }
                                                }}
                                            >
                                                {time.Free > 0
                                                    ? <Trans id="select"></Trans>
                                                    : null}

                                                {(props.service.GroupBooking.Active ||
                                                props.service.MultipleResource.Active) &&
                                                time.Free === 0 ? (
                                                    <span className="text-warning">
                                                        <Trans id="fullyBooked"></Trans>
                                                    </span>
                                                ) : null}

                                                {addToQueue ? (
                                                    <span className="text-warning">
                                                        <Trans id="addToQueue" />
                                                    </span>
                                                ) : null}
                                            </Button>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                );
            })}
        </div>
    );
}

const mapStateToProps = ({ times, booking, company }: any, { service }: any) => ({
    times: times[service.Id],
    booking,
    company: company.data,
});

export default compose<React.ComponentType<OwnProps>>(connect(mapStateToProps))(TimesList);
