import React, { useEffect, useState } from "react";
import { Row, Col, Alert } from "reactstrap";

import { MdPrint } from "react-icons/md";
import BEMHelper from "react-bem-helper";

// Elements
import { Button } from "bokamera-embedded-ui";
import Panel from "@/components/elements/Panel";
import Spinner from "@/components/elements/Spinner";
import { createArticlePaymentUrls, scrollToElement, tryParseJSONObject } from "@/misc/common";

import "./Completed.css";
import { Trans } from "@lingui/macro";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "@/hooks";
import Company from "../partials/Company";
import ArticleSummary from "../partials/ArticleSummary";
import ErrorAlert from "../partials/ErrorAlert";
import { persistor } from "@/store";
import qs from "qs";

const c = new BEMHelper({ name: "Completed" });

export const Completed: React.FC = (props) => {
  const history = useHistory();
  const {
    checkout,
    selected: selectedArticle,
    entity: completedArticle,
  } = useAppSelector((s) => s.article);
  const company = useAppSelector((s) => s.company);
  const configuration = useAppSelector((s) => s.configuration.data);
  const dispatch = useAppDispatch();
  const [completePayment, setCompletePayment] = useState(false);
  const { customerEmail } = useAppSelector((s) => ({
    customerEmail:
      s.user?.data?.CustomerProfile?.Email ||
      s.form?.customerForm?.values?.Customer?.Email,
  }));

  const payWithPaysonV1 =
    company.data?.BookingSettings?.PaymentProviderId === 1;
  const payWithPaysonV2 =
    company.data?.BookingSettings?.PaymentProviderId === 2;
  const payWithBillmate =
    company.data?.BookingSettings?.PaymentProviderId === 3;
  const paymentIsEmbedded = payWithPaysonV2 || payWithBillmate;
  const paymentEnabled = !!company.data?.BookingSettings?.PaymentEnabled;
  // const needToPay = paymentEnabled && statusRequiresPayment;

  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

  useEffect(() => {
    window.onbeforeunload = () => {
      persistor.pause();
      dispatch({ type: 'PURGE_PERSISTED' });
    }
    window.onmessage = (ev) => {
      try {
        const data = tryParseJSONObject(ev.data);
        if (
          data &&
          data.event === "purchase_initialized" &&
          checkout?.entity?.Url
        ) {
          const billmateContainer: HTMLIFrameElement | null =
            document.querySelector(`[src^="${checkout.entity.Url}"]`);
            billmateContainer?.contentWindow?.postMessage('purchase_complete', '*');
        }
      } catch (error) {
        // intentionally left blank
      }
    };

    if (!!checkout.entity?.Snippet && paymentIsEmbedded && !configuration.paymentButton) {
      if (!document.getElementById("paysonContainer") && !iOSSafari) {
        const div = document.createElement("div");
        div.innerHTML = checkout.entity.Snippet;
        window?.bookingAppContainer
        ?.getRootNode()
          // @ts-ignore
          ?.host?.parentNode?.prepend(div);
      } else if (company?.data && iOSSafari) {
        setCompletePayment(true);
        
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
            scrollToElement({ company: company.data });
        }
      }

      const intervalId = setInterval(() => {
        const paysonContainer =
          window?.bookingAppContainer?.querySelector("#paysonContainer") ||
          document.getElementById("paysonContainer");
        if (paysonContainer && company.data) {
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
              navigator.userAgent
            )
          ) {
            scrollToElement({ company: company.data });
          }
          const scriptParentNode: Node | null = paysonContainer.parentNode;
          if (!!scriptParentNode) {
            // How to rewrite it in correct way?
            const scriptNode =
            // @ts-ignore
              scriptParentNode.getElementsByTagName("script")[0];
            const scriptNodeWithContent = document.createElement("script");
            scriptNodeWithContent.src = scriptNode.src;
            document.head.appendChild(scriptNodeWithContent);
            clearInterval(intervalId);
          }
        }
      }, 1000);
    } else if (company.data && paymentEnabled && (iOSSafari || configuration.paymentButton)) {
        setCompletePayment(true);
        
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
            scrollToElement({ company: company.data });
        }
    }
  }, [checkout.entity, payWithPaysonV2, payWithBillmate, paymentEnabled, configuration.paymentButton]);

  if (!selectedArticle || !company.data) return null;

  if (checkout.error) {
    return (
      <Panel>
        {checkout?.error ? <ErrorAlert error={checkout.error} /> : null}
        <Row>
          <Col xs={12}>
            <Trans id="completed.error" />
          </Col>
        </Row>
      </Panel>
    );
  }

  return (
    <Panel>
      <div id="section-to-print">
        <h4
          className="text-left text-danger"
          style={{ marginBottom: 20, padding: "0.3rem" }}
        >
          <Trans id="youNeedToPayTheArticle"></Trans>
        </h4>

        {checkout.entity && payWithPaysonV1 && !iOSSafari && !configuration.paymentButton ? (
          <Alert color="warning" className="clearfix">
            <div>
              <p>
                <Trans id="instructionsHasBeenSentToEmail"></Trans>
              </p>
            </div>
            <a
              className="float-right hidden-print"
              href={checkout.entity?.Snippet}
              target="_blank"
            >
              <Button>
                <Trans id="payNow"></Trans>
              </Button>
            </a>
          </Alert>
        ) : null}
        {company?.data.BookingSettings?.BookingReceiptMessage && (
          <p style={{ marginBottom: 20, padding: "0.3rem" }}>
            {company.data.BookingSettings.BookingReceiptMessage}
          </p>
        )}
        {checkout.isLoading ? (
          <div className="hidden-print">
            <div>
              <Spinner {...c("paymentLoading")} />
            </div>
            <Alert color="warning" className="clearfix">
              <Trans id="yourArticleMustBePaid"></Trans>
            </Alert>
          </div>
        ) : null}
        {checkout.entity?.Snippet && payWithPaysonV2 && !iOSSafari && !configuration.paymentButton ? (
          <Row className="hidden-print" style={{ marginBottom: "1rem" }}>
            <Col xs={12}>
              <div
                dangerouslySetInnerHTML={{ __html: checkout.entity.Snippet }}
              />
            </Col>
          </Row>
        ) : null}
        {checkout.entity?.Url && payWithBillmate && !iOSSafari && !configuration.paymentButton ? (
          <iframe
            src={`${checkout.entity.Url}?activateJsEvents=1`}
            data-testid="billmateContainer"
            sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-scripts allow-same-origin allow-forms allow-popups allow-popups-to-escape-sandbox"
            width="100%"
            height="900px"
            frameBorder="0"
          ></iframe>
        ) : null}
        {completePayment ? (
            <div {...c('userInitiatedPayment')} >
                <Button
                    primary
                    onClick={() => {
                        try {
                            if(window?.top?.location) {
                                const bokameraSiteUrl = process.env.REACT_APP_BOKAMERA_SITE_URL;
                                const { confirmationUrl } = createArticlePaymentUrls(configuration, selectedArticle);

                                const searchString = qs.stringify({
                                  bookingId: completedArticle?.Id,
                                  companyId: company?.data?.Id,
                                  email: customerEmail,
                                  paymentProviderId: company.data?.BookingSettings?.PaymentProviderId,
                                  articles: [{
                                    ArticleId: selectedArticle.Id,
                                    ArticleTypeId: selectedArticle.ArticleTypeId,
                                    Quantity: 1
                                  }]
                                });

                                let paymentUrl = `${bokameraSiteUrl}/payment?${searchString}`;

                                if(confirmationUrl) {
                                    paymentUrl = `${paymentUrl}&confirmationUrl=${encodeURIComponent(confirmationUrl.href)}`
                                }

                                window.top.location = paymentUrl;
                            }
                        } catch (error) {
                            
                        }
                    }}
                >
                    <Trans id="completePayment" />
                </Button>
            </div>
        ) : null}
        {completedArticle ? (
          <div>
            <Row {...c("body")}>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Company />
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <ArticleSummary article={selectedArticle} />
              </Col>
            </Row>
            <Row className="hidden-print">
              <Col xs={12}>
                <Button
                  className="button float-right"
                  onClick={() => {
                    window.print();
                  }}
                >
                  <MdPrint /> <Trans id="print"></Trans>
                </Button>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    </Panel>
  );
};

export default Completed;
