import { Middleware } from 'redux';
import { BookingType } from '@/types';

declare global {
    interface Window {
        ga: any;
    }
}

const sendEvent = (eventFields: any) => {
    window.ga('send', 'event', eventFields);
};

const googleAnalyticsMiddleware: Middleware = (store) => (next) => (action) => {
    if (window.ga) {
        try {
            const state = store.getState();

            const companySitePath = state.company.data.SitePath;
            const companyURL = `https://${companySitePath}.bokamera.se`;

            let serviceName = null;
            if (state.booking && state.booking.service) {
                serviceName = state.booking.service.Name;
            }

            switch (action.type) {
                case 'SELECT_SERVICE':
                    sendEvent({
                        eventCategory: 'Service',
                        eventAction: 'select',
                        eventLabel: `companyURL: ${companyURL}, service: ${action.service.Name}`,
                    });
                    break;
                case 'SELECT_RESOURCE':
                    sendEvent({
                        eventCategory: 'Resource',
                        eventAction: 'select',
                        eventLabel: `companyURL: ${companyURL}, service: ${serviceName}, resource: ${action.resource.Name}`,
                    });
                    break;
                case 'SELECT_TIME':
                    sendEvent({
                        eventCategory: 'Time',
                        eventAction: 'select',
                        eventLabel: `companyURL: ${companyURL}, service: ${serviceName}`,
                    });
                    break;
                case 'SAVE_BOOKING':
                    const booking: BookingType = action.booking;

                    let price;
                    let currency;
                    if (booking.Quantities && booking.Quantities.length > 0) {
                        const value = booking.Quantities.reduce(
                            (acc, quantity) =>
                                acc + (quantity.Price || 0) * (quantity.Quantity || 0),
                            0
                        );
                        currency = booking.Quantities[0].CurrencyId;
                        price = `${value}${currency}`;
                    }

                    sendEvent({
                        eventCategory: 'Booking',
                        eventAction: 'success',
                        eventLabel: `companyURL: ${companyURL}, service: ${serviceName}, price: ${
                            price || '0'
                        }`,
                    });

                    break;
                case 'FAIL_BOOKING':
                    sendEvent({
                        eventCategory: 'Booking',
                        eventAction: 'failure',
                        eventLabel: `companyURL: ${companyURL}, service: ${serviceName}`,
                    });

                    break;
                default:
                    break;
            }
        } catch (err) {}
    }
    return next(action);
};

export default googleAnalyticsMiddleware;
