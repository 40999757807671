import React from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import BEMHelper from 'react-bem-helper';
import { UncontrolledTooltip } from 'reactstrap';

import { format, startOfWeek, toDate, addDays, isSameDay } from '@/misc/datetime';

import './CalendarWeek.css';
import {
    Configuration,
    TimesStateType,
    ServiceType,
    TimeType,
    CompanyStateType,
    BookingStateType,
} from '@/types';
import { MdInfoOutline } from 'react-icons/md';
import { Trans } from '@lingui/macro';
import { addWeeks, isBefore } from 'date-fns';
import { ApplicationState } from '@/store';

const c = new BEMHelper({
    name: 'CalendarWeek',
});

interface Props {
    configuration: Configuration;
    times: TimesStateType;
    services: any;
    company: CompanyStateType;
    dispatch: Dispatch<any>;
}

interface OwnProps {
    booking?: BookingStateType;
}

interface TimeWithService {
    isLoading: boolean;
    time: TimeType;
    service: ServiceType;
    single: string;
    multiple: string;
    color?: string;
}

const tooltipContainer = document.getElementById('bokamera-embedded');

const servicesColors = [
    '#ECF7E5',
    '#C1D6F9',
    '#ECF7ED',
    '#DAF4D5',
    '#F8DEF8',
    '#F1E8E4',
    '#DFF9EA',
    '#D7D9ED',
    '#EAE2CC',
    '#C4F0F1',
    '#F2FCCB',
    '#FBD9D3',
    '#EFDEE6',
    '#EBFBDE',
    '#EBDCFA',
    '#F6D5CD',
    '#F0ECC8',
    '#F4E3DF',
];

export class CalendarWeekOverview extends React.Component<Props> {
    render() {
        const { configuration, times, services } = this.props;
        const serviceIdsToShow = configuration.serviceIdsToShow;

        const headerCells = [];
        const cells = [];
        let noTimes = true;
        let nextAvailableTime: TimeType | undefined;

        for (let i = 0; i < 7; i++) {
            const date = addDays(startOfWeek(toDate(configuration.navigationDate)), i);

            headerCells.push(<HeaderCell key={i} date={date} />);
            let timeWithServices = Object.keys(times)

                .reduce((acc, serviceId, index) => {
                    if (!nextAvailableTime) {
                        nextAvailableTime = times[serviceId].nextAvailableTime;
                    }
                    times[serviceId].times.forEach((time: TimeType) => {
                        const timeWithService = {
                            ...times[serviceId],
                            time,
                            service: services.data.filter(
                                (service: any) => Number(service.Id) === Number(serviceId)
                            )[0],
                            color: servicesColors[index],
                        };

                        if (
                            serviceIdsToShow &&
                            Array.isArray(serviceIdsToShow) &&
                            serviceIdsToShow.length > 0 &&
                            serviceIdsToShow?.indexOf(Number(serviceId)) !== -1
                        ) {
                            acc.push(timeWithService);
                        } else if (!serviceIdsToShow) {
                            acc.push(timeWithService);
                        }
                    });
                    return acc;
                }, [] as Array<TimeWithService>)
                .sort((a, b) => {
                    if (isBefore(new Date(a.time.From), new Date(b.time.From))) {
                        return -1;
                    } else if (isBefore(new Date(b.time.From), new Date(a.time.From))) {
                        return 1;
                    }

                    return 0;
                });

            noTimes = timeWithServices.every(
                (timeWithService) =>
                    !isSameDay(timeWithService.time?.From, date) &&
                    !isSameDay(timeWithService.time?.To, date)
            );

            const isLoading = timeWithServices.some((t) => t.isLoading);

            cells.push(
                <div
                    {...c('column', {
                        noTimes,
                        isLoading,
                    })}
                    key={i}
                    style={{ width: `${100 / 7}%` }}
                >
                    {timeWithServices.length > 0 &&
                        timeWithServices.map((timeWithService) =>
                            this.renderTime(timeWithService, date)
                        )}

                    {noTimes ? <Trans id="noTimes"></Trans> : null}
                </div>
            );
        }

        const isLoading =  Object.keys(times).some((key) => times[key].isLoading);

        return (
            <div {...c('weekOverview')}>
                <div {...c('head')}>{headerCells}</div>
                <div {...c('body')}>{cells}</div>
                {noTimes && nextAvailableTime?.From && !isLoading ? (
                    <div {...c('nextBtnWrapper')}>
                        <button
                            {...c('nextAvailableTimeBtn')}
                            onClick={() => {
                                this.props.dispatch({
                                    type: 'CHANGE_NAVIGATION_DATE',
                                    // @ts-ignore
                                    date: nextAvailableTime.From,
                                });
                                this.props.dispatch({
                                    type: 'FETCH_SERVICES_TIMES',
                                });
                            }}
                        >
                            <Trans id="nextAvailableTime" />
                        </button>
                    </div>
                ) : null}
            </div>
        );
    }

    renderTime = ({ time, service, single, multiple, color }: TimeWithService, date: Date) => {
        const { company, configuration } = this.props;
        const { bookedTimeSlotText } = configuration;

        const addToQueue = !!(time.Free === 0 && service.EnableBookingQueue);
        const fullyBooked = time.Free === 0 && !addToQueue ;

        const id = `timeslot-${service.Id}-${format(time.From, 'yyyy-MM-dd-HH-mm')}`;
        const showTooltip =
            fullyBooked && time.ExceptionTexts?.length > 0 && !!time.ExceptionTexts[0].ReasonPublic;

        if (!isSameDay(toDate(time.From), date)) {
            return null;
        }

        return (
            <React.Fragment key={`${time.From}-${time.To}-${service.Id}`}>
                <button
                    style={{ backgroundColor: color }}
                    data-testid={`button-${id}`}
                    id={id}
                    {...c('timeSlot timeslot', { fullyBooked, addToQueue })}
                    onClick={() => {
                        if (!fullyBooked) {
                            this.props.dispatch({
                                type: 'SELECT_TIME',
                                service,
                                time: time,
                            });
                        }
                    }}
                >
                    <div {...c('timeAndName')}>
                        {(time.Free > 0 || !bookedTimeSlotText) && (
                            <>
                                <span {...c('time')}>
                                    {format(time.From, 'HH:mm')}-{format(time.To, 'HH:mm')}
                                </span>
                                {showTooltip && (
                                    <>
                                        {' '}
                                        <MdInfoOutline />
                                    </>
                                )}
                            </>
                        )}
                        <>
                            <div {...c('serviceName')}>
                                {/* @ts-ignore */}
                                {service.Name}
                            </div>
                        </>
                    </div>
                    {time.Free > 0 && company && company?.BookingSettings?.ShowFreeTimesLeft && (
                        <div {...c('freeTimesLeft')}>
                            {time.Free} {time.Free === 1 ? single : multiple}
                        </div>
                    )}
                    {fullyBooked && bookedTimeSlotText}
                    {fullyBooked && !bookedTimeSlotText && (
                        <div {...c('fullyBooked')}>
                            <Trans id="fullyBooked"></Trans>
                        </div>
                    )}
                    {addToQueue ? (
                        <div {...c("freeTimesLeft")}>
                            <Trans id="addToQueue" />
                        </div>
                    ) : null}
                </button>
                {showTooltip && (
                    <UncontrolledTooltip
                        target={id}
                        container={tooltipContainer ? tooltipContainer : undefined}
                    >
                        {time.ExceptionTexts[0].ReasonPublic}
                    </UncontrolledTooltip>
                )}
            </React.Fragment>
        );
    };
}

function HeaderCell({ date }: { date: Date }) {
    return (
        <div
            {...c('dayHeader', {
                active: isSameDay(new Date(), date),
            })}
        >
            <div {...c('day')}>{format(date, 'd')}</div>
            <div {...c('month')}>{format(date, 'MMM')}</div>
            <div {...c('dayOfWeek')}>{format(date, 'E')}</div>
        </div>
    );
}

const mapStateToProps = ({ configuration, company, services, times, messages }: ApplicationState) => ({
    configuration: configuration.data,
    company: company.data,
    services,
    times,
    messages,
});

export default compose(connect(mapStateToProps))(
    CalendarWeekOverview
) as React.ComponentType<OwnProps>;
