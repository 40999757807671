import React from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import BEMHelper from 'react-bem-helper';

import {
    addDays,
    isSameDay,
    startOfISOWeek,
    endOfISOWeek,
    eachDayOfInterval,
    getMonth,
} from '@/misc/datetime';

import './Calendar.css';

import { ServicesStateType, TimesStateType, Configuration, TimeType, ServiceType } from '@/types';
import {
    format,
    endOfMonth,
    startOfMonth,
    isBefore,
    getISOWeek,
    isToday,
    startOfDay,
} from '@/misc/datetime';
import { Trans } from '@lingui/macro';
import withAutoScroll from '@/hoc/withAutoScroll';
import { ApplicationState } from '@/store';

interface Props {
    configuration: Configuration;
    services: ServicesStateType;
    service: ServiceType;
    times: TimesStateType;
    dispatch: Dispatch<any>;
    date: Date;
}

interface OwnProps {
    service?: ServiceType;
    date: Date;
}

const c = new BEMHelper({
    name: 'Calendar',
});

export class CalendarMonth extends React.Component<Props> {
    render() {
        return (
            <div {...c()}>
                {this.renderCalendarTimesHeader()}
                {this.renderCalendarTimesBody()}
            </div>
        );
    }

    renderCalendarTimesHeader = () => {
        const now = new Date();

        return (
            <div {...c('header')}>
                <div {...c('headerCell')} style={{ width: `${100 / 8}%` }}>
                    <Trans id="week"></Trans>
                </div>
                {eachDayOfInterval({ start: startOfISOWeek(now), end: endOfISOWeek(now) }).map(
                    (day, index) => (
                        <div {...c('headerCell')} key={index} style={{ width: `${100 / 8}%` }}>
                            {format(day, 'E')}
                        </div>
                    )
                )}
            </div>
        );
    };

    renderCalendarTimesBody = () => {
        const { date } = this.props;

        const monthStart = startOfMonth(date);
        const monthEnd = endOfMonth(date);

        const startDate = startOfISOWeek(monthStart);
        const endDate = endOfISOWeek(monthEnd);

        const rows = [];

        let days = [];
        let day = startDate;

        while (day <= endDate) {
            const weekNumber = getISOWeek(day);

            for (let i = 0; i < 7; i++) {
                days.push(
                    <div {...c('dayCol')} key={i}>
                        {this.renderCalendarTimesWeekDayWithOffset(day)}
                    </div>
                );

                day = addDays(day, 1);
            }

            rows.push(
                <div {...c('weekRow')} key={weekNumber}>
                    <div {...c('weekNumberCol')}>v.{weekNumber}</div>
                    {days}
                </div>
            );

            days = [];
        }

        return <div {...c('body')}>{rows}</div>;
    };

    renderCalendarTimesWeekDayWithOffset = (day: Date) => {
        const { configuration, times, service, services, date } = this.props;

        const selected = configuration.selectedDate
            ? isSameDay(day, configuration.selectedDate)
            : false;

        let allTimes;
        if (service) {
            allTimes = times[service.Id].times;
        } else {
            allTimes = services.data.reduce(
                (acc: Array<TimeType>, service: ServiceType) => [
                    ...acc,
                    ...times[service.Id].times,
                ],
                []
            );
        }
        const dayTimes = allTimes.filter((time: TimeType) => isSameDay(time.From, day));

        const availableDayTimes = dayTimes.filter((time: TimeType) => time.Free > 0);

        const isPast = isBefore(startOfDay(day), startOfDay(new Date()));
        const anotherMonth = getMonth(day) !== getMonth(date);
        const noTimes = dayTimes.length === 0;
        const booked = dayTimes.length > 0 && availableDayTimes.length === 0;
        const hasFreeTimes = availableDayTimes.length > 0;
        const today = isToday(day);

        return (
            <button
                {...c('day', {
                    selected,
                    anotherMonth,
                    hasFreeTimes: hasFreeTimes && !anotherMonth,
                    noTimes: noTimes && !anotherMonth,
                    booked: booked && !anotherMonth,
                    today,
                })}
                onClick={() => {
                    this.props.dispatch({
                        type: 'SELECT_TIMES_DAY',
                        day: day,
                    });
                }}
                disabled={anotherMonth || isPast || noTimes}
            >
                {format(day, 'd')}
            </button>
        );
    };
}

const mapStateToProps = ({ configuration, booking, services, times }: ApplicationState) => ({
    configuration: configuration.data,
    booking,
    services,
    times,
});

export default compose(
    connect(mapStateToProps),
    withAutoScroll
)(CalendarMonth) as React.ComponentType<OwnProps>;
