import { createReducer } from 'typesafe-actions';
import { ServiceType } from '@/types';

const initialState = {
    isLoading: false,
    hasError: false,
    data: [] as ServiceType[],
};

const reducer = createReducer(initialState, {
    FETCH_SERVICES_REQUEST: (state, action) => ({ ...state, isLoading: true, data: [] }),
    FETCH_SERVICES_SUCCESS: (state, action) =>
        Object.assign({}, state, {
            isLoading: false,
            hasError: false,
            error: undefined,
            data: action.services,
        }),
    FETCH_SERVICES_FAILURE: (state, action) =>
        Object.assign({}, state, {
            isLoading: false,
            hasError: true,
            error: action.error,
            data: [],
        }),
    UPDATE_SERVICE_WITH_RESOURCE: (state, action) =>
        Object.assign({}, state, {
            isLoading: false,
            hasError: false,
            error: undefined,
            data: state.data.map(service => {
                if(service.Id === action.service.Id) {
                    return action.service;
                }
                return service;
            }),
        }),
    CLEAR_SERVICES: (state, action) =>
        Object.assign({}, state, {
            isLoading: false,
            data: [],
        }),
});

export default reducer;
