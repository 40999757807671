import React from "react";
import { Alert } from "reactstrap";

type Props = {
  error: { [key in string]: string } | string;
};

const ErrorAlert = ({ error }: Props) => {
  return (
    <Alert color="danger">
      {typeof error !== "string" ? (
        <ul>
          {Object.keys(error).map((key) => (
            <li key={key}>{error[key]}</li>
          ))}
        </ul>
      ) : null}
    </Alert>
  );
};

export default ErrorAlert;
