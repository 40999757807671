
import { connect, ConnectedProps } from 'react-redux';
import React, { useState, FC, useCallback, useRef, useEffect } from 'react';
import { Trans } from '@lingui/macro';
import AgreementDialog from '@/components/partials/AgreementDialog';
import { ApplicationState } from '@/store';
import { scrollToElement } from '@/misc/common';

interface Props extends ReduxProps {}

export const Agreement: FC<Props> = ({ company }) => {
    const [modalOpen, setOpen] = useState(false);
    const elRef = useRef(null);

    const onModalToggle = useCallback(() => {
        const open = !modalOpen;
        setOpen(open);

        // @ts-ignore
        const rootNode = elRef.current.getRootNode();

        if (open) {
            // console.log(rootNode)
            // @ts-ignore
            rootNode.querySelector('#bokamera-embedded').classList.add('modal-open');
        } else {
            // @ts-ignore
            rootNode.querySelector('#bokamera-embedded').classList.remove('modal-open');
        }
    }, [modalOpen, setOpen, elRef]);

    useEffect(() => {
        if(modalOpen) {
            scrollToElement({ initiatedByUser: true });
        };
    }, [modalOpen])

    return (
        <div ref={elRef}>
            <div className="text-left text-muted">
                <div>
                    <Trans id="agreementText"></Trans>{' '}
                    <b onClick={onModalToggle} style={{ cursor: 'pointer' }}>
                        <Trans id="showAgreement"></Trans>
                    </b>
                </div>
            </div>
            {modalOpen && (
                // @ts-ignore
                <AgreementDialog company={company} onToggle={onModalToggle} node={elRef.current?.getRootNode().querySelector('#bokamera-embedded')} />
            )}
        </div>
    );
};

const connector = connect(({ company }: ApplicationState) => ({
  company: company.data,
}));

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Agreement);
