import { ADD_LOCALIZE_CATALOG } from '@/actions';
import { ActionType } from '@/types';

export default function localize(
    state = {},
    action: ActionType
) {
    switch (action.type) {
        case ADD_LOCALIZE_CATALOG:
            return Object.assign({}, state, action.payload)
        default:
            return state;
    }
}
