import React, { FunctionComponent, useState, useRef, useEffect, createRef } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Row, Table, Col, Card } from "reactstrap";
import { withRouter, RouteComponentProps } from "react-router";
import { orderBy, sortBy, uniq, flatten } from "lodash";
import { Link } from "react-router-dom";
import BEMHelper from "react-bem-helper";
import { Trans } from "@lingui/macro";

import Service from "@/components/partials/Service";
import Panel from "@/components/elements/Panel";
import { groupArray, scrollToElement, sortByDashes } from "@/misc/common";
import { ConfigKeys } from "@/misc/constants";
import { CompanyType, Configuration, ServiceType } from "@/types";
import { compose } from "redux";
import { getServicesToShow } from "@/selectors";
import { Collapse } from "react-collapse";
import "./Services.css";

import { isBefore } from "@/misc/datetime";
import { ApplicationState } from "@/store";
import readConfigurationProperty from "@/misc/readConfigurationProperty";

interface Props extends RouteComponentProps {
  configuration: Configuration;
  services: ServiceType[];
  company: CompanyType;
  dispatch: Dispatch<any>;
  hideSelect?: boolean;
  append?: React.FC<ServiceType>;
}

interface OwnProps {
  hideSelect?: boolean;
  append?: React.FC<ServiceType>;
  services?: ServiceType[];
}

interface GroupedServicesType {
  [key: string]: Array<ServiceType>;
}

const c = new BEMHelper({
  name: "Services",
});

export class Services extends React.Component<Props> {
  servicesRef = createRef<any>();

  componentDidMount() {
    const { configuration, services } = this.props;

    scrollToElement({
      element: this.servicesRef.current,
      scrollOffsetY:
        configuration[ConfigKeys.BOOK_LAYOUT] === "service-based"
          ? Number(configuration[ConfigKeys.TOP_OFFSET]) + 72
          : Number(configuration[ConfigKeys.TOP_OFFSET]),
    });

    if (this.props.configuration.selectedService) {
      const service = services.find(
        (service) => service.Id == configuration[ConfigKeys.SELECTED_SERVICE]
      );

      if (service) {
        this.props.history.push(`/services/${service.Id}/times`);
        this.props.dispatch({
          type: "SELECT_SERVICE",
          payload: service.Id
        });
      }
    }
  }

  render() {
    const { configuration, services, company } = this.props;
    const layoutType = configuration[ConfigKeys.LISTING_LAYOUT];
    const targetOrigin = configuration?.targetOrigin
      ? configuration?.targetOrigin
      : configuration?._targetOrigin;

    if (layoutType === ConfigKeys.LISTING_LAYOUT_ROWS_COMPACT) {
      return <div ref={this.servicesRef}>
        {this.renderServicesRows(services, true)}
        {this.renderServiceStructuredData(services, company)}
      </div>;
    }
    if (layoutType === ConfigKeys.LISTING_LAYOUT_ROWS) {
      return (
        <div ref={this.servicesRef}>
          {this.renderServicesRows(services)}
          {this.renderServiceStructuredData(services, company)}
        </div>
      );
    }
    if (layoutType === ConfigKeys.LISTING_LAYOUT_COLUMNS) {
      return (
        <div ref={this.servicesRef}>
          {this.renderServicesColumns(services)}
          {this.renderServiceStructuredData(services, company)}
        </div>
      )
    }
    if (layoutType === ConfigKeys.LISTING_LAYOUT_ROW_BASED_GROUPED) {
      return (
        <div ref={this.servicesRef}>
          <ServicesRowsGrouped
            targetOrigin={targetOrigin}
            company={company}
            services={services}
          />
          {this.renderServiceStructuredData(services, company)}
        </div>
      );
    }

    console.error("Unknown services layout detected: " + layoutType);

    return false;
  }

  renderServiceStructuredData = (services: ServiceType[], company: CompanyType) => {
    return (services.map(service => {
      const hoursAvailable = flatten([
        ...service.Schedules.RecurringSchedules.filter(
          (s) => s.Active && isBefore(new Date(), new Date(s.ValidTo))
        ).map((s) => ({
          opens: s.StartTime,
          closes: s.EndTime,
          dayOfWeek: s.RecurringScheduleDayOfWeekRelation.map(
            (r: any) => r.DayOfWeek
          ),
        })),
        ...services[0].Schedules.DateSchedules
        .reduce((acc: any[], curr) => {
          // @ts-ignore
          curr.Dates
          .filter((d: any) => d.IsActive)
          .forEach((d: any) => {
            acc.push({
              opens: new Date(d.From).toISOString().split("T")[1],
              closes: new Date(d.To).toISOString().split("T")[1],
              validFrom: d.From,
              validThrough: d.To
            })
          });
          return acc;
        }, []),
      ]);
        
      return (
        <script type="application/ld+json" key={service.Id}>
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Service",
            "provider": {
              "@type": "LocalBusiness",
              "name": company.Name
            },
            image: service.ImageUrl,
            description: service.Description,
            offers: service.Prices.map(price => ({
              price: price.Price,
              priceCurrency: price.CurrencyId
            })),
            ...(hoursAvailable.length > 0 ? { hoursAvailable } : {})
          })}
        </script>
      )
    }))
  }

  renderServicesColumns = (services: ServiceType[]) => {
    const groupedServices: GroupedServicesType = groupArray(services, "Group");

    return (
      <div {...c(undefined, { column: true })}>
        {Object.keys(groupedServices)
          .sort((a, b) => a.localeCompare(b))
          .map((groupName) => (
            <div key={groupName}>
              <h4 {...c("title")}>{groupName}</h4>
              <Row className={'mb-2'} >
                {groupedServices[groupName]
                  .sort((a, b) => a.SortOrder - b.SortOrder)
                  .map((service) => (
                    <Col xs={12} sm={6} md={4} lg={4} key={service.Id}>
                      <Service
                        service={service}
                        hideSelect={this.props.hideSelect}
                        append={this.props.append}
                      />
                    </Col>
                  ))}
              </Row>
            </div>
          ))}
      </div>
    );
  };

  renderServicesRows = (services: ServiceType[], compact = false) => {
    const groupedServices: GroupedServicesType = groupArray(services, "Group");
    const sortedGroupsBySortOrder = uniq(sortBy(services, 'SortOrder', 'Group').map(s => s.Group || ""));
    
    if (compact) {
      return (
        <Panel>
          <Table className="responsive-table" borderless>
            <tbody>
              {sortedGroupsBySortOrder
                .map((groupName) => (
                  <React.Fragment key={groupName}>
                    <tr>
                      <td colSpan={5}>
                        <h4>{groupName}</h4>
                      </td>
                    </tr>
                    {groupedServices[groupName]
                      .sort((a, b) => a.SortOrder - b.SortOrder)
                      .map((service) => this.renderServiceRow(service, true))}
                  </React.Fragment>
                ))}
            </tbody>
          </Table>
        </Panel>
      );
    }

    return (
      <div>
        {sortedGroupsBySortOrder
          .map((groupName) =>
            groupedServices[groupName]
              .sort((a, b) => a.SortOrder - b.SortOrder)
              .map((service) => (
                <div key={service.Id}>{this.renderServiceRow(service)} </div>
              ))
          )}
      </div>
    );
  };

  renderServiceRow = (service: ServiceType, compact = false) => {
    return (
      <Service
        key={service.Id}
        service={service}
        hideSelect={this.props.hideSelect}
        append={this.props.append}
        compact={compact}
      />
    );
  };
}

interface ServicesRowsGroupedProps {
  services: ServiceType[];

  /**
   * index numbers of the ones that are expanded
   */
  initiallyExpanded?: number[];
  targetOrigin?: string;
  company: CompanyType;
}

export const ServicesRowsGrouped: FunctionComponent<ServicesRowsGroupedProps> =
  ({
    services,
    initiallyExpanded = [],
    company,
    targetOrigin: targetOrigin = "",
  }) => {
    const orderedServices = orderBy(
      services.map((service) => {
        if (!service.Group) {
          service.Group = "Other";
        }
        return service;
      }),
      [(service) => service.Group.toLowerCase()]
    );

    const groupedServices: GroupedServicesType = groupArray(
      orderedServices,
      "Group"
    );
    const sortedGroupNames = orderBy(
      sortByDashes(Object.keys(groupedServices)),
      [(groupName) => groupName.toLowerCase()]
    );
    const [activeGroupId, setActiveGroupId] = useState("");
    const [expanded, setExpanded] = useState(initiallyExpanded);

    const onCollapseChange = (groupId: string) => {
      const newExpandedCollapsedIndex = sortedGroupNames
        .map((group) => convertToSectionNames(group))
        .indexOf(groupId);

      const alreadyThere = expanded.indexOf(newExpandedCollapsedIndex);

      if (alreadyThere !== -1 && alreadyThere !== 0) {
        const expandedClone = [...expanded];
        expandedClone.splice(alreadyThere);
        setExpanded(expandedClone);
      } else if (alreadyThere === 0) {
        const expandedClone = [...expanded];
        expandedClone.shift();
        setExpanded(expandedClone);
      } else {
        setExpanded([...expanded, newExpandedCollapsedIndex]);
      }
    };

    const gotoHeading = (groupName: string) => {
      const id = convertToSectionNames(groupName);
      setActiveGroupId(id);

      const newExpandedCollapsedIndex = sortedGroupNames
        .map((group) => convertToSectionNames(group))
        .indexOf(id);

      const alreadyThere = expanded.indexOf(newExpandedCollapsedIndex);

      // this means it's not there
      if (alreadyThere === -1) {
        setExpanded([...expanded, newExpandedCollapsedIndex]);
      } else {
        setExpanded(expanded.filter(e => e !== newExpandedCollapsedIndex));
        setTimeout(() => {
          setExpanded([...expanded, newExpandedCollapsedIndex])
        }, 50);
      }
    };

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        const el = document.getElementById(activeGroupId);
        const topTo = el?.getBoundingClientRect().top;
        if (topTo) {
          scrollToElement({ element: el })

          setActiveGroupId("");
        }
      }, 100);
      return () => clearTimeout(timeoutId);
    }, [activeGroupId]);

    return (
      <div {...c("ServicesRowsGrouped")}>
        <h4>
          <small className="text-muted">
            <Trans id="clickOnServiceGroup" />
          </small>
        </h4>
        <div className="group-anchors">
          {sortedGroupNames.map((groupName) => (
            <Link
              key={groupName}
              data-testid="groupAnchor"
              onClick={(evt) => {
                evt.preventDefault();
                gotoHeading(groupName);
              }}
              className="group-anchors__item"
              to={{
                hash: convertToSectionNames(groupName),
              }}
            >
              {groupName}
            </Link>
          ))}
        </div>
        {sortedGroupNames.map((groupName, i) => (
          <CollapsibleRow
            key={i}
            expanded={expanded.includes(i)}
            groupName={groupName}
            groupedServices={groupedServices}
            onCollapseChange={onCollapseChange}
          />
        ))}
      </div>
    );
  };

interface CollapsibleRowProps {
  groupName: string;
  groupedServices: GroupedServicesType;
  expanded: boolean;
  onCollapseChange: (groupId: string) => void;
}

interface RouterState {
  fromGroupAnchors?: boolean;
}

export const CollapsibleRow: FunctionComponent<CollapsibleRowProps> = ({
  groupName,
  groupedServices,
  expanded = false,
  onCollapseChange,
}) => {
  const sectionRef = useRef<HTMLElement>(null);
  const groupId = convertToSectionNames(groupName);
  return (
    <section
      {...c("collapsible-row")}
      key={groupName}
      id={groupId}
      ref={sectionRef}
    >
      <Card
        className="collapsible-card"
        onClick={() => onCollapseChange(groupId)}
      >
        <h4 {...c("title")}>
          <span>{groupName}</span>
          <span className={expanded ? "icon icon--expanded" : "icon"} />
        </h4>
      </Card>
      <Collapse key={`${groupName}-collapse`} isOpened={expanded}>
        {groupedServices[groupName]
          .sort((a, b) => a.SortOrder - b.SortOrder)
          .map((service) => (
            <Service
              key={service.Id}
              service={service}
            />
          ))}
      </Collapse>
    </section>
  );
};

const convertToSectionNames = (name: string = "") => {
  const cleanName = name
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
    .replace(/\//gi, "-")
    .replace(/\s/g, "-")
    .toLowerCase();

  return cleanName;
};

const mapStateToProps = (state: ApplicationState) => ({
  configuration: state.configuration.data,
  services: getServicesToShow(state),
  company: state.company.data,
});

export default compose<React.ComponentType<OwnProps>>(
  withRouter,
  connect(mapStateToProps)
)(Services);
