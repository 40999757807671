import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import BEMHelper from 'react-bem-helper';

// Elements
import Panel from '@/components/elements/Panel';
import { CompanyType } from '@/types';

import './Company.css';
import { Trans } from '@lingui/macro';
import { ApplicationState } from '@/store';

const c = new BEMHelper({ name: 'Company' });

interface Props {
    hideImage?: boolean;
    showPanel?: boolean;
    company: CompanyType;
}

interface OwnProps {
    hideImage?: boolean;
    showPanel?: boolean;
}

export function Company(props: Props) {
    const { company, showPanel } = props;

    if (!company) return null;

    const content = (
        <div {...c}>
            <h4 {...c('title')}>{company.Name}</h4>
            <div {...c('city')}>{company.City}</div>
            {company.Details && (
                <Table size="sm" responsive borderless>
                    <thead>
                        <tr>
                            <th>
                                <Trans id="about"></Trans>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{company.Details || <Trans id="missing"></Trans>}</td>
                        </tr>
                    </tbody>
                </Table>
            )}
            <Table size="sm" responsive>
                <thead>
                    <tr>
                        <th>
                            <Trans id="address"></Trans>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {company.Street1 ? `${company.Street1}, ` : ''}{company.ZipCode} {company.City}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );

    return showPanel ? <Panel>{content}</Panel> : <div>{content}</div>;
}

const mapStateToProps = ({ company, configuration }: ApplicationState) => ({
    company: company.data,
    configuration: configuration.data,
});

export default compose<React.ComponentType<OwnProps>>(connect(mapStateToProps))(Company);
