import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Spinner from '@/components/elements/Spinner';
import { Container } from "reactstrap";

interface State {
    hasError: boolean;
}

interface Props  extends RouteComponentProps {}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: any) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error: any) {
      console.log(error);

      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
      if(this.state.hasError && prevState.hasError !== this.state.hasError) {
        this.props.history.push('/');        
        window.location.reload()
      }
    }
  
    componentDidCatch(error: any, info: { componentStack: any; }) {

    }
  
    render() {
      if (this.state.hasError) {
        return (
          <Container>
            <Spinner noGraceTime></Spinner>
          </Container>
        );
      }
  
      return this.props.children;
    }
  }

  export default withRouter(ErrorBoundary);