import bmApi, { apiService } from "@/services/bmApi";
import {
  ApplicationDispatch,
  ApplicationState,
  authenticateInitialState,
  persistor,
  storage,
} from "@/store";
import {
  createListenerMiddleware,
  createSlice,
  isAnyOf,
  PayloadAction,
  TypedStartListening,
} from "@reduxjs/toolkit";
import { authoriseFail, authoriseSuccess } from "../authorise";
import {
  fixLoadingTooLong,
  login,
  logout,
  redirectAfterLogout,
  rehydrateState,
  reLogin,
} from "@/actions";
import { authenticateAuthoriseEffect } from "./authenticateAuthoriseEffects";
import { loginEffectDirect, loginEffectEmbed, reLoginEffectDirect, reLoginEffectEmbed } from "./loginEffect";
import { logoutEffectDirect, logoutEffectEmbed } from "./logoutEffect";
import {
  redirectAfterLoginEffectDirect,
  redirectAfterLoginEffectEmbed
} from "./afterLoginEffect";
import { afterLogoutEffect } from "./afterLogoutEffect";
import { isAuthenticateSuccessDirect, isAuthenticateSuccessEmbed, isLoginDirect, isLoginEmbed, isLogoutDirect, isLogoutEmbed, isRedirectAfterLoginDirect, isRedirectAfterLoginEmbed, isRehydrateStateDirect, isReLoginDirect, isReLoginEmbed, isSelectServiceTypeDirect, isSelectServiceTypeEmbed } from "./matchers";
import { clearCustomer } from "../customer";
import { LOCATION_CHANGE, push } from "connected-react-router";
import { keycloakConfig } from "@/keycloak";

export let logoutCounter = 0;
export let originalUrl = window.location.href;

export const initialState: authenticateInitialState = {
  isError: false,
  isLoaded: false,
  isLoading: false,
  isLoggedIn: false,
  error: undefined
};

export type AuthStartListening = TypedStartListening<
  ApplicationState,
  ApplicationDispatch
>;

export const authListener = createListenerMiddleware();

const startAuthListening = authListener.startListening as AuthStartListening;

const authenticateSlice = createSlice({
  name: "authenticate",
  initialState,
  reducers: {
    authenticateSuccess: (state) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.isLoggedIn = true;
      state.isError = false;
    },
    authenticateFail: (state, action) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.isLoggedIn = false;
      state.isError = true;
      state.error = action.payload;
    },
    authenticateClear: (state) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.isLoggedIn = false;
      state.isError = false;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.isLoggedIn = false;
      state.isError = false;
      state.error = undefined;
      delete state.persist;
      delete state.state;
    });

    builder.addCase(login, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(rehydrateState, (state, action) => {
      state.state = {...action.payload};
      state.persist = {
        rehydrated: true,
      }
    });

    builder.addCase(reLogin, (state, action) => {
      state.isLoading = true;
      state.state = undefined;
    });

    builder.addCase(authoriseFail, (state, action) => {
      state.isLoggedIn = false;
      state.state = undefined;
    })
  },
});

export const { authenticateFail, authenticateSuccess, authenticateClear } =
  authenticateSlice.actions;

startAuthListening({
  matcher: isAnyOf(
    authenticateSuccess,
    authoriseSuccess,
    bmApi.endpoints.getUsers.matchFulfilled,
    bmApi.endpoints.getUsers.matchRejected
  ),
  effect: authenticateAuthoriseEffect,
});


startAuthListening({
  predicate: (action, state) => {
    return (
      state.authenticate.isLoggedIn ||
      bmApi.endpoints.getUsers.matchFulfilled(action) ||
      bmApi.endpoints.getUsers.matchRejected(action)
    );
  },
  effect: authenticateAuthoriseEffect,
});

startAuthListening({
  matcher: isAnyOf(isLoginDirect),
  effect: loginEffectDirect,
});

startAuthListening({
  matcher: isAnyOf(isLoginEmbed),
  effect: loginEffectEmbed,
});


startAuthListening({
  matcher: isAnyOf(isLogoutDirect, clearCustomer),
  effect: logoutEffectDirect,
});

startAuthListening({
  matcher: isAnyOf(isLogoutEmbed, clearCustomer),
  effect: logoutEffectEmbed,
});

startAuthListening({
  matcher: isAnyOf(redirectAfterLogout),
  effect: afterLogoutEffect,
});

startAuthListening({
  matcher: isAnyOf(
    isRedirectAfterLoginDirect,
    isAuthenticateSuccessDirect,
    isSelectServiceTypeDirect
  ),
  effect: redirectAfterLoginEffectDirect,
});

startAuthListening({
  matcher: isAnyOf(
    isRedirectAfterLoginEmbed,
    isAuthenticateSuccessEmbed,
    isSelectServiceTypeEmbed,
    isRehydrateStateDirect
  ),
  effect: redirectAfterLoginEffectEmbed,
});

startAuthListening({
  matcher: isAnyOf(
    isReLoginEmbed,
    (a: PayloadAction): a is PayloadAction => a.type === LOCATION_CHANGE

  ),
  effect: reLoginEffectEmbed,
});

startAuthListening({
  matcher: isAnyOf(
    isReLoginDirect,
    (a: PayloadAction): a is PayloadAction => a.type === LOCATION_CHANGE

  ),
  effect: reLoginEffectDirect,
});

startAuthListening({
  matcher: isAnyOf(
    (a: PayloadAction): a is PayloadAction =>
      a.type === fixLoadingTooLong.request().type
  ),
  effect: (action, state) => {
    persistor.purge();
    state.dispatch(authenticateClear());
    storage.removeItem(keycloakConfig.clientId);
    state.dispatch(push("/"));
    window.location.reload();
  },
});



export default authenticateSlice;
