import React, { useContext, useEffect } from 'react';
import { I18nProvider as LinguiProvider, I18n as LinguiContextConsumer } from '@lingui/react';
import { I18n } from '@lingui/core';
import sv from '@/locales/sv/messages.js';
import en from '@/locales/en/messages.js';
import no from '@/locales/no/messages.js';
import fi from '@/locales/fi/messages.js';
import readConfigurationProperty from "@/misc/readConfigurationProperty";
import { ADD_LOCALIZE_CATALOG } from '@/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';

interface I18nProviderProps {
    children: React.ReactNode;
    defaultLanguage?: string;
}

const catalogs = {
    sv,
    en,
    no,
    fi,
};

const language = readConfigurationProperty('language') || 'sv';
const customTranslations = readConfigurationProperty('translations') || {};

if (customTranslations && customTranslations.length) {
    customTranslations.forEach((translation: { key: string; value: string }) => {
        // @ts-ignore
        catalogs.sv.messages[translation.key] = translation.value;
    });
}

export interface I18nContextProps extends I18n {
    language: string;
}
// @ts-ignore
export const I18nContext = React.createContext<I18nContextProps>();

export const useI18n = () => useContext(I18nContext);

const I18nProvider: React.FC<I18nProviderProps> = ({
    children,
    defaultLanguage = 'sv',
    // @ts-ignore
    dispatch
}) => {

    useEffect(() => {
        // @ts-ignore
        dispatch({ type: ADD_LOCALIZE_CATALOG, payload: catalogs[language].messages })
    }, []);

    return (
        <LinguiProvider language={language} catalogs={catalogs}>
            <LinguiContextConsumer>
                {({ i18n }: { i18n: I18n }) => (
                    <I18nContext.Provider
                        // @ts-ignore
                        value={{ ...i18n, language }}
                    >
                        {children}
                    </I18nContext.Provider>
                )}
            </LinguiContextConsumer>
        </LinguiProvider>
    );
};

// export default I18nProvider;

export default compose<React.ComponentType>(
    connect()
  )(I18nProvider);
