import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { Alert, Row, Col, FormGroup } from 'reactstrap';
import { compose } from 'redux';

import Input from '@/components/elements/Input';
import { Trans } from '@lingui/macro';
import { ApplicationState } from '@/store';

interface Props extends InjectedFormProps<any> {
    [key: string]: any;
}

interface OwnProps {
    anonymous?: boolean;
}

const validateEmail = (value: string) =>
    value && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value)
        ? 'Ange en giltig e-postadress'
        : undefined;


export class CustomerForm extends React.Component<Props> {
    render() {
        const { error, company, anonymous, isLoggedIn } = this.props;

        return (
            <>
                {/*
                // @ts-ignore */}
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Field
                                className="form-component"
                                name="Firstname"
                                component={Input}
                                label={<Trans id="recipientForm.firstname"></Trans>}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Field
                                className="form-component"
                                name="Lastname"
                                component={Input}
                                label={<Trans id="recipientForm.lastname"></Trans>}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <Field
                        className="form-component"
                        name="Email"
                        component={Input}
                        validate={validateEmail}
                        label={<Trans id="recipientForm.email"></Trans>}
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <Field
                        className="form-component"
                        name="ReceiverMessage"
                        component={Input}
                        type="textarea"
                        label={<Trans id="recipientForm.messageToTheRecipient"></Trans>}
                        required
                    />
                </FormGroup>
               
                {error && <Alert color="danger">{error}</Alert>}
            </>
        );
    }
}

function mapStateToProps(state: ApplicationState, props: any) {
    
    const initialValues: any = {

    }

    return {
        isLoggedIn: state.authenticate.isLoggedIn,
        company: state.company.data,
        form: 'recipientForm',
        initialValues,
        onSubmitSuccess: () => {
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
    };
}

export default compose(
    connect(mapStateToProps),
    reduxForm({
        enableReinitialize: true,
        // onSubmit: onSubmitActions('BOOK', 'SAVE_BOOKING', 'FORM_ERROR'),
        destroyOnUnmount: false,
        keepDirtyOnReinitialize: true,
    })
)(CustomerForm) as React.ComponentType<OwnProps>;