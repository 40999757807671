import React from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';

// Elements
import Spinner from '@/components/elements/Spinner';
import Calendar from '@/components/elements/Calendar';

import { ServicesStateType, TimesStateType, Configuration, ServiceType } from '@/types';
import { Trans } from '@lingui/macro';
import { ApplicationState } from '@/store';

interface Props {
    configuration: Configuration;
    services: ServicesStateType;
    service: ServiceType;
    times: TimesStateType;
    dispatch: Dispatch<any>;
}

interface OwnProps {
    service?: ServiceType;
}

export class CalendarMonth extends React.Component<Props> {
    render() {
        const { services, service, times, configuration } = this.props;

        // Make sure that atleast some times are loaded before showing the month view
        const finishedLoading = services.data
            .map((service) => times[service.Id].isLoading)
            .filter((isLoading) => isLoading === false);
        if (finishedLoading.length === 0)
            return (
                <Spinner>
                    <Trans id="loadingTimes"></Trans>
                </Spinner>
            );

        return <Calendar date={configuration.navigationDate} service={service} />;
    }
}

const mapStateToProps = ({ configuration, booking, services, times }: ApplicationState) => ({
    configuration: configuration.data,
    booking,
    services,
    times,
});

export default compose<React.ComponentType<OwnProps>>(connect(mapStateToProps))(CalendarMonth);
