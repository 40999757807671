import React from "react";

import { Button } from "bokamera-embedded-ui";
import { Trans } from "@lingui/macro";

import { connect } from "react-redux";

import * as actions from "@/actions";
import { useLocation } from "react-router";

interface LoginFormProps {
  handleSubmit: () => void;
  dispatch: Function;
}

export const LoginForm = ({ dispatch, handleSubmit }: LoginFormProps) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
        dispatch(actions.login());
      }}
    >
      <div className="form-group">
        <Button block type="submit">
          <Trans id="logIn"></Trans>
        </Button>
      </div>
    </form>
  );
};

export default connect()(LoginForm);
