import React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Alert, Media } from 'reactstrap';
import { Button } from 'bokamera-embedded-ui';

// Partials
import CalendarMonth from '@/components/partials/CalendarMonth';
import CalendarQuarter from '@/components/partials/CalendarQuarter';
import CalendarToolbar from '@/components/partials/CalendarToolbar';
import Resource from '@/components/partials/Resource';
import Times from '@/components/partials/Times';
import Service from '@/components/partials/Service';

// Elements
import Spinner from '@/components/elements/Spinner';
import Panel from '@/components/elements/Panel';

// Misc
import { groupArray, formatFromTo } from '@/misc/common';
import { ConfigKeys } from '@/misc/constants';
import {
    Configuration,
    TimesStateType,
    BookingStateType,
    TimeType,
    ResourceTypeType,
    ServiceType,
} from '@/types';
import { Trans } from '@lingui/macro';
import { ApplicationState } from '@/store';

interface Props {
    dispatch: Dispatch<any>;
    configuration: Configuration;
    booking: BookingStateType;
    services: ServiceType[];
    times: TimesStateType;
    serviceId: string;
}

interface OwnProps {
    serviceId: string;
}

interface GroupedTimesType {
    [key: string]: Array<TimeType>;
}

export class TimesResources extends React.Component<Props> {
    render() {
        const { configuration, booking, times } = this.props;
        const topOffset = configuration.topOffset || 0;
        
        if (!booking.service) return null;

        return (
            <div data-testId={`times-resources-${booking.service.Id}`}>
                {configuration.selectedService && <Service service={booking.service} hideSelect />}
                <Panel>
                    <CalendarToolbar service={booking.service} />

                    {times && times.isLoading && (
                        <Spinner>
                            <Trans id="loadingTimes"></Trans>
                        </Spinner>
                    )}

                    {configuration.timesLayout === ConfigKeys.TIMES_LAYOUT_MONTH && (
                        <CalendarMonth service={booking.service} />
                    )}
                    {configuration.timesLayout === ConfigKeys.TIMES_LAYOUT_QUARTER && (
                        <CalendarQuarter service={booking.service} />
                    )}
                </Panel>

                <div id="times" style={{ scrollMarginTop: `${topOffset}px`}}>
                    {!times.isLoading ? this.renderTimesResourcesTypes(times, booking.service): null}
                    {!times.isLoading ? this.renderNextAvailableTime() : null}
                </div>
            </div>
        );
    }

    renderNextAvailableTime = () => {
        if(!this.props?.serviceId || !this.props?.times?.nextAvailableTime) return null;

        const service = this.props.services.find(s => s.Id.toString() === this.props.serviceId);
        const nextAvailableTime: any = this.props.times?.nextAvailableTime;

        if (!nextAvailableTime || !service) return null;

        return (
            <Panel title={<Trans id="nextAvailableTime"></Trans>}>
                <Media list style={{ paddingLeft: 0 }}>
                    <Media>
                        <Media body>
                            <div>
                                {formatFromTo(nextAvailableTime.From, nextAvailableTime.To, {
                                    renderDateIfSameDay: true,
                                })}
                            </div>
                        </Media>
                        <Media right align="center">
                            <Button
                                primary
                                onClick={() => {
                                    this.props.dispatch({
                                        type: 'SELECT_NEXT_AVAILABLE_TIME',
                                        service: service,
                                        date: nextAvailableTime.From,
                                    });
                                }}
                            >
                                {<Trans id="showDay"></Trans>}
                            </Button>
                        </Media>
                    </Media>
                </Media>
            </Panel>
        );
    }

    renderTimesResourcesTypes = (times: TimesStateType, service: ServiceType) => {
        const timesGroupedByResourceType: GroupedTimesType = groupArray(
          // TODO: what's going on here?
          // @ts-ignore
          times.times.filter((time) =>
            service.ResourceTypes.map((res) => res.Id).includes(
              time.ResourceTypeId
            )
          ),
          "ResourceTypeId"
        );

        return Object.keys(timesGroupedByResourceType).map((resourceTypeId) =>
            this.renderTimesResourceType(
                parseInt(resourceTypeId),
                timesGroupedByResourceType[resourceTypeId]
            )
        );
    };

    renderTimesResourceType = (resourceTypeId: number, times: Array<TimeType>) => {
        const { booking } = this.props;
        if (!booking.service) return null;
        // eslint-disable-next-line
        const resourceType = booking.service.ResourceTypes.find(
            (resourceType: ResourceTypeType) => resourceType.Id == resourceTypeId
        );

        if (!resourceType)
            return (
                <Alert color="danger">
                    <Trans id="noAvailableResources"></Trans>
                </Alert>
            );

        return this.renderTimesResourceTypeRows(resourceType, times);
    };

    renderTimesResourceTypeRows = (resourceType: ResourceTypeType, times: Array<TimeType>) => {
        const timesGroupedByResource: GroupedTimesType = groupArray(times, 'ResourceId');

        return (
            <Panel title={resourceType.Name}>
                <Media list style={{ paddingLeft: 0 }}>
                    {Object.keys(timesGroupedByResource).map((resourceId: string) =>
                        this.renderTimesResourceRow(resourceType, parseInt(resourceId))
                    )}
                </Media>
            </Panel>
        );
    };

    renderTimesResourceRow = (resourceType: ResourceTypeType, resourceId: number) => {
        const resource = resourceType.Resources.find((resource) => resource.Id == resourceId);

        const { service } = this.props.booking;

        if (!resource) return null;
        if (!service) return null;

        return (
            <div>
                <hr />
                <Resource
                    resource={resource}
                    append={(resource) => (
                        <Times service={service} resourceType={resourceType} resource={resource} />
                    )}
                />
            </div>
        );
    };
}

function mapStateToProps(state: ApplicationState, props: any) {
    if (!props.serviceId) {
        console.error('No serviceId available as props');
    }

    return {
        configuration: state.configuration.data,
        booking: state.booking,
        times: state.times[props.serviceId],
        services: state.services.data
    };
}

export default compose<React.ComponentType<OwnProps>>(connect(mapStateToProps))(TimesResources);
