import { definitions } from '@/apitypes';
import { BookingStateType, ServiceType, QuantityToSubmit } from '@/types';
import { AnyAction, Reducer } from 'redux';

// Have initial state function instead of plain object,
// because of need for creating a new object for each service
export const initialState = {
    isLoading: false,
    data: null,
    service: null, // lightweight service
    finalService: null, // service with all properties
    time: null,
    resources: {},
    quantities: [],
    customFields: [],
    bookingUserQueue: null,
    priceDetails: []
};

export const bookingReducer: Reducer<BookingStateType, AnyAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case 'INIT_BOOKING': {
            return initialState;
        }
        case 'SELECT_SERVICE': {
            return {
                ...initialState,
                service: action.service,
                quantities: getServiceInitialQuantities(action.service),
            };
        }
        case 'SAVE_FINAL_SERVICE': {
            return {
                ...state,
                finalService: action.service,
                quantities: getServiceInitialQuantities(action.service),
            };
        }
        case 'UPDATE_BOOKING_QUANTITIES': {
            return {
                ...state,
                priceDetails: action.payload.PriceDetails
            }
        }
        case 'SELECT_TIME': {
            return { ...state, service: action.service, time: action.time };
        }
        case 'UPDATE_SERVICE_WITH_RESOURCE': {
            return {
                ...state,
                service: {
                    ...state.service,
                    ...action.service
                }
            };
        }
        case 'SELECT_RESOURCE': {
            const resources = { ...state.resources };

            if (!action.payload.resource) {
                delete resources[action.payload.resourceType.Id];
            } else {
                resources[action.payload.resourceType.Id] = action.payload.resource.Id;
            }

            return { ...state, resources };
        }
        case 'CHANGE_QUANTITY': {
            const { quantities } = state;
            const nextQuantities: Array<any> = [];
            // Don't allow negative quantity values
            const nextQuantity = Math.max(0, action.value);

            if (action.price) {
                let replaced = false;

                quantities.forEach((quantity) => {
                    if (quantity.PriceId === action.price.Id) {
                        nextQuantities.push({
                            ...quantity,
                            Quantity: nextQuantity,
                        });

                        replaced = true;
                    } else {
                        nextQuantities.push(quantity);
                    }
                });

                if (!replaced) {
                    nextQuantities.push({
                        ...action.price,
                        PriceId: action.price.Id,
                        Quantity: 1,
                    });
                }
            } else {
                nextQuantities.push({
                    Quantity: nextQuantity,
                });
            }

            return {
                ...state,
                quantities: nextQuantities,
            };
        }
        case 'SELECT_PRICE': {
            return {
                ...state,
                quantities: [{ ...action.price, PriceId: action.price.Id, Quantity: 1 }],
            };
        }
        case 'SAVE_CUSTOM_FIELDS': {
            return { ...state, customFields: action.customFields };
        }
        case 'SAVE_CUSTOM_FIELD': {
            const customFields = [...state.customFields];
            const customField = { ...action.customField, Value: action.value };
            customFields.splice(customFields.indexOf(action.customField), 1, customField);
            return { ...state, customFields };
        }
        case 'SAVE_CLEAR_TIME': {
            return { ...state, time: null };
        }
        case 'SAVE_CLEAR_SERVICE': {
            return { ...state, service: null, finalService: null };
        }
        case 'SAVE_CLEAR_RESOURCES': {
            return { ...state, resources: {} };
        }
        case 'LOAD_BOOKING': {
            return { ...state, isLoading: true, data: null };
        }
        case 'SAVE_BOOKING': {
            return { ...state, isLoading: false, data: action.booking };
        }
        case 'FAIL_BOOKING': {
            return { ...state, isLoading: false, data: null };
        }
        case 'CLEAR_BOOKINGS': {
            return initialState;
        }
        case 'ADD_TO_QUEUE': {
            return { ...state, isLoading: false, bookingUserQueue: action.bookingUserQueue };
        }
        default:
            return state;
    }
};

export function getServiceInitialQuantities(service: ServiceType): QuantityToSubmit[] {
    let quantities: QuantityToSubmit[] = [];

    if (!service || !service.Prices) {
        return [];
    }

    const hasGroupBooking =
        service.GroupBooking.Active && service.GroupBooking.Min !== service.GroupBooking.Max;

    const hasMultipleResource =
        service.MultipleResource.Active &&
        service.MultipleResource.Min !== service.MultipleResource.Max;

    // Quantity if no prices
    if (service.Prices && service.Prices.length === 0) {
        quantities = [{ Quantity: 1 }];
    }

    // Return setup price as quantities
    if (!(hasGroupBooking || hasMultipleResource)) {
        // Setup price automatically if only one available (no need to select)
        if (service.Prices && service.Prices.length === 1) {
            const { Id } = service.Prices[0];
            quantities = [{ ...service.Prices[0], Quantity: 1, PriceId: Id }];
        }
    }

    // Return service count as quantities
    if (service.Prices.length === 1 && (hasGroupBooking || hasMultipleResource)) {
        quantities = [
            {
                ...service.Prices[0],
                Quantity: service.GroupBooking.Min || 1,
                PriceId: service.Prices[0].Id,
            },
        ];
    }

    return quantities;
}

export default bookingReducer;
