import React, { useState, useCallback, useRef } from 'react';
import BEMHelper from 'react-bem-helper';
import { Input } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '@/actions';

import { Button } from 'bokamera-embedded-ui';

import './PromoCode.css';
import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { I18n } from '@lingui/core';
import { useAppDispatch, useAppSelector } from '@/hooks';

const c = new BEMHelper({
    name: 'PromoCode',
});

export const SelectQuantities: React.FC<{ i18n: I18n }> = ({ i18n }) => {
    const inputEl = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const  promoCodes = useAppSelector(s => s.promoCodes);
    const [code, setCode] = useState('');

    const handleApply = useCallback(() => {
        dispatch(
            actions.applyPromoCodeAsync.request({
                RebateCodeSign: code,
            })
        );
    }, [code, dispatch]);

    const toggleInput = useCallback(() => {
        let nextInputShown = !promoCodes.inputShown;

        dispatch(nextInputShown ? actions.showPromoCodeInput() : actions.hidePromoCodeInput());

        if (!nextInputShown) {
            setCode('');
        }

        if (inputEl && inputEl.current) {
            inputEl.current.focus();
        }
    }, [promoCodes.inputShown, inputEl, dispatch]);

    return (
        <div {...c()}>
            <button
                {...c('toggle')}
                onClick={toggleInput}
                color="link"
                data-testid="PromoCode__toggle"
            >
                <Trans id="addPromoCode"></Trans>
            </button>
            {promoCodes.inputShown ? (
                <div {...c('controls')}>
                    <Input
                        {...c('input')}
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder={i18n._(t('enterPromoCode')``)}
                        data-testid="PromoCode__input"
                        innerRef={inputEl}
                        autoFocus
                    />
                    <Button outline onClick={handleApply}>
                        <Trans id="apply"></Trans>
                    </Button>
                </div>
            ) : null}
            {code && promoCodes?.error?._error ? <div {...c('error')}>{promoCodes.error._error}</div> : null}
        </div>
    );
};

export default withI18n()(SelectQuantities);
