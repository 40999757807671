import { combineReducers, compose } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { StateType } from 'typesafe-actions';
import booking from '@/reducers/booking';
import checkout from '@/reducers/checkout';
import configuration from '@/reducers/configuration';
import company from '@/reducers/company';
import prices from '@/reducers/prices';
import promoCodes from '@/reducers/promoCodes';
import services from '@/reducers/services';
import times from '@/reducers/times';
import createAccount from '@/reducers/createAccount';
import authenticateSlice from "./authenticate";
import authoriseSlice from "./authorise";
import flexibleHoursSlice from './flexibleHours';
import uiSlice from './ui';

import bmApiService from '@/services/bmApi';
import messages from '@/reducers/messages';


import customerSlice from '@/reducers/customer';
import { History } from 'history';
import localize from './localize';
import articleSlice from './article';
import articlesSlice from './articles';
import { storage } from '@/store';

const createRootReducer = (history: History) => {
    const connectedRouterReducer = connectRouter(history);
    return combineReducers({
        router: ((state, action) => {
            // console.log(action);
            if(action.type === "PURGE_PERSISTED") {
                storage.removeItem('bm-embed');
                storage.removeItem('bm-embedConfig');
                storage.removeItem('persist:bm-embed-root');
            }
            return connectedRouterReducer(state, action);
        }) as typeof connectedRouterReducer,
        form: formReducer,
        authenticate: authenticateSlice.reducer,
        authorise: authoriseSlice.reducer,
        [flexibleHoursSlice.name]: flexibleHoursSlice.reducer,
        ui: uiSlice.reducer,
        booking,
        checkout,
        company,
        configuration: configuration.reducer,
        prices,
        promoCodes,
        services,
        times,
        user: customerSlice.reducer,
        createAccount,
        messages,
        localize,
        [articleSlice.name]: articleSlice.reducer,
        [articlesSlice.name]: articlesSlice.reducer,
        [bmApiService.reducerPath]: bmApiService.reducer
    });
}

export default createRootReducer;

export type RootState = StateType<ReturnType<typeof createRootReducer>>;
