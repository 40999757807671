import { ApplicationDispatch, ApplicationState, storage } from "@/store";
import { AnyAction, ListenerEffect } from "@reduxjs/toolkit";
import { redirectAfterLogout } from "@/actions";
import qs from "qs";
import { replace } from "connected-react-router";
import { keycloakConfig } from "@/keycloak";

export const afterLogoutEffect: ListenerEffect<
  AnyAction,
  ApplicationState,
  ApplicationDispatch
> = async (action, api) => {
  if (redirectAfterLogout.match(action)) {
    const parsedSearch = qs.parse(window.location.hash.split("?")[1], {
      ignoreQueryPrefix: true,
    });

    const persistedAuthRaw = await storage.getItem(keycloakConfig.clientId) as string;
    const _redirectTo = JSON.parse(persistedAuthRaw || "null").redirectTo;


    delete parsedSearch.state;
    delete parsedSearch.session_state;
    delete parsedSearch.code;

    const url = new URL(window.location.origin);
    url.hash = _redirectTo as string;
    url.search = window.location.search;

    window.history.pushState("", "", url.href);
    api.dispatch(replace(_redirectTo));

    storage.removeItem(keycloakConfig.clientId);

    // TODO: post logout without affecting state
    // const targetOrigin = api.getState().configuration.data.targetOrigin;
    // window.parent.postMessage({ logout: true }, targetOrigin || "*");
  }
};
