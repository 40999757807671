import { Middleware } from 'redux';
import { BookingType } from '@/types';

declare global {
    interface Window {
        fbq: any;
    }
}

const facebookPixelMiddleware: Middleware = store => next => action => {
    if (window.fbq) {
        try {
            switch (action.type) {
                case 'SELECT_COMPANY_SUCCESS':
                    window.fbq('track', 'Lead');
                    break;
                case 'SAVE_BOOKING':
                    const booking: BookingType = action.booking;

                    window.fbq(
                        'track',
                        'Purchase',
                        booking.Quantities && booking.Quantities.length > 0
                            ? {
                                  value: booking.Quantities.reduce(
                                      (acc, quantity) =>
                                          acc + (quantity.Price || 0) * (quantity.Quantity || 0),
                                      0
                                  ),
                                  currency: booking.Quantities[0].CurrencyId,
                              }
                            : undefined
                    );
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error(err);
        }
    }

    return next(action);
};

export default facebookPixelMiddleware;
