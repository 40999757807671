import React from 'react';
import { Alert, Table } from 'reactstrap';
import Counter from '@/components/elements/Counter';
import { BookingStateType, PriceType, ServiceType } from '@/types';
import { useSelector, useDispatch } from 'react-redux';
import { FormGroup, Label, Input } from 'reactstrap';
import Panel from '@/components/elements/Panel';
import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { I18n } from '@lingui/core';

import { format } from '@/misc/datetime';

const getNaturalFromTo = (from: string, to: string, i18n: I18n) => {
    if(!from) return '';

    const iStoTimeInfinite = to.includes('9999');
    const isWholeDay = (from.includes('00:00:00') && to.includes('23:59:00')) || (from.includes('00:00:00') && to.includes('23:59:59'))
    
    let text = `${i18n._('selectQuantities.availableFrom')}`;

    if(!iStoTimeInfinite) {
        text = `${text} ${format(from, "PPp")}-${format(to, "PPp")}`
    } else if(!isWholeDay) {
        text = `${text} ${format(from, "p")} - ${format(to, "p")}`
    } 

    return text;
}

export const SelectQuantities: React.FC<{i18n: I18n}> = (props) => {
    const { i18n } = props;
    const dispatch = useDispatch();
    const { booking, services } = useSelector(({ booking, services, ...rest }: { booking: BookingStateType, services: any }) => {
        return {
            services: services.data,
            booking
        };
    });
    
    
    
    const { finalService: service, quantities } = booking;
    const allPricesFromService: PriceType[] = services.find((s: ServiceType) => s.Id ===  service?.Id)?.Prices
        ? services.find((s: ServiceType) => s.Id ===  service?.Id)?.Prices 
        : []

    if (!service) return null;

    const prices = service.Prices;
    const hasPrices = prices.length > 0;
    const hasMultiplePrices = service.Prices && service.Prices.length > 1;

    const maxQuantity = service.GroupBooking.Active
        ? service.GroupBooking.Max
        : service.MultipleResource.Max;

    const totalPrice = quantities
        .map((quantity) => (quantity.Price || 0) * quantity.Quantity)
        .reduce((a, b) => a + b, 0);

    const totalQuantity = quantities
        .map((quantity) => quantity.Quantity)
        .reduce((a, b) => a + b, 0);

    const spotsLeft = booking.time?.Free || 0;

    const hasGroupBooking =
        service.GroupBooking.Active && service.GroupBooking.Min !== service.GroupBooking.Max;

    const hasMultipleResource =
        service.MultipleResource.Active &&
        service.MultipleResource.Min !== service.MultipleResource.Max;

    if (!(hasGroupBooking || hasMultipleResource) && !hasMultiplePrices) return null;

    if (!(hasGroupBooking || hasMultipleResource) && hasMultiplePrices) {
        const quantity = booking.quantities[0];

        return (
            <Panel>
                {prices.map((price: PriceType) => (
                    <FormGroup
                        key={price.Id}
                        check
                        data-testid={`select-price-quantity-${price.Id}`}
                    >
                        <Label check>
                            <Input
                                type="radio"
                                name="Price"
                                checked={quantity ? quantity.PriceId === price.Id : false}
                                onChange={(event) => {
                                    dispatch({
                                        type: 'SELECT_PRICE',
                                        price: price,
                                    });
                                }}
                            />{' '}
                            {price.PriceText}
                        </Label>
                    </FormGroup>
                ))}
                {allPricesFromService
                    .filter(price => !prices.map(p => p.Id).includes(price.Id))
                    .map((price) => {
                        const { Id, From, FromTime, To, ToTime} = price;
                        const fromTime = `${From.split('T')[0]}T${FromTime}`;
                        const toTime = `${To.split('T')[0]}T${ToTime}`;

                        return (
                            <FormGroup
                            key={Id}
                            check
                            title={getNaturalFromTo(fromTime, toTime, i18n)}
                            data-testid={`select-price-quantity-${Id}`}
                        >
                                <Label check style={{ color: '#ccc'}}>
                                    <Input
                                        disabled
                                        type="radio"
                                        name="Price"
                                        checked={quantity ? quantity.PriceId === Id : false}
                                        onChange={(event) => {
                                            dispatch({
                                                type: 'SELECT_PRICE',
                                                price: price,
                                            });
                                        }}
                                    />{' '}
                                    {price.PriceText}
                                </Label>
                            </FormGroup>
                        )
                })}
            </Panel>
        );
    }

    return (
        <>
            <Table size="sm" responsive borderless>
                <thead>
                    <tr>
                        {hasPrices && <th />}
                        {hasPrices && (hasGroupBooking || hasMultipleResource) && (
                            <th className="text-left">{<Trans id="price"></Trans>}</th>
                        )}
                        {(hasGroupBooking || hasMultipleResource) && (
                            <th className="text-center">{<Trans id="number"></Trans>}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {prices.map((price, idx) => {
                        const currentQuantity = quantities.find(
                            (quantity) => quantity.PriceId === price.Id
                        );

                        const quantity = currentQuantity ? currentQuantity.Quantity : 0;

                        return (
                            <tr key={idx}>
                                <td className="text-left">
                                    {!(hasGroupBooking || hasMultipleResource) &&
                                        hasMultiplePrices && <input type="radio" />}
                                    {price.Category || <Trans id="price"></Trans>}
                                </td>

                                <td className="text-left">
                                    {price.Price}
                                    {price.PriceSign}
                                </td>
                                {(hasGroupBooking || hasMultipleResource) && (
                                    <td className="text-center">
                                        <Counter
                                            value={quantity}
                                            onDecrement={() =>
                                                dispatch({
                                                    type: 'CHANGE_QUANTITY',
                                                    value: quantity - 1,
                                                    price,
                                                })
                                            }
                                            onIncrement={() =>
                                                dispatch({
                                                    type: 'CHANGE_QUANTITY',
                                                    value: quantity + 1,
                                                    price,
                                                })
                                            }
                                        />
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                    {!hasPrices && (
                        <tr>
                            <td>
                                <Counter
                                    value={totalQuantity}
                                    onDecrement={() =>
                                        dispatch({
                                            type: 'CHANGE_QUANTITY',
                                            value: totalQuantity - 1,
                                        })
                                    }
                                    onIncrement={() =>
                                        dispatch({
                                            type: 'CHANGE_QUANTITY',
                                            value: totalQuantity + 1,
                                        })
                                    }
                                />
                            </td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    {(((hasGroupBooking || hasMultipleResource) && hasPrices) ||
                        hasMultiplePrices) && (
                        <tr>
                            <td className="text-left">{<Trans id="totally"></Trans>}</td>
                            {hasPrices && (
                                <td data-testid="SelectQuantities__totalPrice">
                                    {totalPrice >= 0 ? totalPrice : 0}
                                    {prices[0].PriceSign}
                                </td>
                            )}
                            {(hasGroupBooking || hasMultipleResource) && (
                                <td className="text-center">{totalQuantity}</td>
                            )}
                        </tr>
                    )}
                </tfoot>
            </Table>
            {(totalQuantity > maxQuantity || totalQuantity > spotsLeft) && !booking.service?.EnableBookingQueue && (
                <Alert color="danger">{<Trans id="quantityLimitExceeded"></Trans>}</Alert>
            )}
        </>
    );
};

export default withI18n()(SelectQuantities);
