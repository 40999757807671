import React from 'react';
import { connect } from 'react-redux';
import { Media } from 'reactstrap';

// Elements
import Thumbnail from '@/components/elements/Thumbnail';

import { ConfigKeys } from '@/misc/constants';
import { Configuration, ResourceInterface } from '@/types';
import { ApplicationState } from '@/store';

interface Props {
    configuration: Configuration;
    resource: ResourceInterface;
    append?(resource: ResourceInterface): React.ReactNode;
}

export const Resource: React.FC<Props> = props => {
    const { configuration, append, resource } = props;

    return (
        <Media>
            {!configuration[ConfigKeys.HIDE_RESOURCE_IMAGE] && resource.ImageUrl && (
                <Media left middle style={{ marginRight: 20 }}>
                    <div className="d-sm-none">
                        <Thumbnail src={resource.ImageUrl} size={64} />
                    </div>
                    <div className="d-none d-sm-block">
                        <Thumbnail src={resource.ImageUrl} size={128} />
                    </div>
                </Media>
            )}
            <Media body>
                <div>
                    <Media heading>{resource.Name}</Media>
                    {resource.Name !== resource.Description && <p>{resource.Description}</p>}

                    {append && append(resource)}
                </div>
            </Media>
        </Media>
    );
};

const mapStateToProps = ({ configuration }: ApplicationState) => ({
    configuration: configuration.data,
});

export default connect(mapStateToProps)(Resource);
