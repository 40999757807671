/* eslint-disable no-extend-native */
import "core-js/features/array/find";
import "core-js/features/string/ends-with";
import "core-js/features/string/starts-with";
import "core-js/features/number/is-nan";
import "core-js/features/object/is";
import "core-js/features/array/flat-map";
import { format } from "date-fns";
import { polyfill } from "seamless-scroll-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";

import "../node_modules/bokamera-embedded-ui/dist/bokamera-ui.css";
import "./stylesheets/light/light.scss";
import "./stylesheets/reboot.css";
import "./stylesheets/main.css";

// @ts-ignore
import { iframeResizerContentWindow } from "iframe-resizer";

// This needs to be referenced so it isn't tree-shaken.
let a = iframeResizerContentWindow;

polyfill();

// Because server uses local ISO dates
Date.prototype.toISOString = function toLocalISOString() {
  return format(this, "yyyy-MM-dd'T'HH:mm:ss");
};

// render the main component
const appContainer = window.bookingAppContainer || document.getElementById('bokamera-embedded');

const render = (Component: React.ComponentType) =>
  ReactDOM.render(
    <>
      <Component />
      <div style={{ clear: 'both' }} data-iframe-height></div>
    </>,
    appContainer
  );

render(App);

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
