import { ActionType, CheckoutStateType } from '@/types';

export default function checkout(
    state: CheckoutStateType = {
        isLoading: false,
        data: undefined,
        hasError: false,
        error: null,
    },
    action: ActionType
) {
    switch (action.type) {
        case 'CREATE_CHECKOUT_REQUEST':
            return {
                ...state,
                isLoading: true,
                data: undefined,
            };
        case 'CREATE_CHECKOUT_SUCCESS':
            return {
                ...state,
                isLoading: false,

                data: action.payload,
            };
        case 'CREATE_CHECKOUT_FAILURE':
            return {
                ...state,
                isLoading: false,
                hasError: true,
                error: action.payload,
                data: undefined,
            };
        case 'CREATE_CHECKOUT_CLEAR':
            return {
                ...state,
                isLoading: false,
                hasError: false,
                error: null,
                data: undefined,
            };
        default:
            return state;
    }
}
