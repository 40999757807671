import React from 'react';
import { connect } from 'react-redux';
import { onSubmitActions } from 'redux-form-submit-saga';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { Alert, Row, Col, FormGroup } from 'reactstrap';
import { compose } from 'redux';

import Input from '@/components/elements/Input';
import CustomFieldTextBox from '@/components/elements/CustomFieldTextBox';
import CustomFieldDropDown from '@/components/elements/CustomFieldDropDown';
import { CustomFieldType } from '@/types';
import { Trans } from '@lingui/macro';
import { ApplicationState } from '@/store';
import qs from 'qs';

interface Props extends InjectedFormProps<any> {
    [key: string]: any;
}

interface OwnProps {
    anonymous?: boolean;
}

const validateEmail = (value: string) =>
    value && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value)
        ? 'Ange en giltig e-postadress'
        : undefined;
export class CustomerForm extends React.Component<Props> {
    render() {
        const { handleSubmit, error, company, anonymous, isLoggedIn } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                {/*
                // @ts-ignore */}
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Field
                                className="form-component"
                                name="Customer.Firstname"
                                component={Input}
                                label={<Trans id="firstname"></Trans>}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Field
                                className="form-component"
                                name="Customer.Lastname"
                                component={Input}
                                label={<Trans id="lastname"></Trans>}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Field
                        className="form-component"
                        name="Customer.Phone"
                        component={Input}
                        label={<Trans id="phone"></Trans>}
                        required
                    />
                </FormGroup>
                <FormGroup style={{ display: `${isLoggedIn ? 'none' : undefined}`}}>
                    <Field
                        className="form-component"
                        name="Customer.Email"
                        disabled={!anonymous}
                        component={Input}
                        label={<Trans id="email"></Trans>}
                        required
                        type="email"
                        validate={validateEmail}
                    />
                </FormGroup>
                {company.BookingSettings.BookOnlyOnExistingCustomers && (
                    <FormGroup style={{ display: `${isLoggedIn ? 'none' : undefined}`}}>
                        <Field
                            className="form-component"
                            name="PinCode"
                            component={Input}
                            label={<Trans id="pinCode"></Trans>}
                            required
                            type="text"
                        />
                    </FormGroup>
                )}
                {company.CustomerCustomFields &&
                    this.renderCustomerCustomFields(company.CustomerCustomFields)}
                {error && <Alert color="danger">{error}</Alert>}
            </form>
        );
    }

    renderCustomerCustomFields = (customFields: Array<CustomFieldType>) => {
        return customFields.map((customField) => {
            if (customField.DataType === 'TextBox') {
                return (
                    <CustomFieldTextBox
                        key={customField.Id}
                        customField={customField}
                        name={`CustomerCustomFields._${customField.Id}`}
                    />
                );
            }

            if (customField.DataType === 'DropDown') {
                return (
                    <CustomFieldDropDown
                        key={customField.Id}
                        customField={customField}
                        name={`CustomerCustomFields._${customField.Id}`}
                    />
                );
            }

            return false;
        });
    };
}

function mapStateToProps(state: ApplicationState, props: any) {
    const companyId = state.company.data?.Id;
    const companyCustomerCustomFields: any[] | undefined = state.company.data?.CustomerCustomFields;

    const customerCustomFields = state.user.data?.CompanyCustomers && state.user.data?.CompanyCustomers.find(c => c.CompanyId === companyId)?.CustomFieldValues
      ? state.user.data?.CompanyCustomers.find(c => c.CompanyId === companyId)?.CustomFieldValues
      : [];

    
    const cutomerDetailsFromQuery: { firstname?: string; lastname?: string; email?: string; phone?: string } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    const initialValues: any = state.user.data
        ? {
            Customer: state.user.data.CustomerProfile,
        }
        : {
            Customer: {
                ...(cutomerDetailsFromQuery.firstname ?  { Firstname: cutomerDetailsFromQuery.firstname } : {}),
                ...(cutomerDetailsFromQuery.lastname ?  { Lastname: cutomerDetailsFromQuery.lastname } : {}),
                ...(cutomerDetailsFromQuery.email ?  { Email: cutomerDetailsFromQuery.email } : {}),
                ...(cutomerDetailsFromQuery.phone ?  { Phone: cutomerDetailsFromQuery.phone } : {})
            }
        };

    if (companyCustomerCustomFields) {
        companyCustomerCustomFields.forEach((customField: any) => {
            if (!initialValues["CustomerCustomFields"]) {
                initialValues["CustomerCustomFields"] = {};
            }
            
            initialValues["CustomerCustomFields"][`_${customField.Id}`] =
            customerCustomFields?.find(cf => cf.Id === customField.Id)?.Value ? customerCustomFields?.find(cf => cf.Id === customField.Id)?.Value : customField.DefaultValue;
        });
    }

    return {
        isLoggedIn: state.authenticate.isLoggedIn,
        company: state.company.data,
        form: 'customerForm',
        initialValues,
        onSubmitSuccess: () => {
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
    };
}

export default compose(
    connect(mapStateToProps),
    reduxForm({
        enableReinitialize: true,
        onSubmit: onSubmitActions('BOOK', 'SAVE_BOOKING', 'FORM_ERROR'),
        destroyOnUnmount: false,
        keepDirtyOnReinitialize: true,
    })
)(CustomerForm) as React.ComponentType<OwnProps>;