import { Middleware } from 'redux';
import { BookingType, AppliedRebateCodes, ItemType, PriceType, ServiceType } from '@/types';
import { ApplicationState } from '@/store';

declare global {
    interface Window {
        dataLayer?: any[];
    }
}

const googleTagManagerMiddleware: Middleware = (store) => (next) => (action) => {
    const dataLayer = window.dataLayer;

    if (dataLayer) {
        try {
            switch (action.type) {
                case 'SELECT_SERVICE':
                    dataLayer.push({
                        event: 'service',
                        action: 'select',
                        service: action.service.Name,
                    });
                    break;
                case 'SELECT_RESOURCE':
                    dataLayer.push({
                        event: 'resource',
                        action: 'select',
                        service: action.service.Name,
                        resource: action.resource.Name,
                    });
                    break;
                case 'SELECT_TIME':
                    dataLayer.push({
                        event: 'time',
                        action: 'select',
                        service: action.service.Name,
                    });
                    break;
                case 'SAVE_BOOKING':
                    const booking: BookingType = action.booking;
                    const prices: ApplicationState['prices']['data'] = action.prices;
                    const company: ApplicationState['company']['data'] = action.company;
                    const service: ServiceType = action.service;
                    let items: ItemType[] = [];
                    let price = 0;
                    let currency = '';
                    let vat;
                    
                    
                    if (booking.Quantities && booking.Quantities.length > 0) {
                        const value = booking.Quantities.reduce(
                            (acc, quantity) =>
                                acc + (quantity.Price || 0) * (quantity.Quantity || 0),
                            0
                        );
                        vat = booking.Quantities.reduce(
                            (acc, quantity) => {
                                if(quantity.VAT && quantity.Price) {
                                    const priceWithoutVat = quantity.Price / (1 + quantity.VAT)
                                    acc = acc + (priceWithoutVat * quantity.VAT)
                                }
                                return acc;
                            },0
                        );

                        currency = booking.Quantities.filter(c => c.CurrencyId)[0]?.CurrencyId ? booking.Quantities.filter(c => c.CurrencyId)[0].CurrencyId : '';
                        price = value;                        

                        booking.Quantities.forEach(quantity => {
                            if(quantity.Price < 0) {
                                return
                            }

                            let item: ItemType = {
                                affiliation: 'BokaMera',
                                item_category: quantity.Category,
                                item_brand: company?.Name,
                                item_name: service.Name,
                                quantity: quantity.Quantity,
                                ...(quantity.Price ? { price: quantity.Price } : {}),
                                item_id: quantity.Id
                            };

                            let coupon = prices?.AppliedCodes[0] ? prices.AppliedCodes[0] : undefined;
                            if(coupon && item.price) {
                                
                                item['coupon'] = coupon.RebateCodeSign;
                                item['discount'] = coupon.RebateAmount / booking.Quantities.filter(q => q.Price >= 0).length
                            }


                            items.push(item);
                        });
                    }
                    
                    dataLayer.push({
                        event: 'booking',
                        action: 'success',
                        service: action.service.Name,
                        bookingId: booking.Id,
                        price,
                        items,
                        ...(currency ? { currency } : {}),
                        ...(typeof vat !== 'undefined' ? { vat } : {})
                    });

                    dataLayer.push({
                        event: 'purchase',
                        action: 'success',
                        service: action.service.Name,
                        bookingId: booking.Id,
                        price,
                        items,
                        ...(currency ? { currency } : {}),
                        ...(typeof vat !== 'undefined' ? { vat: vat } : {})
                    });

                    break;
                case 'FAIL_BOOKING':
                    dataLayer.push({
                        event: 'booking',
                        action: 'failure',
                        service: action.service.Name,
                    });

                    break;
                default:
                    break;
            }
        } catch (err) {}
    }
    return next(action);
};

export default googleTagManagerMiddleware;
