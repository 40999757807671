import axios from "axios";
import { ConfigKeys } from "../misc/constants";
import readConfigurationProperty from "../misc/readConfigurationProperty";
import keycloak from '@/keycloak'

const headers = {
  "Accept": "application/json",
  "x-language": readConfigurationProperty(ConfigKeys.LANGUAGE) || "sv",
  "x-api-key": process.env.REACT_APP_API_KEY,
  "Content-Type": "application/json"
};

const axiosInstance = axios.create({
  headers,
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const headers: any = {};
  
  if (keycloak && keycloak.refreshToken && keycloak.token) {
    headers["x-ss-id"] = keycloak.sessionId;
    headers["Authorization"] = "Bearer " + keycloak.token;
  }

  return {
    ...config,
    headers: { ...headers, ...(config || {}).headers },
  };
})

export default axiosInstance;
