import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

interface Props {
    title?: React.ReactNode;
    style?: Object;
    color?: string;
    className?: string;
    inverse?: boolean;
    children?: React.ReactNode;
}

export function Panel(props: Props) {
    const { title, style, color, inverse, children, className } = props;

    return (
        <Card className={className} style={style} color={color} inverse={inverse}>
            <CardBody>
                {title && <CardTitle>{title}</CardTitle>}
                {children}
            </CardBody>
        </Card>
    );
}

export default Panel;
