import React, { useContext, useEffect } from "react";
import { Alert } from 'reactstrap';
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import { withI18n, withI18nProps } from "@lingui/react";
import { ApplicationState } from "@/store";

interface AuthenticationProviderProps extends ReduxProps,withI18nProps {
  children: any;
}

export const BACK_URI = 'backUri';

export const REFRESH_TOKEN_INTERVAL = 55; // in seconds

export const AuthenticationContext = React.createContext({
  
});

export const LAST_PATHNAME_STORAGE_KEY = 'lastPathname';

export const useAuthentication = () => useContext(AuthenticationContext);

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  children,
  dispatch,
  authentication,
  i18n
}) => {
  useEffect(() => {
    window.addEventListener("message", (evt) => { // NOSONAR
      if (evt.data === '[iFrameSizer]message:"logout"') {
        dispatch({ type: "LOGOUT" });
      }
    });
  }, []);

  return (
    <AuthenticationContext.Provider value={{ backUri: "" }}>
      {children}
      {authentication?.error?.message ? (
        <div className="container" style={{ marginTop: '20px', maxWidth: 960 }}>
          <Alert color="danger">
            <span dangerouslySetInnerHTML={{
              __html: i18n._('identityProvider.authError', { error:  authentication.error.message })
            }} />
            <br />
          </Alert>
        </div>
      
    ) : null}
    </AuthenticationContext.Provider>
  );
};

const connector = connect((state: ApplicationState) => ({ authentication: state.authenticate }));

type ReduxProps = ConnectedProps<typeof connector>

export default compose<React.ComponentType>(
  withI18n(),
  connect((state: ApplicationState) => ({ authentication: state.authenticate }))
)(AuthenticationProvider);

