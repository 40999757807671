import { UiInitialState } from "@/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: UiInitialState = {
    parentScrollTop: 0
} 

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        updateScrollTop: (state, action: PayloadAction<number>) => {
            state.parentScrollTop = action.payload;
        },

    }
});
export const { updateScrollTop } = uiSlice.actions;

export default uiSlice;