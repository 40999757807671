import React from 'react';
import { Table } from 'reactstrap';
import BEMHelper from 'react-bem-helper';
import { Article } from '@/types';

import './Quantities.css';
import { Trans } from '@lingui/macro';


interface Props {
    article: Required<Article>,
    quantity: number;
    currency: string;

}

const c = new BEMHelper({
    name: 'Quantities',
});

export const ArticleQuantities: React.FC<Props> = (props) => {
    const { article, quantity, currency } = props;

    return (
      <Table size="sm" responsive borderless>
        <thead>
          
        </thead>
        <tbody>
          <tr>
            <td>
              {quantity} x {article.Description}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr {...c("totalRow")}>
            <td>
                <strong>{<Trans id="totally"></Trans>}</strong>
            </td>
            <td colSpan={2}>
                <strong>
                {article.Price.toFixed(2)}
                {currency}
                </strong>
              </td>
          </tr>
        </tfoot>
      </Table>
    );
};

export default ArticleQuantities;
