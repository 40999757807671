import React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ButtonGroup } from 'reactstrap';
import BEMHelper from 'react-bem-helper';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { t } from '@lingui/macro';

import { Button } from 'bokamera-embedded-ui';

import { format, getISOWeek } from '@/misc/datetime';
import { ConfigKeys } from '@/misc/constants';
import { Configuration, ServiceType } from '@/types';

import './CalendarToolbar.css';
import { I18n } from '@lingui/core';
import { withI18n } from '@lingui/react';
import { ApplicationState } from '@/store';

interface Props {
    configuration: Configuration;
    service: ServiceType;
    dispatch: Dispatch<any>;
    i18n: I18n;
}

interface OwnProps {
    service?: ServiceType;
}

const c = new BEMHelper({
    name: 'CalendarToolbar',
});

export const CalendarToolbar: React.FC<Props> = (props) => {
    const { configuration, service, i18n } = props;

    let header;
    let timesLayout = configuration.timesLayout;

    if (timesLayout === ConfigKeys.TIMES_LAYOUT_MONTH) {
        header = format(configuration.navigationDate, 'MMMM yyyy');
    }
    if (timesLayout === ConfigKeys.TIMES_LAYOUT_WEEK) {
        header = `${i18n._(t('week')``)} ${getISOWeek(configuration.navigationDate)} ${format(
            configuration.navigationDate,
            'yyyy'
        )}`;
    }

    return (
        <div {...c()}>
            <ButtonGroup>
                <h4>{header}</h4>
            </ButtonGroup>
            <ButtonGroup>
                <Button
                    {...c('button')}
                    outline
                    color="link"
                    onClick={() => {
                        props.dispatch({
                            type: 'SELECT_PREV_CALENDAR',
                            service,
                        });
                    }}
                    aria-label={(i18n._(t('back')``) as unknown) as string}
                >
                    <MdChevronLeft size="1em" />
                </Button>
                <Button
                    {...c('button')}
                    outline
                    color="link"
                    onClick={() => {
                        props.dispatch({
                            type: 'SELECT_NEXT_CALENDAR',
                            service,
                        });
                    }}
                    aria-label={(i18n._(t('next')``) as unknown) as string}
                >
                    <MdChevronRight size="1em" />
                </Button>
            </ButtonGroup>
        </div>
    );
};

const mapStateToProps = ({ configuration }: ApplicationState) => ({
    configuration: configuration.data,
});

export default compose<React.ComponentType<OwnProps>>(
    connect(mapStateToProps),
    withI18n()
)(CalendarToolbar);
