import { createReducer } from 'typesafe-actions';
import { CalculatedPriceResponse } from '@/types';
import { selectArticle } from './article';

const initialState = {
    isLoading: false,
    hasError: false,
    error: null,
    data: undefined,
};

interface State {
    isLoading: boolean;
    hasError: boolean;
    error: null | any;
    data?: CalculatedPriceResponse;
}

const reducer = createReducer<State>(initialState, {
  SELECT_SERVICE: () => initialState,
  SELECT_TIME: () => initialState,
  CALCULATE_PRICE_REQUEST: (state, action) => ({
    ...state,
    isLoading: true,
    data: state.data,
    hasError: false,
    error: null,
  }),
  CALCULATE_PRICE_SUCCESS: (state, action) => ({
    ...state,
    isLoading: false,
    data: action.payload,
    hasError: false,
    error: null,
  }),
  [selectArticle.type]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      data: action.payload,
      hasError: false,
      error: null,
    };
  },
  CALCULATE_PRICE_FAILURE: (state, action) => ({
    ...state,
    isLoading: false,
    hasError: true,
    error: action.payload,
  }),
  // [buyArticleRequest.type]: (
  //   state,
  //   { payload }: ReturnType<typeof buyArticleRequest>
  // ) => {
  //   if (typeof payload.Price === "number") {
  //     const stateData: CalculatedPriceResponse = {
  //       AppliedCodes: [],
  //       TotalPrice: payload.Price,
  //       TotalPriceBeforeRebate: payload.Price,
  //       TotalVatAmount: 0,
  //     };

  //     return {
  //       ...state,
  //       isLoading: false,
  //       data: stateData,
  //       hasError: false,
  //       error: null,
  //     };
  //   }

  //   return state;
  // },
});

export default reducer;
