import { ActionType } from "@/types";
import { createReducer } from "typesafe-actions";

type InitialState = {
  isLoading: boolean;
  error: any;
  data: any;
};

const initialState: InitialState = {
  isLoading: false,
  error: undefined,
  data: undefined,
};

const reducer = createReducer(initialState, {
  CREATE_ACCOUNT_REQUEST: (state, action) => {
    return Object.assign({}, state, {
      isLoading: true,
      data: { ...action.payload },
    });
  },
  CREATE_ACCOUNT_SUCCESS: (state, action) => {
    return Object.assign({}, state, {
      isLoading: false,
      data: action.payload,
      error: false,
    });
  },
  CREATE_ACCOUNT_FAILURE: (state, action) => {
    return Object.assign({}, state, {
      isLoading: false,
      error: true,
      data: action.payload,
    });
  },
  "@@redux-form/RESET": (state, action) => {
    if (action?.meta?.form === "createAccountForm") {
      return {
        ...state,
        isLoading: false,
        data: undefined,
      };
    }
    return state;
  },
});

export default reducer;
