import React, { useEffect } from 'react';
import Spinner from "@/components/elements/Spinner";
import { redirectAfterLogin } from "@/actions";
import { useAppDispatch } from '@/hooks';

interface Props {  }

const Auth: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        dispatch(redirectAfterLogin());
      }, [])
      
    return (
        <Spinner noGraceTime />
    );
};

export default Auth;
