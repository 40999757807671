import React, { useRef } from 'react';
import BEMHelper from 'react-bem-helper';
import { UncontrolledTooltip } from 'reactstrap';
import { MdInfoOutline } from 'react-icons/md';
import { formatFromTo } from '@/misc/common';
import { TimeType } from '@/types';

import './TimeSlot.css';
import { Trans } from '@lingui/macro';

const classes = new BEMHelper('TimeSlot');

interface Props {
    time: TimeType;
    showFreeTimesLeft: boolean;
    bookedTimeSlotText?: string;
    showEndTimeOnTimeslots: boolean;
    onClick(e: React.MouseEvent<any>): void;
    addToQueue: boolean;
    fullyBooked: boolean;
    single?: string;
    multiple?: string;
    multipleBooking?: boolean;
    className?: string;
    id?: string;
}

const tooltipContainer = window?.bookingAppContainer;

const TimeSlot = (props: Props) => {
    const {
        time,

        onClick,
        showFreeTimesLeft,
        bookedTimeSlotText,
        showEndTimeOnTimeslots,
        single,
        multiple,
        multipleBooking,
        className,
        id,
        addToQueue,
        fullyBooked
    } = props;

    const divEl = useRef<HTMLDivElement>(null);

    const formattedFromTo = formatFromTo(time.From, time.To, {
        renderEndTimeIfSameDay: showEndTimeOnTimeslots,
    });

    const disabled = fullyBooked;

    const showTooltip = time.ExceptionTexts?.length > 0 && !!time.ExceptionTexts[0].ReasonPublic;

    return (
        <>
            <div
                key={time.From}
                onClick={!disabled ? onClick : undefined}
                {...classes(undefined, { disabled: fullyBooked, addToQueue }, className)}
                role="button"
                aria-label={formattedFromTo}
                id={id}
                ref={divEl}
            >
                <div>
                    {formattedFromTo}
                    {showTooltip && (
                        <>
                            {' '}
                            <MdInfoOutline />
                        </>
                    )}
                    {multipleBooking && time.Free > 0 && (
                        <span className="">
                            {showFreeTimesLeft && (
                                <em>
                                    {' '}
                                    {time.Free} {time.Free === 1 ? single : multiple}
                                </em>
                            )}
                        </span>
                    )}
                    {time.Free === 0 && <br />}
                    {time.Free === 0 && <small>{bookedTimeSlotText}</small>}
                    {multipleBooking && time.Free === 0 && (
                        <span className="text-danger">
                            <em>
                                <Trans id="fullyBooked"></Trans>
                            </em>
                        </span>
                    )}
                    {addToQueue ? (<Trans id="addToQueue" />) : null}
                </div>
            </div>
            {showTooltip && divEl && (
                <UncontrolledTooltip
                    target={divEl}
                    container={tooltipContainer ? tooltipContainer : undefined}
                >
                    {time.ExceptionTexts[0].ReasonPublic}
                </UncontrolledTooltip>
            )}
        </>
    );
};

export default TimeSlot;
