import readConfigurationProperty from "@/misc/readConfigurationProperty";
import { ActionType, Configuration } from "@/types";
import { addQuarters, addMonths, addWeeks } from "@/misc/datetime";
import { ConfigKeys } from "@/misc/constants";
import {
  createSlice,
  TypedStartListening,
  createListenerMiddleware,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ApplicationDispatch, ApplicationState, persistor } from "@/store";
import { fetchCompanyAsync } from "@/actions";
import { fetchArticles } from "./articles";

export function getInitialState(): Configuration {
  return {
    company: readConfigurationProperty(ConfigKeys.COMPANY, ""),
    bookMethods: readConfigurationProperty(ConfigKeys.BOOK_METODS, [
      ConfigKeys.BOOK_METODS_CONTACT_INFORMATION,
      ConfigKeys.BOOK_METODS_CREATE_ACCOUNT,
      ConfigKeys.BOOK_METODS_LOGIN,
      ConfigKeys.BOOK_METODS_LOGIN_FACEBOOK,
    ]),
    bookLayout: readConfigurationProperty(
      ConfigKeys.BOOK_LAYOUT,
      ConfigKeys.BOOK_LAYOUT_TIME_BASED
    ),
    listingLayout: readConfigurationProperty(
      ConfigKeys.LISTING_LAYOUT,
      ConfigKeys.LISTING_LAYOUT_ROWS
    ),
    timesLayout: readConfigurationProperty(
      ConfigKeys.TIMES_LAYOUT,
      ConfigKeys.TIMES_LAYOUT_MONTH
    ),
    showNextAvailableTime: readConfigurationProperty(
      ConfigKeys.SHOW_NEXT_AVAILABLE_TIME,
      true
    ),
    showCompanySummary: readConfigurationProperty(
      ConfigKeys.SHOW_COMPANY_SUMMARY,
      false
    ),
    hideServiceImage: readConfigurationProperty(
      ConfigKeys.HIDE_SERVICE_IMAGE,
      false
    ),
    showEndTimeOnTimeslots: readConfigurationProperty(
      ConfigKeys.SHOW_END_TIME_ON_TIMESLOTS,
      false
    ),
    hideResourceImage: readConfigurationProperty(
      ConfigKeys.HIDE_RESOURCE_IMAGE,
      false
    ),
    bookedTimeSlotText: readConfigurationProperty(
      ConfigKeys.BOOKED_TIME_SLOT_TEXT,
      null
    ),
    topOffset: readConfigurationProperty(ConfigKeys.TOP_OFFSET, 0),
    selectedService: readConfigurationProperty(
      ConfigKeys.SELECTED_SERVICE,
      null
    ),
    language: readConfigurationProperty(ConfigKeys.LANGUAGE, "sv"),
    paymentConfirmationURL: readConfigurationProperty(
      ConfigKeys.PAYMENT_CONFIRMATION_URL
    ),
    darkTheme: readConfigurationProperty(ConfigKeys.DARK_THEME),
    serviceIdsToShow: readConfigurationProperty(
      ConfigKeys.SERVICES_TO_SHOW_IDS
    ),
    articleIdsToShow: readConfigurationProperty(
      ConfigKeys.ARTICLES_TO_SHOW_IDS
    ),
    primaryColor: readConfigurationProperty(ConfigKeys.PRIMARY_COLOR),
    targetOrigin: readConfigurationProperty(ConfigKeys.TARGETORIGIN),
    preventAutoscroll: readConfigurationProperty(
      ConfigKeys.PREVENT_AUTOSCROLL,
      false
    ),
    scrollAfterStep: readConfigurationProperty(ConfigKeys.SCROLL_AFTER_STEP, 0),
    showRebateCodeField: readConfigurationProperty(
      ConfigKeys.SHOW_REBATE_CODE_FIELD
    ),
    analytics: readConfigurationProperty(ConfigKeys.ANALYTICS, true),
    paymentButton: readConfigurationProperty(ConfigKeys.PAYMENT_BUTTON, false),

    navigationDate: new Date(),
  };
}

export type ConfigurationStartListening = TypedStartListening<
  ApplicationState,
  ApplicationDispatch
>;

export const configurationListener = createListenerMiddleware();

const starConfigurationListening =
  configurationListener.startListening as ConfigurationStartListening;

const initialState = {
  data: getInitialState(),
};

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    addConfiguration: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<
          Configuration & {
            state?: string;
            session_state: string;
            code: string;
          }
        >
      >
    ) => {
      delete payload.state;
      delete payload.session_state;
      delete payload.code;

      state.data = {
        ...state.data,
        ...payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase("SAVE_COMPANY_ID", (state, action: any) => {
      state.data.company = action.companyId;
    });

    builder.addCase("CHANGE_NAVIGATION_DATE", (state, action: any) => {
      state.data.navigationDate = action.date;
    });

    builder.addCase("SAVE_SELECTED_DAY", (state, action: any) => {
      state.data.selectedDate = action.day;
    });

    builder.addCase("SELECT_PREV_CALENDAR", (state) => {
      if (state.data.timesLayout === ConfigKeys.TIMES_LAYOUT_WEEK) {
        state.data.navigationDate = addWeeks(state.data.navigationDate, -1);
      } else if (state.data.timesLayout === ConfigKeys.TIMES_LAYOUT_MONTH) {
        state.data.navigationDate = addMonths(state.data.navigationDate, -1);
      } else if (state.data.timesLayout === ConfigKeys.TIMES_LAYOUT_QUARTER) {
        state.data.navigationDate = addQuarters(state.data.navigationDate, -1);
      } else if (
        state.data.timesLayout === ConfigKeys.TIMES_LAYOUT_WEEKLY_OVERVIEW
      ) {
        state.data.navigationDate = addWeeks(state.data.navigationDate, -1);
      }
    });

    builder.addCase("SELECT_NEXT_CALENDAR", (state) => {
      if (state.data.timesLayout === ConfigKeys.TIMES_LAYOUT_WEEK) {
        state.data.navigationDate = addWeeks(state.data.navigationDate, 1);
      } else if (state.data.timesLayout === ConfigKeys.TIMES_LAYOUT_MONTH) {
        state.data.navigationDate = addMonths(state.data.navigationDate, 1);
      } else if (state.data.timesLayout === ConfigKeys.TIMES_LAYOUT_QUARTER) {
        state.data.navigationDate = addQuarters(state.data.navigationDate, 1);
      } else if (
        state.data.timesLayout === ConfigKeys.TIMES_LAYOUT_WEEKLY_OVERVIEW
      ) {
        state.data.navigationDate = addWeeks(state.data.navigationDate, 1);
      }
    });

    builder.addCase("RESET_CONFIG", (state) => {
      state.data = getInitialState();
    });
  },
});

export const { addConfiguration } = configurationSlice.actions;

starConfigurationListening({
  matcher: isAnyOf(addConfiguration.match),
  effect: async (action, api) => {
    const stateConfiguration = api.getState().configuration.data;

    if (addConfiguration.match(action)) {
      if (
        stateConfiguration.articleIdsToShow &&
        action.payload.articleIdsToShow &&
        stateConfiguration.articleIdsToShow.toString() !==
          action.payload.articleIdsToShow.toString()
      ) {
        api.dispatch({ type: "RESET_CONFIG" });
        api.dispatch({ type: "RESET_PARENT_CONFIG" });
        return;
      } else if (
        action.payload.articleIdsToShow &&
        !stateConfiguration.articleIdsToShow
      ) {
        api.dispatch({ type: "RESET_CONFIG" });
        api.dispatch({ type: "RESET_PARENT_CONFIG" });
        return;
      } else if (
        !action.payload.articleIdsToShow &&
        stateConfiguration.articleIdsToShow
      ) {
        api.dispatch({ type: "RESET_CONFIG" });
        api.dispatch({ type: "RESET_PARENT_CONFIG" });
        return;
      }

      api.dispatch(fetchCompanyAsync.request());
      api.dispatch({ type: "FETCH_SERVICES" });
      // @ts-ignore
      api.dispatch(fetchArticles({ CompanyId: action.payload.company }));
    }
  },
});

export default configurationSlice;
