import React from 'react';

// Not found page component
const NotFound: React.SFC<{}> = () => {
    return (
        <div className="page-not-found">
            <h4>Page not found</h4>
        </div>
    );
};

export default NotFound;
