import React from 'react';
import { scrollToElement } from '@/misc/common';
import readConfigurationProperty from "@/misc/readConfigurationProperty";


const withAutoScroll = <P extends object>(Component: React.ComponentType<P>) => {
    return class extends React.Component {
        ref: React.RefObject<HTMLElement>;

        constructor(props: any) {
            super(props);
            this.ref = React.createRef();
          }

        componentDidMount() {
            const element = this.ref.current;

            if (!readConfigurationProperty('preventAutoscroll', false) && element) {
                scrollToElement();
            }
        }

        render() {
            // @ts-ignore
            return <div ref={this.ref}><Component {...this.props} /></div>;
          }
    }
}

export default withAutoScroll
