import React, { SFC } from 'react';

interface Props {
    src: string;
    size: number;
}

const Thumbnail: React.SFC<Props> = props => {
    const { src, size } = props;

    if (!src) return null;

    const style = {
        backgroundImage: `url('${src}')`,
        width: size,
        height: size,
        display: 'block',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        margin: '0 auto',
        top: 0,
        left: 0,
        right: 0,
    };
    return <div className="rounded-circle" style={style} />;
};

export default Thumbnail;
